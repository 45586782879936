import { ROLE_VIEWS } from 'constants/roles'
import { shiftUpdateTypes } from 'constants/shift'
import { taskStates } from 'pages/Tasks/constants'
import routes from 'services/RoutesProvider/routes'
import { ShiftNotification } from 'shared/graphql/graphql'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import { GetNotificationData } from '../types'

export const getShiftData = ({ notification, t }: GetNotificationData) => {
  const { dataType, data } = notification
  const { dateTo, dateFrom, count, taskUuid } = data as ShiftNotification

  const isInserted = dataType === shiftUpdateTypes.inserted

  const bodyTranslationKey = () => {
    if (!count) return ''
    if (count > 1 && dataType === shiftUpdateTypes.inserted) {
      return 'notification.new.newshift.multiday.body'
    }
    if (count === 1 && dataType === shiftUpdateTypes.inserted) {
      return 'notification.new.newshift.singleday.body'
    }
    if (count > 1 && dataType === shiftUpdateTypes.updated) {
      return 'notification.new.shiftchange.multiday.body'
    }
    if (count === 1 && dataType === shiftUpdateTypes.updated) {
      return 'notification.new.shiftchange.singleday.body'
    }

    return ''
  }

  const textVariables = {
    // New-date-format
    dateTo: getFormattedFromISOString(dateTo ?? '', uiDateFormat, undefined),
    dateFrom: getFormattedFromISOString(dateFrom ?? '', uiDateFormat, undefined),
    count: count ?? 0,
  }

  const url = isInserted ? routes.shifts : `${routes.shiftUpdateDetail}/${taskUuid}/${taskStates.todo}`

  return {
    title: t(isInserted ? 'notification.new.newshift.title' : 'notification.new.shiftchange.title'),
    body: t(bodyTranslationKey(), textVariables),
    route: url,
    role: ROLE_VIEWS.frontliner,
  }
}
