import React, { useCallback, useMemo } from 'react'
import { Box } from '@mui/material'
import { useFormsContext } from 'components/Forms/context'
import Page from 'components/Page'
import useRefreshHighSecSession from 'hooks/useRefreshHighSecSession'
import { headerClasses } from 'pages/Tasks/styles'
import { StepT } from 'types'
import { userDataIntroStep, userDataSteps } from '../../Forms/constants'
import FormActionButtons from './FormActionButtons'
import StepperHeader from './StepperHeader'
import { classes } from './styles'

interface IFormPageProps {
  onClose: () => void
  activeStep: StepT
  goToStep: (stepName: string, additionalUrlParams?: string[] | undefined) => void
  showCommentButton: boolean
}

const FormPage: React.FC<IFormPageProps> = ({ onClose, activeStep, goToStep, showCommentButton }) => {
  const { getCurrentStepSubmit } = useFormsContext()
  const { refreshHighSecTriggers } = useRefreshHighSecSession()
  const activeIndex = useMemo(() => activeStep.index, [activeStep.index])
  const goToPrevStep = useCallback(() => {
    const prevStep = activeIndex > 0 ? userDataSteps[activeIndex - 1] : userDataIntroStep
    goToStep(prevStep.name)
  }, [activeIndex, goToStep])

  return (
    <Page
      hideNativeNavigation
      hideHamburger
      hideBackground
      removeLastMargin
      header={<StepperHeader onClickIcon={onClose} activeStep={activeStep.index} steps={userDataSteps} />}
      sxHeader={headerClasses.subHeader}
    >
      <Box
        sx={[classes.formContainer, ...(activeIndex > -1 ? [classes.paddingWithActionButtons] : [])]}
        {...refreshHighSecTriggers}
      >
        <activeStep.component goToStep={goToStep} hideStepper={() => {}} />
        {activeStep.index > -1 && (
          <FormActionButtons
            onPrevious={goToPrevStep}
            onNext={getCurrentStepSubmit()}
            lastStep={activeIndex >= userDataSteps.length - 1}
            showCommentButton={showCommentButton}
          />
        )}
      </Box>
    </Page>
  )
}

export default FormPage
