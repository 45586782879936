import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { SecureConnectionNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getSecureConnectionData = ({ notification, t }: GetNotificationData) => {
  const { tenantName, taskUuid } = notification.data as SecureConnectionNotification
  const { title, body } = notificationGlosssaryKeys.secureConnection

  return {
    title: t(title),
    body: t(body, { tenantName }),
    route: routes.secureConnectionWithTask(taskUuid ?? ''),
    role: ROLE_VIEWS.frontliner,
  }
}
