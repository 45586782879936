import type { DateVariant } from './types'

export const DATE_FORMAT_VARIANTS = {
  shortWithWeekday: 'shortWithWeekday',
  shortWithWeekdayAndTime: 'shortWithWeekdayAndTime',
  shortWithWeekdayAndTimeRange: 'shortWithWeekdayAndTimeRange',
  shortWithoutWeekday: 'shortWithoutWeekday',
  longWithWeekday: 'longWithWeekday',
  longWithoutWeekday: 'longWithoutWeekday',
  monthOnly: 'monthOnly',
} as const

export const DATE_FORMATS: Record<DateVariant, { currentYear: string; otherYear: string }> = {
  shortWithWeekday: { currentYear: 'd MMM', otherYear: 'd MMM yyyy' },
  shortWithWeekdayAndTime: { currentYear: 'd MMM, HH:mm', otherYear: 'd MMM yyyy, HH:mm' },
  shortWithWeekdayAndTimeRange: { currentYear: 'd MMM, HH:mm', otherYear: 'd MMM yyyy, HH:mm' },
  shortWithoutWeekday: { currentYear: 'd MMM', otherYear: 'd MMM yyyy' },
  longWithWeekday: { currentYear: 'd MMMM', otherYear: 'd MMMM yyyy' },
  longWithoutWeekday: { currentYear: 'd MMMM', otherYear: 'd MMMM yyyy' },
  monthOnly: { currentYear: 'MMMM', otherYear: 'MMMM yyyy' },
}

export const FORMATS_WITHOUT_WEEKDAYS: DateVariant[] = [
  DATE_FORMAT_VARIANTS.shortWithoutWeekday,
  DATE_FORMAT_VARIANTS.longWithoutWeekday,
  DATE_FORMAT_VARIANTS.monthOnly,
]
