export const classes = {
  formControlLabel: {
    width: '100%',
    justifyContent: 'space-between',
    marginLeft: 0,
  },
  breakAppButton: {
    marginTop: '50px',
    width: '100%',
  },
}
