import { Locale, isSameDay } from 'date-fns'
import { TFunction } from 'react-i18next'
import { CmsContentNotification } from 'shared/graphql/graphql'
import { CONTENT_TYPES } from 'types/cms'
import { formatDate } from 'utils/documents'

export const contentTranslationPropsFromNotification = (
  data: CmsContentNotification,
  t: TFunction<'translation', undefined>,
  locale: Locale | undefined,
) => ({
  // New-date-format
  title: data.title,
  ...(data.startDate && data.endDate
    ? {
        date: `${formatDate(data.startDate, locale)} ${
          isSameDay(data.startDate, data.endDate) ? `` : `- ${formatDate(data.endDate, locale)}`
        }`,
      }
    : {}),
})

export const getTranslations = ({ contentType, isNew }: { contentType: string; isNew: boolean }) => {
  const key = contentType ?? CONTENT_TYPES.page
  const state = isNew ? 'new' : 'update'

  return {
    title: `notification.new.cms.${state}.${key}.inapp.title`,
    body: `notification.new.cms.${state}.${key}.inapp.body`,
  }
}
