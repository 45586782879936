/* eslint-disable fp/no-this */
import React, { useEffect, useState } from 'react'
import { TENANT_CONNECTION_STATUS } from 'constants/highSecurityConnection'
import { defaultPhoneNumberCountryCode } from 'constants/i18n'
import useFavurForm from 'hooks/useFavurForm'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useSessionContext from 'hooks/useSessionContext'
import useTenantsAndPermissions from 'hooks/useTenantsAndPermissions'
import { isValidPhoneNumber } from 'libphonenumber-js'
import { SubmitHandler } from 'react-hook-form'
import * as yup from 'yup'
import { useQuery } from '@apollo/client'
import { isValidZipCode } from '../helpers'
import { contactDataQuery } from './queries'
import { ContactDataResponseT, ContactFormValuesT, addressTypes } from './types'

const canPhoneNumberBeEmpty = (phoneNumber: string, type: string): boolean => {
  if (!phoneNumber && type === addressTypes.emergency) {
    return false
  }
  return true
}

const isValidAddress = (address: string): boolean => !address.includes('"')

const useContactForm = ({
  onSubmit,
}: {
  onSubmit: (data: ContactFormValuesT, e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}) => {
  const { t } = useFavurTranslation()
  const requiredFieldText = t('personalData.error.requiredField')
  const { phone: phoneFromSession } = useSessionContext()
  const [loading, setLoading] = useState<boolean>(true)
  const [formReady, setFormReady] = useState(false)
  const [apiData, setApiData] = useState<ContactDataResponseT>()
  const [defaultValues, setDefaultValues] = useState<ContactFormValuesT>()
  const { tenantsAndPermissions } = useTenantsAndPermissions()

  const hasOneHighSecurityConnection = tenantsAndPermissions?.some(
    (tenant) => tenant.tenantConnectionStatus === TENANT_CONNECTION_STATUS.high,
  )

  useQuery<{ contactData: ContactDataResponseT }>(contactDataQuery, {
    onCompleted(data) {
      setLoading(false)
      setApiData(data.contactData)
    },
  })

  const addressFields = yup.object().shape({
    address: yup
      .string()
      .nullable()
      .required(requiredFieldText)
      .test({
        test: function test() {
          const { address } = this.parent
          return isValidAddress(address)
        },
        message: t('personalData.error.invalidAddress'),
      }),
    additionalAddress: yup
      .string()
      .nullable()
      .test({
        test: function test() {
          const { additionalAddress } = this.parent
          if (!additionalAddress) {
            return true
          }

          return isValidAddress(additionalAddress)
        },
        message: t('personalData.error.invalidAddress'),
      }),
    zipCode: yup
      .string()
      .nullable()
      .required(requiredFieldText)
      .test({
        name: 'zipCode',
        test: function test() {
          const { zipCode } = this.parent
          return isValidZipCode(zipCode)
        },
        message: t('personalData.error.invalidZipCode'),
      }),
    city: yup.string().nullable().required(requiredFieldText),
    country: yup.string().nullable().required(requiredFieldText),
    phoneNumber: yup
      .string()
      .nullable()
      .test({
        name: 'phoneNumber',
        test: function test() {
          const { phoneNumber, type } = this.parent
          return (
            canPhoneNumberBeEmpty(phoneNumber, type) || isValidPhoneNumber(phoneNumber, defaultPhoneNumberCountryCode)
          )
        },
      }),
    firstName: yup.string().nullable(),
    lastName: yup.string().nullable(),
  })

  const [formFields] = useState([
    {
      name: 'email',
      init: '',
      validation: yup.string().email().nullable().required(requiredFieldText),
    },
    {
      name: 'phoneNumber',
      init: '',
      validation: undefined,
      label: t('personalData.phoneNumber.label'),
    },
    {
      name: 'addresses',
      init: [],
      validation: yup.array().of(addressFields),
    },
  ])

  const { favurForm, getFormHandlers } = useFavurForm<ContactFormValuesT>({
    context: 'personalData',
    prolongHighSecuritySession: hasOneHighSecurityConnection,
    formFields,
    onSubmit: onSubmit as SubmitHandler<ContactFormValuesT>,
    // eslint-disable-next-line no-console
    onError: console.error,
  })

  const formHandlers = (name: keyof ContactFormValuesT) => {
    const handlers = getFormHandlers(name)
    return {
      ...handlers,
      id: `user-employment-data_contact_${name}`,
    }
  }

  const { reset } = favurForm
  useEffect(() => {
    if (!loading && apiData !== undefined) {
      const withDefault = {
        email: apiData.email,
        addresses: apiData.addresses,
        phoneNumber: phoneFromSession,
      }

      reset(withDefault, {
        keepDirty: true,
      })
      setDefaultValues(withDefault as ContactFormValuesT)
      setFormReady(true)
    }
  }, [loading, apiData, phoneFromSession, reset])

  return { favurForm, formHandlers, formReady, defaultValues }
}

export default useContactForm
