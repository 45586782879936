import { DOCUMENT_TYPES } from 'constants/documents'
import { TFunction } from 'react-i18next'
import routes from 'services/RoutesProvider/routes'
import { DocumentNotification } from 'shared/graphql/graphql'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import { GetNotificationData, HandleDocumentData } from '../types'

export const getBodyTranslation = (t: TFunction, documentType: string | undefined) => {
  if (documentType && documentType in DOCUMENT_TYPES) {
    const translatedDocumentType = t(
      `page.documents.documentType.${DOCUMENT_TYPES[documentType as keyof typeof DOCUMENT_TYPES]}`,
    )

    return t(`notification.new.document.body`, {
      documentType: translatedDocumentType,
    })
  }

  return t('notification.new.document.body_other_document_type')
}

const getReminderTitleTranslations = ({ t, data }: HandleDocumentData): string => {
  const { dateTo, dateFrom } = data
  if (Boolean(dateTo) && Boolean(dateFrom)) {
    return t('notification.new.worktimecontrol.title')
  }

  return t('notification.new.worktimecontrol.reminder.title')
}

const getReminderBodyTranslations = ({ t, data }: HandleDocumentData): string => {
  const { dateTo, dateFrom } = data

  if (dateTo && Boolean(dateTo) && Boolean(dateFrom) && dateFrom) {
    // New-Date-Format
    const textVariables = {
      dateTo: getFormattedFromISOString(dateTo, uiDateFormat, undefined),
      dateFrom: getFormattedFromISOString(dateFrom, uiDateFormat, undefined),
    }

    return t('notification.new.worktimecontrol.body', textVariables)
  }

  return t('notification.new.worktimecontrol.reminder.body')
}

const getDocumentBody = (params: HandleDocumentData): string => {
  const { t, data } = params
  if (data.documentType === DOCUMENT_TYPES.monthlySheetApproval) return getReminderBodyTranslations(params)

  return getBodyTranslation(t, data.documentType ?? undefined)
}

const getDocumentTitle = (params: HandleDocumentData): string => {
  const { t, data } = params
  if (data.documentType === DOCUMENT_TYPES.monthlySheetApproval) return getReminderTitleTranslations(params)

  return t(`notification.new.document.title`)
}

const getDocumentRoute = (data: HandleDocumentData['data']) => {
  if (data.documentType === DOCUMENT_TYPES.monthlySheetApproval && data.taskUuid) {
    return routes.monthlySheetsWithTask(data.taskUuid)
  }

  return routes.documents
}

export const getDocumentData = ({ notification, t }: GetNotificationData) => {
  const data = notification.data as DocumentNotification

  return {
    title: getDocumentTitle({ t, data }),
    body: getDocumentBody({ t, data }),
    route: getDocumentRoute(data),
  }
}
