import React, { useContext } from 'react'
import { Box } from '@mui/material'
import Timeline from 'components/Timeline'
import { Locale } from 'date-fns'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRefreshHighSecSession from 'hooks/useRefreshHighSecSession'
import useRolesViews from 'hooks/useRolesViews'
import { ShareUserDataTaskT, TaskT } from 'pages/Tasks/types'
import { ShareUserDataFormContext } from '.'
import ActionButtons from './ActionButtons'
import { classes } from './styles'
import { getTimelineForComponent } from './utils'

interface IContentProps {
  task: TaskT<ShareUserDataTaskT>
  isOffice: boolean
}

const Content: React.FC<IContentProps> = ({ task, isOffice }) => {
  const { t, locale } = useFavurTranslation()
  const { personIds } = useRolesViews()
  const { refetch } = useContext(ShareUserDataFormContext)
  const { refreshHighSecTriggers } = useRefreshHighSecSession()

  const timelineItems = getTimelineForComponent({ task, t, locale: locale as Locale, isOffice, personIds })

  return (
    <Box sx={classes.contentContainer} {...refreshHighSecTriggers}>
      <Timeline items={timelineItems} />
      <ActionButtons
        task={task}
        isOffice={isOffice}
        onAction={() => {
          refetch()
        }}
      />
    </Box>
  )
}

export default Content
