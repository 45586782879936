import { ROLE_VIEWS } from 'constants/roles'
import { tutorialTypes } from 'constants/tutorials'
import { compareAsc } from 'date-fns'
import routes from 'services/RoutesProvider/routes'
import { ResignationInfoNotification } from 'shared/graphql/graphql'
import { newDateWithoutTime } from 'utils/date'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getResignationInfoData = ({ notification, t }: GetNotificationData) => {
  const { tenantName, resignationDate } = notification.data as ResignationInfoNotification
  const { title: titleFuture, body } = notificationGlosssaryKeys.resignationInfo
  const { title: titlePast } = notificationGlosssaryKeys.resignationInfoPast
  const hasResignationPassed = compareAsc(newDateWithoutTime(resignationDate ?? ''), newDateWithoutTime()) > 0
  const title = t(hasResignationPassed ? titleFuture : titlePast, {
    tenantName,
  })

  return {
    title: title,
    body: t(body),
    route: `${routes.tutorial}/${tutorialTypes.resignation}`,
    role: ROLE_VIEWS.frontliner,
  }
}
