import theme from 'utils/theme'

export const successClasses = {
  body: {
    color: theme.palette.primary.main,
    gap: '16px',
    px: '24px',
    display: 'flex',
    flexDirection: 'column',
    marginBottom: `calc(100px + var(--ion-safe-area-bottom))`,
  },
  title: {
    color: theme.palette.primary.main,
    lineHeight: 1.26,
    fontWeight: 600,
    paddingTop: theme.spacing(0.75),
  },
  contentLinkBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
    height: '40px',
    justifyContent: 'space-between',
    width: '100%',
    cursor: 'pointer',
  },
  linkNameIcon: { display: 'flex', alignItems: 'center', gap: '12px' },
  root: {
    paddingTop: '72px',
  },
}
