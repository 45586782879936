import { ROLE_VIEWS } from 'constants/roles'
import { TFunction } from 'react-i18next'
import routes from 'services/RoutesProvider/routes'
import { absencePeriods, absencePeriodsTranslationMap } from 'shared/constants'
import { OfficeAbsenceRequestNotification } from 'shared/graphql/graphql'
import { getShortNameFromName } from 'utils/person'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

const getOfficeAbsenceRequestBody = (data: OfficeAbsenceRequestNotification, t: TFunction) => {
  const { dateFrom, dateTo, period } = data
  const isMultiday = dateFrom !== dateTo

  const absencePeriod = isMultiday || !period?.trim() ? '' : t(absencePeriods[absencePeriodsTranslationMap[period]])

  return t(isMultiday ? 'notification.new.absence.dates_multiday.body' : 'notification.new.absence_singleday.body', {
    // New-Date-Format
    dateFrom,
    dateTo,
    absencePeriod,
  })
}

export const getOfficeAbsenceRequestData = ({ notification, t }: GetNotificationData) => {
  const data = notification.data as OfficeAbsenceRequestNotification
  const { taskUuid, userName, userNameShort } = data

  return {
    title: t(notificationGlosssaryKeys.officeAbsenceRequest.title, {
      frontlinerNameShort: userNameShort ?? getShortNameFromName(userName ?? ''),
    }),
    body: getOfficeAbsenceRequestBody(data, t),
    route: `${routes.absenceRequestDetail}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
