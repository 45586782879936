import { ComponentsOverrides, Theme } from '@mui/material'

const styleOverrides: ComponentsOverrides<Theme>['MuiTab'] = {
  root: {
    padding: '0.56rem 1rem',
    minWidth: 0,
    minHeight: '2.625rem',
    textTransform: 'capitalize',
  },
  labelIcon: {
    gap: '0.5rem',
  },
}
const MuiTab = {
  styleOverrides,
}

export default MuiTab
