import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'

export const TASK_LIMIT = 40

export const TASK_TYPES = {
  absenceRequest: 'absence_request',
  shareUserData: 'share_user_data',
  monthlySheet: 'monthly_sheet',
  shiftUpdate: 'shift_update',
  secureConnection: 'secure_connection',
} as const

export const DEFAULT_DATE_FORMAT = DATE_FORMAT_VARIANTS.shortWithWeekday

export const TASK_STATES = {
  open: 'open',
  pending: 'pending',
  closed: 'closed',
  todo: 'todo',
} as const

export const TASK_STATES_TRANSLATIONS = {
  [TASK_STATES.open]: 'task.state.open',
  [TASK_STATES.pending]: 'task.state.pending',
  [TASK_STATES.closed]: 'task.state.closed',
  [TASK_STATES.todo]: 'task.state.open',
}

export const TASKS_STATUS = {
  approvedOffice: 'approved_office',
  rejectedOffice: 'rejected_office',
  deletionRequested: 'deletion_requested',
  acceptedDeletionRequest: 'accepted_deletion_request',
  rejectedDeletionRequest: 'rejected_deletion_request',
  reviewFrontlineUnseen: 'review_frontline_unseen',
  reviewFrontlineSeen: 'review_frontline_seen',
  reviewOfficeUnseen: 'review_office_unseen',
  reviewOfficeSeen: 'review_office_seen',
  resolvedOffice: 'resolved_office',
  outdated: 'outdated',
  reviewFrontline: 'review_frontline',
  approvedFrontline: 'approved_frontline',
  rejectedFrontline: 'rejected_frontline',
  markedChangeNextMonth: 'marked_change_next_month',
  markedChangeThisMonth: 'marked_change_this_month',
  resolvedFrontline: 'resolved_frontline',
  requestChange: 'requested_change',
}

export const INITIAL_TASKS_STATUS = {
  [TASK_TYPES.absenceRequest]: TASKS_STATUS.reviewOfficeUnseen,
  [TASK_TYPES.shiftUpdate]: TASKS_STATUS.reviewFrontlineUnseen,
  [TASK_TYPES.secureConnection]: TASKS_STATUS.reviewFrontline,
  [TASK_TYPES.monthlySheet]: TASKS_STATUS.reviewFrontline,
  [TASK_TYPES.shareUserData]: TASKS_STATUS.reviewFrontlineUnseen,
} as const

export const TASKS_STATUS_TRANSLATIONS = {
  [TASKS_STATUS.approvedOffice]: 'task.detailState.accepted',
  [TASKS_STATUS.rejectedOffice]: 'task.detailState.rejected',
  [TASKS_STATUS.deletionRequested]: 'task.detailState.deletionRequested',
  [TASKS_STATUS.acceptedDeletionRequest]: 'task.detailState.deletionAccepted',
  [TASKS_STATUS.rejectedDeletionRequest]: 'task.detailState.deletionRejected',
  [TASKS_STATUS.approvedFrontline]: 'task.detailState.approved',
  [TASKS_STATUS.requestChange]: 'task.detailState.objected',
  [TASKS_STATUS.markedChangeNextMonth]: 'task.detailState.claimAccepted',
  [TASKS_STATUS.markedChangeThisMonth]: 'task.detailState.claimAccepted',
  [TASKS_STATUS.reviewFrontlineSeen]: 'task.detailState.seen',
  [TASKS_STATUS.outdated]: 'task.detailState.outdated',
  [TASKS_STATUS.reviewFrontlineUnseen]: 'task.detailState.dataShared',
  [TASKS_STATUS.reviewOfficeUnseen]: 'task.detailState.dataShared',
  [TASKS_STATUS.resolvedOffice]: 'task.detailState.dataTransferred',
  [TASKS_STATUS.resolvedFrontline]: 'task.detailState.verified',
} as const
