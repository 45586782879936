import { gql } from '@apollo/client'

export const createTeam = gql`
  mutation createTeam(
    $nameDe: String!
    $nameEn: String
    $nameFr: String
    $nameIt: String
    $personId: Int
    $tenantId: ID
  ) {
    createTeam(
      nameDe: $nameDe
      nameEn: $nameEn
      nameFr: $nameFr
      nameIt: $nameIt
      personId: $personId
      tenantId: $tenantId
    ) {
      id
      ownerId
      nameDe
      nameEn
      nameFr
      nameIt
    }
  }
`
