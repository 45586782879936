import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { AbsenceRequestRequestDeletionNotification } from 'shared/graphql/graphql'
import { absenceRequestDateFormat } from 'utils/constants'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getAbsenceRequestDeletionData = ({ notification, t, locale }: GetNotificationData) => {
  const {
    taskUuid,
    frontlinerNameShort,
    dateFrom: notificationDateFrom,
    dateTo: notificationDateTo,
  } = notification.data as AbsenceRequestRequestDeletionNotification
  const { title, body: bodySingleday } = notificationGlosssaryKeys.absenceRequestRequestDeletion
  const { body: bodyMultiday } = notificationGlosssaryKeys.absenceRequestRequestDeletionMultiday

  const dateFrom = getFormattedFromDate(
    newDateWithoutTime(notificationDateFrom as string),
    absenceRequestDateFormat,
    locale,
  )
  const dateTo = getFormattedFromDate(
    newDateWithoutTime(notificationDateTo as string),
    absenceRequestDateFormat,
    locale,
  )
  const isMultiday = dateFrom !== dateTo

  const body = t(isMultiday ? bodyMultiday : bodySingleday, { dateFrom, dateTo })

  return {
    title: t(title, { frontlinerNameShort }),
    body,
    route: `${routes.absenceRequestDetail}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
