import type { MonthlySheetDialogDataT } from 'pages/Documents/DocumentList/types'

export const DEFAULT_DIALOG_STATE: MonthlySheetDialogDataT = {
  open: false,
  taskUuid: undefined,
  isInactivePerson: false,
  openFile: () => {},
}

export const DOCUMENT_TRANSLATION_PREFIX = 'page.documents.monthlySheetDialog'

export const DOCUMENT_CARD_IDS = {
  document: 'document',
}
