import { useState, useEffect, useCallback } from 'react'
import { throttle } from 'lodash/fp'
import { SecuritySetupProcessT } from 'pages/SecuritySetup/types'
import { sessionValidityQuery } from 'shared/queries'
import { SessionValidityT } from 'types'
import { getDateFromISOString } from 'utils/date'
import { useJamesApolloQuery } from './useJamesApolloQuery'

const THROTTLING_TIME = 20_000

export type TimeoutProcessT = SecuritySetupProcessT | 'securityTimeout'

export const TIMEOUTS_M: { [key in TimeoutProcessT]: number } = {
  securitySetup: 2000 * 60,
  pinReset: 2000 * 60,
  securityTimeout: 2000 * 60,
  pinChange: 2000 * 60,
}

export const useSessionTimeout = (securityProcess: TimeoutProcessT, callback: () => void) => {
  const [rendered, setRendered] = useState(false)
  const { data: sessionValidityData, refetch: sessionValidityRefetch } = useJamesApolloQuery<{
    sessionValidity: SessionValidityT
  }>(sessionValidityQuery, { fetchPolicy: 'no-cache' })

  const throttledValidityRefetch = useCallback(() => throttle(THROTTLING_TIME, sessionValidityRefetch), [
    sessionValidityRefetch,
  ])

  useEffect(() => {
    const onFocus = () => throttledValidityRefetch()
    window.addEventListener('focus', onFocus)

    return () => {
      window.removeEventListener('focus', onFocus)
    }
  })

  useEffect(() => {
    throttledValidityRefetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const timeoutKeyToServerTimeout = ['pinChange', 'securityTimeout'].includes(securityProcess)
      ? 'highSecSession'
      : securityProcess
    const validUntilKey = `${timeoutKeyToServerTimeout}ValidUntil` as keyof SessionValidityT
    const validNowKey = `${timeoutKeyToServerTimeout}ValidNow` as keyof SessionValidityT
    const timestamp = sessionValidityData?.sessionValidity?.timestamp
    const validUntil = sessionValidityData?.sessionValidity?.[validUntilKey]
    const validNow = sessionValidityData?.sessionValidity?.[validNowKey]

    if (!validNow && rendered) {
      callback()
      return () => {}
    }
    if (validNow && !rendered) {
      setRendered(true)
    }

    const timeout = (() => {
      if (validUntil) {
        const validUntilISO = getDateFromISOString(validUntil as string)
        const nowISO = getDateFromISOString(timestamp as string)
        const nextRefetchIn = validUntilISO.getTime() - nowISO.getTime() + 1000

        return setTimeout(() => {
          sessionValidityRefetch()
        }, nextRefetchIn)
      }
      return setTimeout(() => {
        sessionValidityRefetch()
      }, TIMEOUTS_M[securityProcess])
    })()

    return () => {
      clearTimeout(timeout)
    }
  }, [callback, rendered, sessionValidityData, securityProcess, sessionValidityRefetch])

  return { sessionValidity: sessionValidityData?.sessionValidity, sessionValidityRefetch }
}
