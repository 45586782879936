import React from 'react'
import PinLogin from 'pages/PinLogin'
import DocumentsHeader from 'redesign/components/organisms/Headers/DocumentsHeader'
import { DocumentsList } from 'redesign/components/templates/ContentLists'
import Page from 'redesign/components/templates/Page'
import theme from 'redesign/themes/main'

// TODO: theme to be updated with useTheme
const DocumentsPage = () => (
  <PinLogin>
    <Page header={<DocumentsHeader />} backgroundColor={theme.palette.common.backgroundApp} paddingX={0}>
      <DocumentsList />
    </Page>
  </PinLogin>
)

export default DocumentsPage
