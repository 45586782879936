import React from 'react'
import { Dot, DotContainer } from './styles'
import { DotDividerProps } from './types'

const DotDivider: React.FC<DotDividerProps> = ({ size }) => (
  <DotContainer>
    <Dot size={size} />
  </DotContainer>
)

export default DotDivider
