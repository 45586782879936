import { createTheme } from '@mui/material/styles'
import { ComponentOverrides } from '../overrides'
import palette from './palette'
import shadows from './shadows'
import typography from './typography'

const theme = createTheme({
  shadows,
  spacing: (factor: number) => `${0.5 * factor}rem`,
  palette,
  typography,
  components: ComponentOverrides,
})

export default theme
