import React from 'react'
import Dot from '../Divider/DotDivider'
import { NotificationContainer, ChildrenContainer } from './styles'
import { DotAlertProps } from './types'

const DotAlert: React.FC<DotAlertProps> = ({ children, isNew }) => (
  <NotificationContainer>
    <ChildrenContainer isNew={isNew}>{children}</ChildrenContainer>
    {isNew && <Dot size="large" />}
  </NotificationContainer>
)

export default DotAlert
