import React, { useCallback, useMemo } from 'react'
import { shiftDateFormat } from 'constants/dateFormats'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useHistoryUtils from 'hooks/useHistoryUtils'
import useShitfsContext from 'hooks/useShiftsContext'
import useStateBackLink from 'hooks/useStateBackLink'
import routes from 'services/RoutesProvider/routes'
import { Notification as NotificationProp } from 'shared/graphql/graphql'
import { notificationStatuses, NotificationTypes, RoleViewT, ShiftNotificationT } from 'types'
import { getDateFromISOString, getFormattedFromDate } from 'utils/date'
import ContentCard from '../ContentCard'
import type { NotificationProps } from './types'
import { normalizeNotificationData } from './utils/normalizeNotificationData'

const Notification: React.FC<NotificationProps<NotificationProp>> = ({ dismiss, notification }) => {
  const { setBackLink } = useStateBackLink()
  const { t, locale } = useFavurTranslation()
  const { pushWithRole } = useHistoryUtils()
  const { setShiftsContext } = useShitfsContext()
  const { status } = notification
  const setBackLinkAndDismiss = useCallback(
    (funcNotification: NotificationProp) => {
      setBackLink(routes.notifications)
      dismiss(funcNotification)
    },
    [dismiss, setBackLink],
  )

  const onClick = useCallback(
    (route: string, role?: RoleViewT) => {
      setBackLinkAndDismiss(notification)
      //Exception for shift notification
      if (notification.type === NotificationTypes.shift) {
        const { dateFrom } = notification.data as ShiftNotificationT['data']
        const fromDate = getDateFromISOString(dateFrom)
        const start = getFormattedFromDate(fromDate, shiftDateFormat)
        const end = getFormattedFromDate(fromDate, shiftDateFormat)
        setShiftsContext({
          start,
          end,
        })
      }

      pushWithRole(route, role)
    },
    [notification, pushWithRole, setBackLinkAndDismiss, setShiftsContext],
  )

  const { title, body, route, role } = useMemo(
    () =>
      normalizeNotificationData({
        notification,
        t,
        locale,
      }),
    [locale, notification, t],
  )

  return (
    <ContentCard
      isNew={status === notificationStatuses.new}
      header={{
        title,
        isLink: true,
      }}
      footerProps={{
        startText: body,
        endText: 'placeholder-date',
      }}
      onClick={() => onClick(route, role)}
    />
  )
}

export default Notification
