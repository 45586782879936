import React from 'react'
import { LinkContent } from './styles'
import { LinkProps } from './types'

const Link: React.FC<LinkProps> = ({ children, url, download = false }) =>
  url ? (
    <LinkContent href={url} download={download}>
      {children}
    </LinkContent>
  ) : (
    <>{children}</>
  )

export default Link
