import React from 'react'
import { Svg } from './styles'
import { SvgWrapperProps } from './types'

const SvgWrapper: React.FC<SvgWrapperProps> = ({ children, fill, width, height, size, viewBox, ...props }) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox={viewBox}
    inheritViewBox={!viewBox}
    width={width}
    height={height}
    fill={fill}
    size={size}
    {...props}
  >
    {children}
  </Svg>
)

export default SvgWrapper
