import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const FilterIcon: React.FC<IconProps> = ({ viewBox = ICON_VIEWBOX.medium, color, size = ICON_SIZE.medium }) => (
  <SvgWrapper viewBox={viewBox} size={size}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.244 2c-.73 0-1.43.297-1.945.826a2.837 2.837 0 0 0-.701 1.225H2.75a.76.76 0 0 0-.75.77c0 .424.336.769.75.769h10.848c.127.459.366.882.701 1.225a2.716 2.716 0 0 0 1.945.826c.729 0 1.428-.297 1.944-.826.335-.343.575-.766.701-1.225h2.355a.76.76 0 0 0 .75-.77.76.76 0 0 0-.75-.769h-2.355a2.837 2.837 0 0 0-.7-1.225A2.716 2.716 0 0 0 16.243 2zm1.25 2.82c0-.34-.132-.666-.366-.906a1.234 1.234 0 0 0-.884-.376c-.332 0-.65.136-.884.376a1.297 1.297 0 0 0-.364.827.801.801 0 0 1 0 .159c.019.311.148.605.364.827.234.24.552.376.884.376.331 0 .65-.136.884-.376.234-.24.366-.566.366-.906zM2 12a.76.76 0 0 1 .75-.77h2.848a2.87 2.87 0 0 1 .701-1.224c.255-.262.559-.47.892-.612a2.69 2.69 0 0 1 2.105 0c.334.142.637.35.892.612a2.825 2.825 0 0 1 .701 1.225H21.25A.76.76 0 0 1 22 12a.76.76 0 0 1-.75.77H10.89a2.865 2.865 0 0 1-.702 1.224c-.255.262-.558.47-.892.612a2.69 2.69 0 0 1-2.105 0 2.746 2.746 0 0 1-.892-.612 2.826 2.826 0 0 1-.701-1.225H2.75A.76.76 0 0 1 2 12zm4.996.08a1.31 1.31 0 0 0 .364.826 1.249 1.249 0 0 0 .884.376 1.224 1.224 0 0 0 .884-.376A1.284 1.284 0 0 0 9.494 12a1.31 1.31 0 0 0-.366-.906 1.25 1.25 0 0 0-.884-.376 1.223 1.223 0 0 0-.884.376 1.284 1.284 0 0 0-.364.827.798.798 0 0 1 0 .158zM2.75 18.41a.76.76 0 0 0-.75.77c0 .424.336.769.75.769h10.848c.127.459.366.881.701 1.225a2.716 2.716 0 0 0 1.945.826c.729 0 1.428-.297 1.944-.826.335-.343.575-.766.701-1.225h2.355a.76.76 0 0 0 .75-.77.76.76 0 0 0-.75-.769h-2.355a2.837 2.837 0 0 0-.7-1.225 2.715 2.715 0 0 0-1.945-.826c-.73 0-1.43.297-1.945.826a2.837 2.837 0 0 0-.701 1.225H2.75zm12.61 1.676a1.297 1.297 0 0 1-.364-.827.801.801 0 0 0 0-.159c.019-.311.148-.605.364-.827.234-.24.552-.376.884-.376.331 0 .65.136.884.376.234.24.366.567.366.907 0 .34-.132.666-.366.906-.235.24-.553.376-.884.376-.332 0-.65-.136-.884-.376z"
      fill={color}
      fillOpacity=".87"
    />
  </SvgWrapper>
)

export default FilterIcon
