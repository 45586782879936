import React from 'react'
import { useTheme } from '@mui/material'
import useKeyboardState from 'hooks/useKeyboardState'
import SafeSpace from 'redesign/components/atoms/SafeSpace'
import { convertPxToMuiSpacing } from 'redesign/themes/main/utils'
// TODO: Apply when redesign is finished for accessibility reasons
// import '@ionic/react/css/core.css'
// import '@ionic/react/css/normalize.css'
// import '@ionic/react/css/typography.css'
import { Content, ContentContainer } from './styles'
import { PageProps } from './types'

const Page: React.FC<PageProps> = ({
  children,
  header,
  backgroundColor,
  footer,
  center = false,
  flex = false,
  paddingX = 0.75,
  noPadding,
  columnCenter,
  fitToViewport,
  growContent = true,
  hasFloatingButtons,
  removeLastMargin,
  fullContentWidth = false,
  fullContentHeight = false,
  extraBottomMargin = 0,
}) => {
  const { isOpen, keyboardHeight } = useKeyboardState()
  const extraBotMargin = (isOpen ? convertPxToMuiSpacing(keyboardHeight) : 0) + extraBottomMargin
  const theme = useTheme()

  return (
    <SafeSpace>
      {header}
      <ContentContainer
        no-bounce
        has-bouncing="false"
        forceOverscroll={false}
        id="ionContent"
        backgroundColor={backgroundColor ?? theme.palette.common.backgroundApp}
      >
        <Content
          isFlex={flex}
          paddingX={paddingX}
          center={center}
          growContent={growContent}
          footer={Boolean(footer)}
          columnCenter={columnCenter}
          fitToViewport={fitToViewport}
          fullContentHeight={fullContentHeight}
          isOpen={isOpen}
          fullContentWidth={fullContentWidth}
          removeLastMargin={removeLastMargin}
          hasFloatingButtons={hasFloatingButtons}
          addSafeMargin={!header}
          noPadding={noPadding}
          extraBottomMargin={extraBotMargin}
        >
          {children}
        </Content>
      </ContentContainer>
      {footer}
    </SafeSpace>
  )
}

export default Page
