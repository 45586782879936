import React, { SyntheticEvent, useMemo } from 'react'
import { Box } from '@mui/material'
import { BoxedLoading } from 'components/LoadingIcon'
import Page from 'components/Page'
import WithBackwardLink from 'components/Page/Header/SubHeader/WithBackwardLink'
import TabContent from 'components/TabContent'
import Tabs from 'components/Tabs'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRefreshHighSecSession from 'hooks/useRefreshHighSecSession'
import { AlertS } from 'icons'
import { hasResignedManagers, isPermissionMissing } from 'pages/Teams/utils'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import type { TabT } from 'types'
import { getTeamNameByLanguage } from 'types/utils'
import theme from 'utils/theme'
import MembersTab from './Tabs/Members'
import RightsTab from './Tabs/Rights'
import SettingsTab from './Tabs/Settings'
import { classes } from './styles'
import { useTeam } from './useTeam'

enum TeamTabs {
  members,
  rights,
  settings,
}

const TeamPageContent: React.FC<{ tab?: string; uuid: string }> = ({ tab, uuid: teamUuid }) => {
  const { t, language } = useFavurTranslation()
  const { refreshHighSecTriggers } = useRefreshHighSecSession()
  const history = useHistory()
  const { team, personsByPermission, loading, refetch } = useTeam(teamUuid)
  const title = useMemo(() => (team ? getTeamNameByLanguage(team, language) : '-'), [language, team])
  const tabIndex = useMemo(() => {
    const possibleTabIndex = Object.values(TeamTabs).findIndex((value) => value === tab)
    return possibleTabIndex === -1 ? TeamTabs.members : possibleTabIndex
  }, [tab])
  const handleChange = (_event: SyntheticEvent<Element, Event>, newValue: number) => {
    if (newValue !== tabIndex) {
      history.push(`${routes.teams}/${teamUuid}/${TeamTabs[newValue]}`)
    }
  }

  const showAlertBadge = useMemo(() => {
    return isPermissionMissing(personsByPermission) || hasResignedManagers(team?.teamPermissions ?? [])
  }, [personsByPermission, team?.teamPermissions])

  return (
    <Page
      header={
        <WithBackwardLink
          route={team ? routes.teamsWithOwner(String(team.ownerId)) : routes.teams}
          title={title}
          topTitle={t('page.team.header.title')}
        />
      }
      hideNativeNavigation
      hasFloatingButtons
      noPadding
    >
      {!team || loading ? (
        <BoxedLoading />
      ) : (
        <Box {...refreshHighSecTriggers}>
          <Tabs
            currentTabIndex={tabIndex}
            handleChange={handleChange}
            tabs={
              [
                {
                  id: 0,
                  label: (
                    <Box sx={classes.tabLabel}>
                      <span>{t('teams.tabLabel.members')}</span>
                      {team?.teamPermissions?.length ? undefined : <AlertS fill={theme.palette.secondary.main} />}
                    </Box>
                  ),
                },
                {
                  id: 1,
                  label: (
                    <Box sx={classes.tabLabel}>
                      <span>{t('teams.tabLabel.rights')}</span>
                      {showAlertBadge ? <AlertS fill={theme.palette.secondary.main} /> : undefined}
                    </Box>
                  ),
                },
                {
                  id: 2,
                  label: t('teams.tabLabel.settings'),
                },
              ] as TabT[]
            }
          >
            <TabContent index={TeamTabs.members} currentTabIndex={tabIndex}>
              <MembersTab team={team} ownerHasMultipleTenants={(team.owner?.tenants?.length ?? 0) > 1} />
            </TabContent>
            <TabContent index={TeamTabs.rights} currentTabIndex={tabIndex}>
              <RightsTab team={team} personsByPermission={personsByPermission} />
            </TabContent>
            <TabContent index={TeamTabs.settings} currentTabIndex={tabIndex}>
              <SettingsTab team={team} personsByPermission={personsByPermission} refetch={refetch} />
            </TabContent>
          </Tabs>
        </Box>
      )}
    </Page>
  )
}

export default TeamPageContent
