import { useEffect, useReducer } from 'react'
import {
  MAX,
  SMOOTHNESS,
  TRIGGER_THRESHOLD,
  SHOW_INDICATOR_THRESHOLD,
  INITIAL_INDICATOR_STATE,
  PULL_TO_REFRESH_ARROW_STATES,
} from './constants'
import { reducer } from './reducer'
import type { UsePullToRefresh } from './types'

const appr = (x: number) => MAX * (1 - Math.exp((-SMOOTHNESS * x) / MAX))

const usePullToRefresh = ({ ref, onRefresh, disabled }: UsePullToRefresh) => {
  const [state, dispatch] = useReducer(reducer, INITIAL_INDICATOR_STATE)

  useEffect(() => {
    if (disabled) return

    const container = ref.current
    if (!container) return

    const onTransitionEnd = () => {
      container.style.transition = ''

      // cleanup
      container.removeEventListener('transitionend', onTransitionEnd)
    }

    const handleTouchStart = (startEvent: TouchEvent) => {
      if (!container) return

      if (container.scrollTop > 0) return

      const initialY = startEvent.touches[0].clientY

      const handleTouchMove = (moveEvent: TouchEvent) => {
        const currentY = moveEvent.touches[0].clientY
        const pullDistance = currentY - initialY

        if (pullDistance < 0) return

        container.style.transform = `translateY(${appr(pullDistance)}px)`

        if (pullDistance > TRIGGER_THRESHOLD) {
          dispatch({ type: PULL_TO_REFRESH_ARROW_STATES.flip })
        } else if (pullDistance > SHOW_INDICATOR_THRESHOLD) {
          dispatch({ type: PULL_TO_REFRESH_ARROW_STATES.show })
        } else {
          dispatch({ type: PULL_TO_REFRESH_ARROW_STATES.hide })
        }
      }

      const handleTouchEnd = (endEvent: TouchEvent) => {
        container.style.transform = 'translateY(0)'
        dispatch({ type: PULL_TO_REFRESH_ARROW_STATES.reset })

        container.style.transition = 'transform 0.2s'

        const y = endEvent.changedTouches[0].clientY
        const pullDistance = y - initialY
        if (pullDistance > TRIGGER_THRESHOLD) {
          onRefresh()
        }

        container.addEventListener('transitionend', onTransitionEnd)

        // cleanup
        container.removeEventListener('touchmove', handleTouchMove)
        container.removeEventListener('touchend', handleTouchEnd)
      }

      container.addEventListener('touchmove', handleTouchMove)
      container.addEventListener('touchend', handleTouchEnd)
    }

    container.addEventListener('touchstart', handleTouchStart)

    // eslint-disable-next-line consistent-return
    return () => {
      container.removeEventListener('touchstart', handleTouchStart)
    }
  }, [ref, onRefresh, disabled])

  return state
}

export default usePullToRefresh
