import type { TaskListAbsenceData, TaskListElement, TaskListElementData } from 'shared/graphql/graphql'
import { TASK_TYPES } from '../../constants'

export const isAbsenceRequestTaskData = (taskData?: TaskListElementData): taskData is TaskListAbsenceData =>
  taskData?.__typename === 'TaskListAbsenceData'

export const isMonthlySheetTask = (task?: TaskListElement): task is TaskListElement =>
  task?.taskType === TASK_TYPES.monthlySheet

export const isSharedUserDataTask = (task?: TaskListElement): task is TaskListElement =>
  task?.taskType === TASK_TYPES.shareUserData

export const isShiftUpdateTask = (task?: TaskListElement): task is TaskListElement =>
  task?.taskType === TASK_TYPES.shiftUpdate

export const isSecuredConnectionTask = (task?: TaskListElement): task is TaskListElement =>
  task?.taskType === TASK_TYPES.secureConnection
