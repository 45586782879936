import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { Button, Container } from './styles'
import type { LoadMoreProps } from './types'

const LoadMoreButton: React.FC<LoadMoreProps> = ({
  onClick,
  testId,
  variant = 'outlined',
  size = 'medium',
  loading,
}) => {
  const { t } = useFavurTranslation()

  return (
    <Container>
      <Button variant={variant} size={size} loading={loading} color="secondary" onClick={onClick} data-testid={testId}>
        {t('page.tasks.content.loadMore')}
      </Button>
    </Container>
  )
}

export default LoadMoreButton
