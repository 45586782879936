import { formatDateRange } from 'redesign/shared/date/utils'
import { newDateWithoutTime } from 'utils/date'
import { DEFAULT_DATE_FORMAT } from '../../constants'
import { GetShiftUpdateDatePeriod } from '../types'

// TODO: translations
export const getShiftUpdateDatePeriod = ({ shiftsUpdated = [], locale, t }: GetShiftUpdateDatePeriod) => {
  const shiftUpdatedCount = shiftsUpdated.length

  if (!shiftUpdatedCount) return 'No shift changes'

  const dates = {
    startDate: newDateWithoutTime(shiftsUpdated?.[0].date as string),
    endDate: newDateWithoutTime(shiftsUpdated?.[shiftUpdatedCount - 1]?.date as string),
  }

  const dateRange = formatDateRange({ dates, dateFormat: DEFAULT_DATE_FORMAT, locale, t })

  return `${dateRange} | ${shiftUpdatedCount} shift changes`
}
