import { ComponentsOverrides, Theme } from '@mui/material'
import { typographyVariants } from 'redesign/themes/main/typography'

const styleOverrides: ComponentsOverrides<Theme>['MuiFormLabel'] = {
  root: {
    ...typographyVariants.body1,
  },
}
const MuiFormLabel = {
  styleOverrides,
}

export default MuiFormLabel
