import React, { useCallback } from 'react'
import { Button, Typography } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useStateBackLink from 'hooks/useStateBackLink'
import { useHistory } from 'react-router-dom'
import Dialog from 'redesign/components/organisms/Dialog'
import routes from 'services/RoutesProvider/routes'
import { DOCUMENT_TRANSLATION_PREFIX } from './constants'
import { DocumentDialogProps } from './types'

const DocumentDialog: React.FC<DocumentDialogProps> = ({ dialog, onClose }) => {
  const { t } = useFavurTranslation()
  const { setBackLink } = useStateBackLink()
  const history = useHistory()
  const downloadMonthlySheet = useCallback(() => dialog.openFile(), [dialog])
  const acceptMonthlySheet = useCallback(() => {
    if (dialog.taskUuid) {
      setBackLink(routes.documents)
      history.push(routes.monthlySheetsWithTask(dialog.taskUuid))
    }
  }, [dialog, history, setBackLink])

  return (
    <Dialog
      open={dialog.open}
      title={t(`${DOCUMENT_TRANSLATION_PREFIX}.title`)}
      actions={
        <>
          <Button color="secondary" onClick={onClose} size="large">
            {t('common.close')}
          </Button>
          {dialog.isInactivePerson && (
            <Button color="secondary" size="large" data-testid="download-monthly-sheet" onClick={downloadMonthlySheet}>
              {t(`${DOCUMENT_TRANSLATION_PREFIX}.download`)}
            </Button>
          )}
          <Button color="secondary" size="large" data-testid="go-to-monthly-sheet-detail" onClick={acceptMonthlySheet}>
            {t(`${DOCUMENT_TRANSLATION_PREFIX}.toTaskButton`)}
          </Button>
        </>
      }
    >
      <Typography>{t(`${DOCUMENT_TRANSLATION_PREFIX}.content`)}</Typography>
    </Dialog>
  )
}

export default DocumentDialog
