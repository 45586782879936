import { datadogLogs } from '@datadog/browser-logs'

const BASE_WAIT = 250
const MAX_CALL_RETRIES = 2
const MAX_WAIT = 1000

const waitForAttempt = (attempt: number) =>
  new Promise((resolve) => {
    const waitTime = Math.min(MAX_WAIT, BASE_WAIT * 2 ** (attempt - 1))
    setTimeout(resolve, waitTime)
  })

type RetryOperationT = {
  operation: () => Promise<void>
  handleOperationError: () => void
  name: string
  maxRetries?: number
}

export const retryOperation = async ({
  operation,
  handleOperationError,
  name,
  maxRetries,
}: RetryOperationT): Promise<void> => {
  const retriesLeft = maxRetries ?? MAX_CALL_RETRIES
  try {
    datadogLogs.logger.info(`[Doing ${name}] Calling graphql`)
    await operation()
  } catch (err) {
    if (retriesLeft > 0) {
      datadogLogs.logger.warn(`[Doing ${name}] Attempt has failed... Pending attempts: ${retriesLeft}`)
      await waitForAttempt(MAX_CALL_RETRIES - retriesLeft)
      await retryOperation({ operation, handleOperationError, name, maxRetries: retriesLeft - 1 })
    } else {
      datadogLogs.logger.error(`[Doing ${name}] There was an unexpected error after three attempts`, {
        error: err,
      })
      handleOperationError()
    }
  }
}
