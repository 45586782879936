import React, { useRef } from 'react'
import { ArrowDownward } from '@mui/icons-material'
import { SimpleLoading } from 'components/LoadingIcon'
import ContentWrapper from 'redesign/components/atoms/ContentWrapper'
import { Container, PullIndicator } from './styles'
import { PullToRefreshContentWrapperProps } from './types'
import usePullToRefresh from './usePullToRefresh'

const PullToRefreshContentWrapper: React.FC<PullToRefreshContentWrapperProps> = ({
  children,
  disabled = false,
  isLoading = false,
  onRefresh,
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const { showIndicator, flipIndicator } = usePullToRefresh({ ref: contentRef, onRefresh, disabled })

  return (
    <ContentWrapper>
      {isLoading && <SimpleLoading small />}
      <Container ref={contentRef}>
        {showIndicator && (
          <PullIndicator className={flipIndicator ? 'flip' : ''}>
            <ArrowDownward color="primary" />
          </PullIndicator>
        )}
        {children}
      </Container>
    </ContentWrapper>
  )
}

export default PullToRefreshContentWrapper
