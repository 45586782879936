import React, { useMemo } from 'react'
// eslint-disable-next-line import/no-extraneous-dependencies
import { LocalizationProvider } from '@mui/x-date-pickers'
// eslint-disable-next-line import/no-extraneous-dependencies
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import ErrorPage from 'components/Splash/Error'
import UpdateApp from 'components/Splash/UpdateApp'
import ThemeProvider from 'components/ThemeProvider'
import { apiPath, getAPIURL } from 'constants/environment'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { absencePlannerTypePolicies } from 'pages/AbsencePlanner/types'
import { ErrorBoundary } from 'react-error-boundary'
import { ReactQueryConfigProvider } from 'react-query'
import Services from 'services'
import AnnouncementsService from 'services/AnnouncementsService'
import AppConfigurationOverrides from 'services/AppConfigurationOverrides'
import AxiosInterceptor from 'services/AxiosInterceptor'
import RoutesProvider from 'services/RoutesProvider'
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client'
import { IonApp, setupIonicReact } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'

setupIonicReact({
  mode: 'md',
})

const App: React.FC = () => {
  const client = useMemo(
    () =>
      new ApolloClient({
        link: createHttpLink({
          credentials: 'include',
          uri: `${getAPIURL()}${apiPath}`,
        }),
        cache: new InMemoryCache({
          typePolicies: {
            ...absencePlannerTypePolicies,
          },
        }),
      }),
    [],
  )
  const queryConfig = {
    queries: {
      refetchOnWindowFocus: false,
    },
  }
  const { locale } = useFavurTranslation()

  return (
    <IonApp>
      <ApolloProvider client={client}>
        {/* implicit children problem, must upgrade/change this dependency */}
        {/* @ts-ignore */}
        <ReactQueryConfigProvider config={queryConfig}>
          <ThemeProvider>
            <Services>
              <AppConfigurationOverrides>
                <IonReactRouter>
                  <AxiosInterceptor>
                    <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
                      <ErrorBoundary FallbackComponent={ErrorPage}>
                        <UpdateApp>
                          <AnnouncementsService>
                            <RoutesProvider />
                          </AnnouncementsService>
                        </UpdateApp>
                      </ErrorBoundary>
                    </LocalizationProvider>
                  </AxiosInterceptor>
                </IonReactRouter>
              </AppConfigurationOverrides>
            </Services>
          </ThemeProvider>
        </ReactQueryConfigProvider>
      </ApolloProvider>
    </IonApp>
  )
}

export default App
