import React, { useCallback } from 'react'
import { IconButton, useTheme } from '@mui/material'
import useStateBackLink from 'hooks/useStateBackLink'
import { useHistory } from 'react-router-dom'
import { ChevronLeft } from '../../Icon'

const BackButton: React.FC = () => {
  const theme = useTheme()
  const history = useHistory()
  const { pushBackLinkOrDefault } = useStateBackLink()

  const gotoBackLink = useCallback(() => {
    pushBackLinkOrDefault(() => {
      history.goBack()
    })
  }, [pushBackLinkOrDefault, history])

  return (
    <IconButton onClick={gotoBackLink}>
      <ChevronLeft color={theme.palette.text.secondary} />
    </IconButton>
  )
}

export default BackButton
