import React, { useEffect } from 'react'
import { FF_ENABLE_APP_ROTATION } from 'constants/featureFlags'
import useFeatureFlag from 'hooks/useFeatureFlag'
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation'
import { Capacitor } from '@capacitor/core'
import { SplashScreen } from '@capacitor/splash-screen'

const AppConfigurationOverrides = ({ children }: { children?: React.ReactNode }) => {
  const appRotationFF = useFeatureFlag(FF_ENABLE_APP_ROTATION)

  useEffect(() => {
    if (!Capacitor.isNativePlatform()) return

    if (!appRotationFF) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT)
    } else {
      ScreenOrientation.unlock()
    }

    SplashScreen.hide()
  }, [appRotationFF])

  return <>{children}</>
}

export default AppConfigurationOverrides
