import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { SecureConnectionCompletedNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getSecureConnectionCompletedData = ({ notification, t }: GetNotificationData) => {
  const { tenantName, taskUuid, frontlinerNameShort } = notification.data as SecureConnectionCompletedNotification
  const { title, body } = notificationGlosssaryKeys.secureConnectionCompleted
  return {
    title: t(title, {
      frontlinerNameShort,
    }),
    body: t(body, {
      tenantName,
    }),
    route: routes.secureConnectionWithTask(taskUuid ?? ''),
    role: ROLE_VIEWS.office,
  }
}
