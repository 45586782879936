import { TASK_TYPES } from '../constants'

export const EMPTY_CONTENT_DATA = {
  bodyText: '',
  startText: '',
  secondaryText: '',
  endText: '',
}

export const HEADER_MANAGER_TRANSLATIONS = {
  [TASK_TYPES.absenceRequest]: 'task.headlineManager.absenceRequest',
  [TASK_TYPES.monthlySheet]: 'task.headlineManager.workTime',
  [TASK_TYPES.shiftUpdate]: 'task.headlineManager.shiftUpdate',
  [TASK_TYPES.shareUserData]: 'task.headlineManager.shareData',
  [TASK_TYPES.secureConnection]: 'task.headlineManager.secureConnection',
} as const

export const HEADER_FRONTLINER_TRANSLATIONS = {
  [TASK_TYPES.absenceRequest]: 'task.headline.absenceRequest',
  [TASK_TYPES.monthlySheet]: 'task.headline.workTime',
  [TASK_TYPES.shiftUpdate]: 'task.headline.shiftUpdate',
  [TASK_TYPES.shareUserData]: 'task.headline.shareData',
  [TASK_TYPES.secureConnection]: 'task.headline.secureConnection',
} as const
