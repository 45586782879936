/* eslint-disable  camelcase */
const badgeGlossary: Record<string, string> = {
  work_time_accepted: 'timeline.badge.workTimeAccepted',
  wtc_change_request: 'timeline.badge.workTimeControlChangeRequest',
  accepted_for_this_month: 'timeline.badge.acceptedForThisMonth',
  accepted_for_next_month: 'timeline.badge.acceptedForNextMonth',
  rejected: 'timeline.badge.rejected',
  called: 'timeline.badge.called',
  reminder: 'timeline.badge.reminder',
  absence_requested: 'timeline.badge.absence_requested',
  absence_request_accepted: 'timeline.badge.absence_request_accepted',
  absence_request_rejected: 'timeline.badge.absence_request_rejected',
  deletion_request: 'timeline.badge.deletion_request',
  deletion_confirmed: 'timeline.badge.deletion_confirmed',
  deletion_denied: 'timeline.badge.deletion_denied',
}

export const getBadgeGlossaryKey = (badge: string): string => badgeGlossary[badge] || '-'
