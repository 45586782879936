import { ROLE_VIEWS } from 'constants/roles'
import { absenceRequestStatus } from 'pages/Tasks/AbsenceRequest/constants'
import routes from 'services/RoutesProvider/routes'
import { absencePeriodsTranslationMap, absencePeriods } from 'shared/constants'
import { ResolvedAbsenceRequestRequestDeletionNotification } from 'shared/graphql/graphql'
import { absenceRequestDateFormat } from 'utils/constants'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getResolveAbsenceRequestDeletionData = ({ notification, t, locale }: GetNotificationData) => {
  const {
    managerNameShort,
    state,
    taskUuid,
    period,
    dateFrom: notificationDatefrom,
    dateTo: notificationDateTo,
  } = notification.data as ResolvedAbsenceRequestRequestDeletionNotification
  const { title: acceptedTitle, body: multidayBody } = notificationGlosssaryKeys.resolvedAbsenceRequestRequestDeletion
  const {
    title: declinedTitle,
    body: singledayBody,
  } = notificationGlosssaryKeys.resolvedAbsenceRequestRequestDeletionDeclined

  const dateFrom = getFormattedFromDate(
    newDateWithoutTime(notificationDatefrom ?? ''),
    absenceRequestDateFormat,
    locale,
  )
  const dateTo = getFormattedFromDate(newDateWithoutTime(notificationDateTo ?? ''), absenceRequestDateFormat, locale)
  const isMultiday = dateFrom !== dateTo
  const absencePeriod = () => {
    if (isMultiday || !period || period.trim() === '') {
      return ''
    }
    const translatedPeriod = absencePeriodsTranslationMap[period]
    return t(absencePeriods[translatedPeriod])
  }
  const titleKey = state === absenceRequestStatus.acceptedDeletionRequest ? acceptedTitle : declinedTitle

  const body = t(isMultiday ? multidayBody : singledayBody, { dateFrom, dateTo, absencePeriod: absencePeriod() })

  return {
    title: t(titleKey, {
      managerNameShort,
    }),
    body: body,
    route: `${routes.absenceRequestDetail}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
