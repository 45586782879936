import React, { useCallback, useState } from 'react'
import { Box, Typography } from '@mui/material'
import ActionCardButtons from 'components/Buttons/ActionCardButtons'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { TFunction } from 'i18next'
import DeleteWorkflow from 'pages/Tasks/components/ActionButtons/DeleteWorkflow'
import Plus from 'pages/Tasks/components/ActionButtons/Plus'
import SendComment from 'pages/Tasks/components/ActionButtons/SendComment'
import SendReminder from 'pages/Tasks/components/ActionButtons/SendReminder'
import { taskStates } from 'pages/Tasks/constants'
import { EventsOfTask, SecureConnectionTaskT, TaskT } from 'pages/Tasks/types'
import Start from './Start'
import { classes } from './styles'

interface IActionButtonsProps {
  task: TaskT<SecureConnectionTaskT>
  isOffice: boolean
  onAction: () => void
}

type IActionButtonsPropsWithT = IActionButtonsProps & {
  t: TFunction
  showWarning: boolean
  onChangeInput?: (newValue: string) => void
}

const SendCommentBody = ({ text }: { text: string }) => {
  return (
    <Box sx={classes.sendCommentBody}>
      <Typography variant="body2" sx={classes.sendCommentTitle}>
        {text}
      </Typography>
    </Box>
  )
}

const getButtonsFromStateFrontline = (
  task: TaskT<SecureConnectionTaskT>,
  onAction: () => void,
  t: TFunction,
  showWarning: boolean,
  onChangeInput?: (newValue: string) => void,
) => {
  const taskUuid = task.favurUuid

  if (task.statusFe === taskStates.todo) {
    return (
      <ActionCardButtons>
        <Start taskUuid={taskUuid} />
        <Plus>
          <SendComment
            event={EventsOfTask.secureConnectionSendCommentToManager}
            taskUuid={taskUuid}
            onAction={onAction}
            warningText={showWarning ? t('tasks.new.secureconnection.warningContent') : undefined}
            onChangeInput={onChangeInput}
            sendCommentHeader={<SendCommentBody text={t('secureConnection.sendComment.title')} />}
            required
          />
        </Plus>
      </ActionCardButtons>
    )
  }
  return <></>
}

const getButtonsFromStateOffice = (
  task: TaskT<SecureConnectionTaskT>,
  onAction: () => void,
  t: TFunction,
  showWarning: boolean,
  onChangeInput?: (newValue: string) => void,
) => {
  const taskUuid = task.favurUuid

  if (task.statusFe === taskStates.pending) {
    return (
      <ActionCardButtons>
        <Plus>
          <SendComment
            event={EventsOfTask.secureConnectionSendCommentToFrontline}
            taskUuid={taskUuid}
            onAction={onAction}
            warningText={showWarning ? t('tasks.new.secureconnection.warningContent') : undefined}
            onChangeInput={onChangeInput}
            sendCommentHeader={<SendCommentBody text={t('secureConnection.sendComment.title')} />}
            required
          />
          <SendReminder
            taskUuid={taskUuid}
            onAction={onAction}
            label={t('secureConnection.buttons.sendReminder')}
            isOffice
          />
          <DeleteWorkflow task={task} label={t('secureConnection.buttons.deleteWorkflow')} />
        </Plus>
      </ActionCardButtons>
    )
  }
  return <></>
}

const getButtonsFromTaskAndView = ({
  task,
  isOffice,
  onAction,
  t,
  showWarning,
  onChangeInput,
}: IActionButtonsPropsWithT) => {
  if (task.statusFe === taskStates.closed) {
    return <></>
  }
  if (isOffice) {
    return getButtonsFromStateOffice(task, onAction, t, showWarning, onChangeInput)
  }

  return getButtonsFromStateFrontline(task, onAction, t, showWarning, onChangeInput)
}

const ActionButtons: React.FC<IActionButtonsProps> = ({ task, isOffice, onAction }) => {
  const { t } = useFavurTranslation()
  const [showWarning, setShowWarning] = useState(false)

  const onChangeInput = useCallback((newValue: string) => {
    const match = newValue.match(/[0-9]/gm)
    setShowWarning(Boolean(match?.length))
  }, [])

  return <>{getButtonsFromTaskAndView({ task, isOffice, onAction, t, showWarning, onChangeInput })}</>
}
export default ActionButtons
