import { INITIAL_INDICATOR_STATE, PULL_TO_REFRESH_ARROW_STATES } from './constants'
import type { IndicatorAction, IndicatorState } from './types'

export const reducer = (state: IndicatorState, action: IndicatorAction): IndicatorState => {
  switch (action.type) {
    case PULL_TO_REFRESH_ARROW_STATES.show:
      return { ...state, showIndicator: true, flipIndicator: false }
    case PULL_TO_REFRESH_ARROW_STATES.hide:
      return { ...state, showIndicator: false, flipIndicator: false }
    case PULL_TO_REFRESH_ARROW_STATES.flip:
      return { ...state, flipIndicator: true }
    case PULL_TO_REFRESH_ARROW_STATES.reset:
      return INITIAL_INDICATOR_STATE
    default:
      return INITIAL_INDICATOR_STATE
  }
}
