import { SvgIcon, styled } from '@mui/material'
import { convertPxToMuiSpacing } from 'redesign/themes/main/utils'

export const Svg = styled(SvgIcon, {
  shouldForwardProp: (prop) => prop !== 'width' && prop !== 'height' && prop !== 'fill' && prop !== 'size',
})<{
  width?: number
  height?: number
  size?: number
  fill?: string
}>`
  fill: ${({ fill }) => fill || 'currentcolor'};
  width: ${({ size, width, theme }) => theme.spacing(convertPxToMuiSpacing((width || size) ?? 0))};
  height: ${({ size, height, theme }) => theme.spacing(convertPxToMuiSpacing((height || size) ?? 0))};
`
