import React from 'react'
import { Typography } from '@mui/material'
import LoadingSpinner from 'redesign/components/atoms/LoadingSpinner'
import { EmptyContainer, ListContainer } from './styles'
import type { ListWrapperProps } from './types'

const ListWrapper: React.FC<ListWrapperProps> = ({ hasContent, message, isLoading, children }) => {
  if (!hasContent) {
    return (
      <EmptyContainer>
        {isLoading && <LoadingSpinner />}
        {/* Placeholder until design */}
        {!isLoading && <Typography variant="h3">{message}</Typography>}
      </EmptyContainer>
    )
  }
  return <ListContainer>{children}</ListContainer>
}

export default ListWrapper
