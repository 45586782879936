import { gql } from '@apollo/client'

export const authStartMutationApollo = gql`
  mutation authStart($phone: String!, $appSignature: String) {
    authStart(phone: $phone, appSignature: $appSignature) {
      auth
      invitationCode
      user {
        phoneNumber
      }
    }
  }
`
