import React from 'react'
import AbsencePlanner from 'pages/AbsencePlanner'
import ContentIndexEvent from 'pages/Cms/EventsList'
import ContentIndexNewsletter from 'pages/Cms/NewsletterList'
import ContentOverviewPage from 'pages/Cms/OverviewPage'
import ContentIndexPage from 'pages/Cms/PagesList'
import RedirectCmsPage from 'pages/Cms/Redirect'
import ContentViewPage from 'pages/Cms/View'
import EmployeeCardPage from 'pages/EmployeeCard'
import RequestEmployeeData from 'pages/RequestEmployeeData'
import FrontlinerTasks from 'pages/Tasks'
import DetailsAbsenceRequestPage from 'pages/Tasks/AbsenceRequest/Details'
import NewAbsenceRequest from 'pages/Tasks/AbsenceRequest/New'
import UserDataDetails from 'pages/Tasks/ShareUserData/Details'
import ShiftUpdateDetails from 'pages/Tasks/ShiftUpdate/Details'
import TeamsPage from 'pages/Teams'
import TeamPage from 'pages/Teams/TeamPage'
import MemberDetail from 'pages/Teams/TeamPage/Tabs/Members/Detail'
import EditMembers from 'pages/Teams/TeamPage/Tabs/Members/Edit'
import EditPermissionMembers from 'pages/Teams/TeamPage/Tabs/Rights/Edit'
import EditTeamSettings from 'pages/Teams/TeamPage/Tabs/Settings/Edit'
import PersonDetail from 'pages/Teams/components/TeamsList/PersonDetail'
import PersonList from 'pages/Teams/components/TeamsList/PersonList'
import TenantPage from 'pages/UserSettings/pages/Tenant'
import TenantReminderListPage from 'pages/UserSettings/pages/TenantReminderList'
import TenantRemindersPage from 'pages/UserSettings/pages/TenantReminders'
import TenantsPage from 'pages/UserSettings/pages/Tenants'
import TaskListPage from 'redesign/pages/TaskList'
import ProtectedRoute from 'utils/ProtectedRoute'
import routes from './routes'
import type { ConditionalRoutes } from './types'

export const routesWithAbsenceWorkflow = (isAuthenticated: boolean) => [
  <ProtectedRoute
    key={routes.absenceRequestDetail}
    path={`${routes.absenceRequestDetail}/:taskUuid`}
    // @ts-ignore
    component={DetailsAbsenceRequestPage}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={routes.newAbsenceRequest}
    path={routes.newAbsenceRequest}
    // @ts-ignore
    component={NewAbsenceRequest}
    exact
    isAllowed={isAuthenticated}
  />,
]

export const classicPackageRoutes = ({ isAuthenticated, redesignFF }: ConditionalRoutes) => [
  <ProtectedRoute
    key={routes.tasks}
    path={`${routes.tasks}/:taskstate?`}
    // @ts-ignore
    component={redesignFF ? TaskListPage : FrontlinerTasks}
    exact
    isAllowed={isAuthenticated}
    isRedesignTheme={redesignFF}
  />,
  <ProtectedRoute
    key={routes.userDataDetails}
    path={`${routes.userDataDetails}/:favurUuid`}
    // @ts-ignore
    component={UserDataDetails}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={routes.shiftUpdateDetail}
    path={`${routes.shiftUpdateDetail}/:favurUuid/:status`}
    // @ts-ignore
    component={ShiftUpdateDetails}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={routes.employeeCard}
    path={`${routes.employeeCard}`}
    // @ts-ignore
    component={EmployeeCardPage}
    exact
    isAllowed={isAuthenticated}
  />,
]

export const teamRoutes = (isAuthenticated: boolean) => [
  <ProtectedRoute
    key={routes.teams}
    path={routes.teams}
    // @ts-ignore
    component={TeamsPage}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={`${routes.teams}/owner/:ownerId`}
    path={`${routes.teams}/owner/:ownerId`}
    // @ts-ignore
    component={TeamsPage}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={`${routes.teams}/owner/:ownerId/members/status/:status`}
    path={`${routes.teams}/owner/:ownerId/members/status/:status`}
    // @ts-ignore
    component={PersonList}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={`${routes.teams}/owner/:ownerId/members/:favurUuid`}
    path={`${routes.teams}/owner/:ownerId/members/:favurUuid`}
    // @ts-ignore
    component={PersonDetail}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={`${routes.teams}/:uuid/:tab?`}
    path={`${routes.teams}/:uuid/:tab?`}
    // @ts-ignore
    component={TeamPage}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={`${routes.teams}/:uuid/${routes.teamEditMembers}`}
    path={`${routes.teams}/:uuid/${routes.teamEditMembers}`}
    // @ts-ignore
    component={EditMembers}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={`${routes.teams}/:uuid/${routes.teamDetailMember}/:favurUuid`}
    path={`${routes.teams}/:uuid/${routes.teamDetailMember}/:favurUuid`}
    // @ts-ignore
    component={MemberDetail}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={`${routes.teams}/:uuid/${routes.teamEditPermissionMembers}/:permission`}
    path={`${routes.teams}/:uuid/${routes.teamEditPermissionMembers}/:permission`}
    // @ts-ignore
    component={EditPermissionMembers}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={`${routes.teams}/:uuid/${routes.teamEditSettings}`}
    path={`${routes.teams}/:uuid/${routes.teamEditSettings}`}
    // @ts-ignore
    component={EditTeamSettings}
    exact
    isAllowed={isAuthenticated}
  />,
]

export const tenantRoutes = (isAuthenticated: boolean) => [
  <ProtectedRoute
    key={routes.tenants}
    path={routes.tenants}
    // @ts-ignore
    component={TenantsPage}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={routes.tenant}
    path={`${routes.tenant}/:tenantUuid`}
    // @ts-ignore
    component={TenantPage}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={routes.tenantReminders(':tenantUuid')}
    path={routes.tenantReminders(':tenantUuid')}
    // @ts-ignore
    component={TenantRemindersPage}
    exact
    isAllowed={isAuthenticated}
  />,
  <ProtectedRoute
    key={routes.tenantRemindersList(':tenantUuid', ':workflowType')}
    path={routes.tenantRemindersList(':tenantUuid', ':workflowType')}
    // @ts-ignore
    component={TenantReminderListPage}
    exact
    isAllowed={isAuthenticated}
  />,
]

export const officeRoutes = (isAuthenticated: boolean) => [
  <ProtectedRoute
    key={`${routes.requestEmployeeData}/:mode`}
    path={`${routes.requestEmployeeData}/:mode`}
    // @ts-ignore
    component={RequestEmployeeData}
    exact
    isAllowed={isAuthenticated}
  />,
]

export const absencePlannerRoutes = (isAuthenticated: boolean, hasClassicAccess: boolean) => [
  <ProtectedRoute
    key={routes.absencePlanner}
    path={routes.absencePlanner}
    // @ts-ignore
    component={AbsencePlanner}
    exact
    isAllowed={isAuthenticated}
    classicModuleAccess={hasClassicAccess}
  />,
]

export const cmsRoutes = (isAuthenticated: boolean, hasClassicAccess: boolean) => [
  <ProtectedRoute
    key={routes.cmsContentView}
    path={`${routes.cmsContentView}/:contentUuid`}
    // @ts-ignore
    component={ContentViewPage}
    exact
    isAllowed={isAuthenticated}
    classicModuleAccess={hasClassicAccess}
  />,
  <ProtectedRoute
    key={routes.cmsContentIndex}
    path={routes.cmsContentIndex}
    // @ts-ignore
    component={ContentIndexPage}
    exact
    isAllowed={isAuthenticated}
    classicModuleAccess={hasClassicAccess}
  />,
  <ProtectedRoute
    key={routes.cmsContentIndex}
    path={`${routes.cmsContentIndex}/:ownerId`}
    // @ts-ignore
    component={ContentIndexPage}
    exact
    isAllowed={isAuthenticated}
    classicModuleAccess={hasClassicAccess}
  />,
  <ProtectedRoute
    key={routes.cmsOverview}
    path={routes.cmsOverview}
    // @ts-ignore
    component={ContentOverviewPage}
    exact
    isAllowed={isAuthenticated}
    classicModuleAccess={hasClassicAccess}
  />,
  <ProtectedRoute
    key={routes.cmsEventIndex}
    path={routes.cmsEventIndex}
    // @ts-ignore
    component={ContentIndexEvent}
    exact
    isAllowed={isAuthenticated}
    classicModuleAccess={hasClassicAccess}
  />,
  <ProtectedRoute
    key={routes.cmsEventIndex}
    path={`${routes.cmsEventIndex}/:ownerId`}
    // @ts-ignore
    component={ContentIndexEvent}
    exact
    isAllowed={isAuthenticated}
    classicModuleAccess={hasClassicAccess}
  />,
  <ProtectedRoute
    key={routes.cmsNewsletterIndex}
    path={routes.cmsNewsletterIndex}
    // @ts-ignore
    component={ContentIndexNewsletter}
    exact
    isAllowed={isAuthenticated}
    classicModuleAccess={hasClassicAccess}
  />,
  <ProtectedRoute
    key={routes.cmsNewsletterIndex}
    path={`${routes.cmsNewsletterIndex}/:ownerId`}
    // @ts-ignore
    component={ContentIndexNewsletter}
    exact
    isAllowed={isAuthenticated}
    classicModuleAccess={hasClassicAccess}
  />,
  <ProtectedRoute
    key={routes.cmsRedirect}
    path={`${routes.cmsRedirect}`}
    // @ts-ignore
    component={RedirectCmsPage}
    isAllowed={isAuthenticated}
    noPushRegistration
  />,
]
