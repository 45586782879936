import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { ReminderNotification } from 'shared/graphql/graphql'
import { uiDateFormat } from 'utils/constants'
import { getDeadlineTranslationKey, getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getReminderData = ({ notification, t }: GetNotificationData) => {
  const { taskUuid, dateTo, dateFrom, reminderDeadline } = notification.data as ReminderNotification

  return {
    title: t(notificationGlosssaryKeys.reminder.title),
    body: t(notificationGlosssaryKeys.reminder.body, {
      dateEnd: getFormattedFromDate(newDateWithoutTime(dateTo ?? ''), uiDateFormat),
      dateStart: getFormattedFromDate(newDateWithoutTime(dateFrom ?? ''), uiDateFormat),
      reminderDeadline: t(getDeadlineTranslationKey(reminderDeadline as '24h')),
    }),
    route: taskUuid ? routes.monthlySheetsWithTask(taskUuid) : '',
    role: ROLE_VIEWS.frontliner,
  }
}
