import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import {
  ReminderShareUserDataManualNotification,
  ReminderShareUserDataManualNotificationForManager,
} from 'shared/graphql/graphql'
import { NotificationWithManagerProps } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getShareUserDataManualReminder = ({ notification, t, isManager }: NotificationWithManagerProps) => {
  const data = notification.data as
    | ReminderShareUserDataManualNotification
    | ReminderShareUserDataManualNotificationForManager

  const { taskUuid } = data
  const { title, body } = isManager
    ? notificationGlosssaryKeys.reminderShareUserDataManualForManager
    : notificationGlosssaryKeys.reminderShareUserDataManual

  return {
    title: t(title, { ...data }),
    body: t(body),
    route: `${routes.userDataDetails}/${taskUuid}`,
    role: ROLE_VIEWS.frontliner,
  }
}
