import { formatDate } from 'redesign/shared/date'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { newDateWithoutTime } from 'utils/date'
import { GetDateFormatFromTask } from '../types'

export const getDateFormatFromTask = ({
  date,
  dateFormat = DATE_FORMAT_VARIANTS.shortWithoutWeekday,
  locale,
  t,
}: GetDateFormatFromTask) =>
  formatDate({
    date: newDateWithoutTime(date),
    dateFormat,
    locale,
    t,
  })
