import React from 'react'
import { useTheme } from '@mui/material'
import { Frame } from './styles'
import { FramedIconProps } from './types'

const FramedIcon: React.FC<FramedIconProps> = ({ icon: Icon, color }) => {
  const theme = useTheme()
  const iconColor = color ? color : theme.palette.primary.main

  return (
    <Frame>
      <Icon color={iconColor} />
    </Frame>
  )
}

export default FramedIcon
