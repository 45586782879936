import React from 'react'
import { IconButton, useTheme } from '@mui/material'
import { FilterIcon } from '../../Icon'
import type { FilterButtonProps } from './types'

const FilterButton: React.FC<FilterButtonProps> = ({ isPrimaryButton = true }) => {
  const theme = useTheme()

  return (
    <IconButton size="medium">
      <FilterIcon color={isPrimaryButton ? theme.palette.primary.main : theme.palette.text.primary} />
    </IconButton>
  )
}

export default FilterButton
