import { gql } from '@apollo/client'

export const documentsPaginatedQuery = gql`
  query documentsPaginated($filters: DocumentFilters, $limit: Int, $offset: Int) {
    documentsPaginated(filters: $filters, limit: $limit, offset: $offset) {
      totalCount
      list {
        id
        person {
          id
          status
          tenant {
            name
            package
          }
        }
        documentType
        documentUuid
        favurUuid
        status
        data
        lang
        createdByMirus
        taskMonthlySheet {
          task {
            favurUuid
          }
        }
      }
    }
  }
`

export const dismissBasicDocumentsMutation = gql`
  mutation dismissBasicDocumentNotifications {
    dismissBasicDocumentNotifications {
      success
    }
  }
`
