import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'

const variants: ComponentsVariants['MuiLink'] = [
  {
    props: { color: 'inherit' },
    style: {
      textDecorationColor: 'color-mix(in srgb, currentColor 50%, transparent)',
    },
  },
]

const styleOverrides: ComponentsOverrides<Theme>['MuiLink'] = {
  root: {
    textUnderlineOffset: '0.2375rem',
    ':focus': {
      borderRadius: '0.25rem',
      padding: '0.125rem 0',
      textDecoration: 'none',
      outline: '0.0625px solid color-mix(in srgb, currentColor 50%, transparent);',
    },
    variants,
  },
}
const MuiLink = {
  styleOverrides,
}

export default MuiLink
