import { Shift } from 'shared/graphql/graphql'
import { TASK_TYPES } from '../../constants'
import { getContentStatus } from '../../getContentStatus'
import { getDateFormatFromTask } from '../Dates/getDateFormatFromTask'
import { getShiftUpdateDatePeriod } from '../Dates/getShiftUpdateDatePeriod'
import { EMPTY_CONTENT_DATA } from '../constants'
import type { GetTaskListData } from '../types'
import { getTaskState } from './getTaskState'
import { isShiftUpdateTask } from './taskDataTypeGuard'

export const getShiftUpdateData = ({ task, locale, t }: GetTaskListData) => {
  if (!isShiftUpdateTask(task)) return EMPTY_CONTENT_DATA

  const { status, updatedAt, statusFe, shiftsUpdated: shifts } = task
  const shiftsUpdated = shifts?.filter((shift): shift is Shift => shift !== null) ?? []

  return {
    bodyText: getShiftUpdateDatePeriod({ shiftsUpdated, locale, t }),
    startText: t(getTaskState({ statusFe })),
    secondaryText: t(getContentStatus({ status, taskType: TASK_TYPES.shiftUpdate })),
    endText: getDateFormatFromTask({ date: updatedAt, locale, t }),
  }
}
