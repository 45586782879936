import { numberOfShiftsOnDashboard, shiftQueryPollInterval } from 'constants/shift'
import type { QueryDates } from 'types'
import { getFirstNShifts } from 'utils/shifts'
import { effectiveShiftListQuery, shiftListQuery } from '../components/ShiftsList/queries'
import type { ShiftsByDateT } from '../components/ShiftsList/types'
import { useJamesApolloQuery } from './useJamesApolloQuery'

const useShifts = ({
  dates,
  displayFull = true,
  personIds = [],
  isEffective = false,
  enabled = true,
}: {
  dates: QueryDates
  displayFull?: boolean
  personIds?: number[]
  isEffective?: boolean
  enabled?: boolean
}) => {
  const query = isEffective ? effectiveShiftListQuery(dates) : shiftListQuery(dates, personIds)
  const { data, loading, error } = useJamesApolloQuery<{
    shiftsFavurByDate?: ShiftsByDateT[]
    effectiveShiftsFavur?: ShiftsByDateT[]
    isMultiTenant: { isMultiTenant: boolean }
  }>(query, { skip: !enabled, fetchPolicy: 'cache-and-network', pollInterval: shiftQueryPollInterval })
  const shiftsByDate = isEffective ? data?.effectiveShiftsFavur || [] : data?.shiftsFavurByDate || []
  const shifts = displayFull
    ? shiftsByDate
    : getFirstNShifts({ shiftsByDate, numberOfShifts: numberOfShiftsOnDashboard })
  const isMultitenant = data?.isMultiTenant?.isMultiTenant ?? false

  return { shifts, isMultitenant, loading, error }
}

export default useShifts
