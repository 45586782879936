import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import {
  SecureConnectionCommentForFrontlinerNotification,
  SecureConnectionCommentForManagerNotification,
} from 'shared/graphql/graphql'
import { NotificationWithManagerProps } from '../types'
import { getBadgeGlossaryKey } from '../utils'
import { notificationGlosssaryKeys } from './constants'

export const getSecureConnectionComment = ({ notification, t, isManager }: NotificationWithManagerProps) => {
  const data = notification.data as
    | SecureConnectionCommentForManagerNotification
    | SecureConnectionCommentForFrontlinerNotification
  const { commentShort, taskUuid, badge } = data
  const { title, body } = isManager
    ? notificationGlosssaryKeys.secureConnectionCommentForManager
    : notificationGlosssaryKeys.secureConnectionCommentForFrontliner

  return {
    title: t(title, { ...data }),
    body: `${t(body)} ${commentShort ?? t(getBadgeGlossaryKey(badge ?? ''))}`,
    route: routes.secureConnectionWithTask(taskUuid ?? ''),
    role: ROLE_VIEWS.frontliner,
  }
}
