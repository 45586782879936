import { gql } from '@apollo/client'

export const deleteTeamMutation = gql`
  mutation deleteTeam($id: String!) {
    deleteTeam(id: $id) {
      success
    }
  }
`

export const updateTeamNameMutation = gql`
  mutation updateTeamName($id: String!, $nameEn: String!, $nameDe: String!, $nameFr: String!, $nameIt: String!) {
    updateTeamName(id: $id, nameEn: $nameEn, nameDe: $nameDe, nameFr: $nameFr, nameIt: $nameIt) {
      success
    }
  }
`

export const updateTeamPublicStatusMutation = gql`
  mutation updateTeamPublicStatus($id: String!, $public: Boolean!) {
    updateTeamPublicStatus(id: $id, public: $public) {
      success
    }
  }
`

export const updateTeamShowTeamplanCostCentersMutation = gql`
  mutation updateTeamShowTeamplanCostCentersStatus($id: String!, $showTeamplanCostCenters: Boolean!) {
    updateTeamShowTeamplanCostCentersStatus(id: $id, showTeamplanCostCenters: $showTeamplanCostCenters) {
      success
    }
  }
`
