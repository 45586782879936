import { format, isThisYear, isToday, isYesterday, isTomorrow } from 'date-fns'
import { FORMATS_WITHOUT_WEEKDAYS, DATE_FORMATS, DATE_FORMAT_VARIANTS } from './constants'
import { FormatDate, FormatDateRange, GetDay, GetFormatString } from './types'

export const getDay = ({ date, locale, t }: GetDay) => {
  if (isYesterday(date)) return `${t('page.date.yesterday')},`
  if (isToday(date)) return `${t('page.date.today')},`
  if (isTomorrow(date)) return `${t('page.date.tomorrow')},`

  return format(date, 'EEE', { locale })
}

export const getFormatString = ({ date, dateFormat }: GetFormatString) =>
  isThisYear(date) ? DATE_FORMATS[dateFormat].currentYear : DATE_FORMATS[dateFormat].otherYear

export const formatDate = ({ date, dateFormat, endDate, locale, t }: FormatDate) => {
  if (!date || !dateFormat) return ''

  const formatStr = getFormatString({ date, dateFormat })
  const formattedDate = format(date, formatStr, { locale })

  if (FORMATS_WITHOUT_WEEKDAYS.includes(dateFormat)) {
    return formattedDate
  }

  const formattedDateWithWeekday = `${getDay({ date, locale, t })} ${formattedDate}`
  const hasTimeRange = endDate && dateFormat === DATE_FORMAT_VARIANTS.shortWithWeekdayAndTimeRange

  return hasTimeRange ? `${formattedDateWithWeekday} - ${format(endDate, 'HH:mm')}` : formattedDateWithWeekday
}

export const formatDateRange = ({ dates, dateFormat, locale, t }: FormatDateRange) => {
  const { startDate, endDate } = dates
  if (!startDate || !dateFormat) return ''

  return endDate
    ? `${formatDate({ date: startDate, dateFormat, locale, t })} - ${formatDate({
        date: endDate,
        dateFormat,
        locale,
        t,
      })}`
    : `${formatDate({ date: startDate, dateFormat, locale, t })}`
}
