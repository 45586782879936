import React from 'react'
import { Divider } from '@mui/material'
import { MiddleContainer, HeaderContainer, EndContainer, StartContainer, MainContainer, HeaderContent } from './styles'
import type { HeaderProps } from './types'

const Header: React.FC<HeaderProps> = ({
  startContent,
  middleContent,
  endContent,
  subheader,
  withoutDivider = false,
}) => {
  return (
    <MainContainer>
      <HeaderContainer>
        <HeaderContent>
          <StartContainer>{startContent}</StartContainer>
          <MiddleContainer>{middleContent}</MiddleContainer>
          <EndContainer>{endContent}</EndContainer>
        </HeaderContent>
      </HeaderContainer>
      {subheader}
      {!withoutDivider && <Divider />}
    </MainContainer>
  )
}

export default Header
