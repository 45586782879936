import { useEffect, useContext, useCallback } from 'react'
import { ROLE_VIEWS } from 'constants/roles'
import { shiftUpdateTypes } from 'constants/shift'
import { tutorialTypes } from 'constants/tutorials'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useJamesMutation from 'hooks/useJamesMutation'
import { useShiftsContext } from 'hooks/useShiftsContext'
import { taskStates } from 'pages/Tasks/constants'
import { resignationTenantNameKey } from 'pages/Tutorial/Resignation'
import { useHistory } from 'react-router'
import { useAppStatesContext } from 'services/AppStates'
import FlashMessagesContext from 'services/FlashMessages/context'
import PushNotificationContext from 'services/PushNotifications/context'
import routes from 'services/RoutesProvider/routes'
import SessionContext from 'services/Session/context'
import { notificationQueryReturnValues } from 'shared/queries'
import { NotificationT, NotificationTypes } from 'types'
import { isAndroidNative, isNativeNoOverride } from 'utils/platform'
import { gql, useApolloClient } from '@apollo/client'
import { PushNotifications, Token, ActionPerformed } from '@capacitor/push-notifications'
import { getPlatforms } from '@ionic/react'
import useHistoryUtils from './useHistoryUtils'

const platform = getPlatforms().join(', ')

const usePSN = ({ refresh = true }: { refresh?: boolean }) => {
  const { language } = useFavurTranslation()
  const history = useHistory()
  const { setShiftsContext } = useShiftsContext()
  const { setFlashMessage } = useContext(FlashMessagesContext)
  const { pushWithRole } = useHistoryUtils()
  const { set } = useAppStatesContext()
  const client = useApolloClient()

  const {
    pushActiveOnDevice,
    pushGenericCallbacksRegistered,
    setPushActiveOnDevice,
    setPushGenericCallbacksRegistered,
  } = useContext(PushNotificationContext)

  const { setRegisteredToken } = useContext(SessionContext)
  const defaultRedirectAction = useCallback(() => {
    history.push(routes.dashboard)
  }, [history])

  const [registerDeviceTokenForPushMutation] = useJamesMutation(
    `registerDevice(token: "#token", platform: "#platform", language: "#language") {
      success
    }`,
  )

  const [dismissNotificationMutation] = useJamesMutation(
    `dismissNotification(notificationId: #notificationId) {
      success
    }`,
  )

  const registerDeviceTokenForPush = useCallback(
    (token: Token) => {
      setRegisteredToken(token.value)
      registerDeviceTokenForPushMutation({ token: token.value, platform, language })
    },
    [language, registerDeviceTokenForPushMutation, setRegisteredToken],
  )

  const refreshPushActiveOnDevice = useCallback(async () => {
    //Currently there is a bug, if granted and call requestPermissions in android
    // the promise never resolves
    // https://github.com/ionic-team/capacitor-plugins/issues/1628
    if (isAndroidNative()) {
      const currentPermissions = await PushNotifications.checkPermissions()
      if (currentPermissions.receive === 'granted') {
        await PushNotifications.register()
        return true
      }
    }

    const permStatus = await PushNotifications.requestPermissions()

    if (permStatus.receive === 'granted' && !pushActiveOnDevice) {
      setPushActiveOnDevice(true)
      // This will also trigger register callback. We have to rely on this callback in order to get the device token.
      await PushNotifications.register()
      return true
    }

    if (permStatus.receive === 'granted') {
      return true
    }

    setPushActiveOnDevice(false)
    return false
  }, [pushActiveOnDevice, setPushActiveOnDevice])

  const acknowledgeInAppNotification = useCallback(
    async (notification: NotificationT) => {
      if (notification?.id) {
        const res = await dismissNotificationMutation({ notificationId: notification.id })
        if (!res?.dismissNotification.success) {
          setFlashMessage('common.error.be.default')
        }
      }
    },
    [dismissNotificationMutation, setFlashMessage],
  )

  const redirectToCorrectPage = useCallback(
    // eslint-disable-next-line
    (notification: NotificationT) => {
      switch (notification?.type) {
        case NotificationTypes.shift:
          acknowledgeInAppNotification(notification)
          if (notification?.dataType === shiftUpdateTypes.updated) {
            const { taskUuid } = notification.data
            if (taskUuid) {
              const status = taskStates.todo
              pushWithRole(`${routes.shiftUpdateDetail}/${taskUuid}/${status}`, ROLE_VIEWS.frontliner)
            } else {
              pushWithRole(routes.dashboard, ROLE_VIEWS.frontliner)
            }
            return
          }
          if (notification?.dataType === shiftUpdateTypes.inserted) {
            setShiftsContext({ start: notification.data.dateFrom, end: notification.data.dateTo })
            pushWithRole(routes.shifts, ROLE_VIEWS.frontliner)
          }
          break
        case NotificationTypes.shiftUpdateSeen:
          {
            acknowledgeInAppNotification(notification)
            const { taskUuid } = notification.data
            if (taskUuid) {
              const status = taskStates.closed
              pushWithRole(`${routes.shiftUpdateDetail}/${taskUuid}/${status}`, ROLE_VIEWS.office)
            } else {
              defaultRedirectAction()
            }
          }
          break
        case NotificationTypes.invitation:
          {
            const invitationCode = notification.invitationCode
            pushWithRole(`${routes.invite}/${invitationCode}`, ROLE_VIEWS.frontliner)
          }
          break
        case NotificationTypes.document:
          {
            const documentType = notification?.data.documentType
            const taskUuid = notification?.data.taskUuid
            acknowledgeInAppNotification(notification)
            if (documentType === 'monthly_sheet_approval') {
              if (taskUuid) {
                pushWithRole(routes.monthlySheetsWithTask(taskUuid), ROLE_VIEWS.frontliner)
              }
              return
            }

            pushWithRole(routes.documents, ROLE_VIEWS.frontliner)
          }
          break
        case NotificationTypes.reminderShiftUpdate:
        case NotificationTypes.reminderShiftUpdateManual:
          {
            acknowledgeInAppNotification(notification)
            const { taskUuid } = notification.data
            if (taskUuid) {
              const status = taskStates.closed
              pushWithRole(`${routes.shiftUpdateDetail}/${taskUuid}/${status}`, ROLE_VIEWS.frontliner)
            } else {
              defaultRedirectAction()
            }
          }
          break
        case NotificationTypes.shareUserData:
        case NotificationTypes.shareUserDataCommentForFrontliner:
        case NotificationTypes.shareUserDataAccepted:
        case NotificationTypes.reminderShareUserData:
        case NotificationTypes.reminderShareUserDataManual:
          {
            acknowledgeInAppNotification(notification)
            const { taskUuid } = notification.data
            if (taskUuid) {
              pushWithRole(`${routes.userDataDetails}/${taskUuid}`, ROLE_VIEWS.frontliner)
            } else {
              defaultRedirectAction()
            }
          }
          break
        case NotificationTypes.receivedUserData:
        case NotificationTypes.reminderShareUserDataManualForManager:
        case NotificationTypes.shareUserDataCommentForManager:
          {
            acknowledgeInAppNotification(notification)
            const { taskUuid } = notification.data
            if (taskUuid) {
              pushWithRole(`${routes.userDataDetails}/${taskUuid}`, ROLE_VIEWS.office)
            } else {
              defaultRedirectAction()
            }
          }
          break
        case NotificationTypes.reminder:
        case NotificationTypes.monthlySheetCommentForFrontliner:
        case NotificationTypes.reminderMonthlySheetManual:
          {
            acknowledgeInAppNotification(notification)
            const { taskUuid } = notification.data
            if (taskUuid) {
              pushWithRole(routes.monthlySheetsWithTask(taskUuid), ROLE_VIEWS.frontliner)
            } else {
              defaultRedirectAction()
            }
          }
          break
        case NotificationTypes.officeMonthlySheetApproved:
        case NotificationTypes.monthlySheetCommentForManager:
          {
            acknowledgeInAppNotification(notification)
            const { taskUuid } = notification.data
            if (taskUuid) {
              pushWithRole(routes.monthlySheetsWithTask(taskUuid), ROLE_VIEWS.office)
            } else {
              defaultRedirectAction()
            }
          }
          break
        case NotificationTypes.resolvedAbsenceRequest:
        case NotificationTypes.absenceRequestCommentForFrontliner:
        case NotificationTypes.resolvedAbsenceRequestRequestDeletion:
          {
            acknowledgeInAppNotification(notification)
            const { taskUuid } = notification.data
            if (taskUuid) {
              pushWithRole(`${routes.absenceRequestDetail}/${taskUuid}`, ROLE_VIEWS.frontliner)
            } else {
              defaultRedirectAction()
            }
          }
          break
        case NotificationTypes.officeAbsenceRequest:
        case NotificationTypes.absenceRequestCommentForManager:
        case NotificationTypes.reminderAbsenceRequestManualForManager:
        case NotificationTypes.absenceRequestRequestDeletion:
          {
            acknowledgeInAppNotification(notification)
            const { taskUuid } = notification.data
            if (taskUuid) {
              pushWithRole(`${routes.absenceRequestDetail}/${taskUuid}`, ROLE_VIEWS.office)
            } else {
              defaultRedirectAction()
            }
          }
          break
        case NotificationTypes.resignationInfo:
          {
            acknowledgeInAppNotification(notification)
            const { tenantName } = notification.data
            set(resignationTenantNameKey, tenantName)
            pushWithRole(`${routes.tutorial}/${tutorialTypes.resignation}`, ROLE_VIEWS.frontliner)
          }
          break

        case NotificationTypes.cmsNewContent:
        case NotificationTypes.cmsUpdateContent:
          acknowledgeInAppNotification(notification)
          pushWithRole(`${routes.cmsContentView}/${notification.data.contentUuid}`, ROLE_VIEWS.frontliner)
          break

        case NotificationTypes.secureConnection:
        case NotificationTypes.reminderSecureConnection:
        case NotificationTypes.secureConnectionCommentForFrontliner:
        case NotificationTypes.reminderSecureConnectionManualForFrontliner: {
          acknowledgeInAppNotification(notification)
          const { taskUuid } = notification.data
          pushWithRole(routes.secureConnectionWithTask(taskUuid), ROLE_VIEWS.frontliner)
          break
        }
        case NotificationTypes.secureConnectionCommentForManager:
        case NotificationTypes.secureConnectionCompleted: {
          acknowledgeInAppNotification(notification)
          const { taskUuid } = notification.data
          pushWithRole(routes.secureConnectionWithTask(taskUuid), ROLE_VIEWS.office)
          break
        }
        default:
          defaultRedirectAction()
          break
      }
    },
    [acknowledgeInAppNotification, defaultRedirectAction, pushWithRole, set, setShiftsContext],
  )

  const getNotificationDetailAndRedirect = useCallback(
    async (notification: ActionPerformed) => {
      const {
        notification: { data: psnData },
      } = notification
      if (psnData?.notification_id) {
        const query = gql`query ($notificationId: ID){
        notification(notificationId: $notificationId) {
          ${notificationQueryReturnValues}
        }
      }`

        try {
          const res = await client.query<{ notification: NotificationT }>({
            query,
            fetchPolicy: 'no-cache',
            variables: { notificationId: psnData?.notification_id },
          })
          redirectToCorrectPage(res?.data?.notification)
        } catch (error) {
          history.replace('/')
        }
      }
    },
    [client, history, redirectToCorrectPage],
  )

  useEffect(() => {
    if (isNativeNoOverride() && !pushGenericCallbacksRegistered && refresh) {
      // This is the only way how to get the device token
      // So that, we cannot just call registerDeviceTokenForPush directly from refreshPushActiveOnDevice
      PushNotifications.addListener('registration', registerDeviceTokenForPush)
      PushNotifications.addListener('pushNotificationActionPerformed', getNotificationDetailAndRedirect)
      PushNotifications.addListener(
        'pushNotificationActionPerformed',
        PushNotifications.removeAllDeliveredNotifications,
      )
      setPushGenericCallbacksRegistered(true)
    }
  }, [
    getNotificationDetailAndRedirect,
    pushGenericCallbacksRegistered,
    refresh,
    registerDeviceTokenForPush,
    setPushGenericCallbacksRegistered,
  ])

  useEffect(() => {
    if (refresh) {
      refreshPushActiveOnDevice()
    }
  }, [refresh, refreshPushActiveOnDevice])

  return {
    pushActiveOnDevice,
    pushGenericCallbacksRegistered,
    setPushActiveOnDevice,
    setPushGenericCallbacksRegistered,
    refreshPushActiveOnDevice,
  }
}

export default usePSN
