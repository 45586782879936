import { gql } from '@apollo/client'

export const dismissTaskNotificationsMutation = gql`
  mutation dismissByTask($taskUuid: String!, $isManager: Boolean!) {
    dismissByTask(taskUuid: $taskUuid, isManager: $isManager) {
      success
    }
  }
`

export const sendUserDataMutation = gql`
  mutation updateShareUserDataRequest($favurUuid: String!, $status: String!, $userComment: String) {
    updateShareUserDataRequest(favurUuid: $favurUuid, status: $status, userComment: $userComment) {
      success
    }
  }
`
