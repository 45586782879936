import { APPLICATION_ENV } from 'env'
import info from 'info.json'
import { datadogLogs } from '@datadog/browser-logs'

// For sending logs to Datadog, you have to import the datadogLogs and use the logger:
//
// import { datadogLogs } from '@datadog/browser-logs'
// datadogLogs.logger.info('Open settings', { data: 'test', data2: 'test2' })
//
if (APPLICATION_ENV === 'LIVE') {
  datadogLogs.init({
    // A Datadog client token.
    clientToken: 'pub246f6c4bfc0e4ca436120d12040a950c',
    // The Datadog site parameter of your organization.
    site: 'datadoghq.eu',
    // Set to false to stop forwarding console.error logs, uncaught exceptions and network errors to Datadog.
    forwardErrorsToLogs: true,
    // The percentage of sessions to track: 100 for all, 0 for none. Only tracked sessions send logs.
    sessionSampleRate: 100,
    // The application’s environment, for example: prod, pre-prod, staging, and so on.
    env: APPLICATION_ENV.toLowerCase(),
    // The application’s version, for example: 1.2.3, 6c44da20, 2020.02.13, and so on.
    version: info.version,
    // The service name for your application
    service: 'steve-logs',
  })
}

export default datadogLogs
