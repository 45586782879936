import React from 'react'
import { Typography, useTheme } from '@mui/material'
import DotDivider from 'redesign/components/atoms/Divider/DotDivider'
import { MainContainer, Container } from './styles'
import { ContentFooterProps } from './types'

const ContentFooter: React.FC<ContentFooterProps> = ({
  startText,
  secondaryText,
  endText,
  mainTextVariant = 'body2',
  endTextVariant = 'body2',
  primaryColorVariant,
  secondaryColorVariant,
}) => {
  const theme = useTheme()
  const primaryColor = primaryColorVariant ?? theme.palette.text.primary

  return (
    <MainContainer>
      <Container>
        {startText && (
          <Typography color={primaryColor} variant={mainTextVariant}>
            {startText}
          </Typography>
        )}
        {secondaryText && (
          <Container>
            <DotDivider />
            <Typography color={primaryColor} variant={mainTextVariant}>
              {secondaryText}
            </Typography>
          </Container>
        )}
      </Container>
      {endText && (
        <Typography color={secondaryColorVariant ?? theme.palette.text.secondary} variant={endTextVariant}>
          {endText}
        </Typography>
      )}
    </MainContainer>
  )
}

export default ContentFooter
