import { Typography, styled } from '@mui/material'

export const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  min-height: ${({ theme }) => theme.spacing(4)};
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`

export const ContentContainer = styled('div')`
  display: flex;
  gap: ${({ theme }) => theme.spacing(1)};
  align-items: center;
`

export const HeaderText = styled(Typography)`
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 2;
  -webkit-line-clamp: 2;
`
