import { PersonT, TenantT, OwnerT, TeamT } from 'types'
import { gql } from '@apollo/client'

const teamPermissionFields = `
  absenceManager
  monthlySheetManager
  secureConnectionManager
  shareUserDataManager
  shiftUpdateManager
`

export const teamFields = `
  id
  nameDe
  nameEn
  nameFr
  nameIt
  public
  showTeamplanCostCenters
`

export const defaultApiMeFields = `
  auth
  cmsActivated
  personIds
  personModules
  personPackages
`

export const permissions = `
permissions{
  officeView
  permissionsChangedAt
  workflowAbsences
  workflowMonthlySheets
  workflowShareUserData
  workflowShiftUpdate
  workflowSecureConnection
}
`
const personPermissionsContent = `
id
status
ownerId
tenantId
workWithdrawal
${permissions}
roles {
  role
  tenantId
}
`

export const personPermissionQuery = gql`
query UserPersonsAndPermissions {
  userPersonsAndPermissions {
    ${personPermissionsContent}
  }
}
`

export const personsWithTenantAndPermissions = gql`
query personsWithTenantAndPermissions {
    findAllPersons {
      id
      favurUuid
      status
      tenantConnectionStatus
      tenant {
        id
        name
        absencesBlockingPeriod
      }
      ${permissions}
    }
  }
`

export const personsOnlyTenant = gql`
  query personsOnlyTenants {
    findAllPersons {
      id
      tenantConnectionStatus
      workWithdrawal
      tenant {
        uuid
        name
        id
        package
      }
      owner {
        id
      }
    }
  }
`

export const fullPersonsQuery = gql`
  query fullPersonsQuery {
    findAllPersons {
      id
      firstName
      lastName
      favurUuid
      status
      lastSharedData
      workEntry
      workWithdrawal
      tenantConnectionStatus
      tenantConnectionEstablished
      roles {
        role
        tenant {
          name
          uuid
        }
      }
      teamPermissions {
        teamId
      }
      tenant {
        id
        name
        links {
          id
          url
          labelEn
          labelDe
          labelIt
          labelFr
        }
      }
      owner {
        id
        name
      }
      user {
        id
        firstName
        lastName
        avatarUrl
      }
    }
  }
`

export const authQuery = `
  ${defaultApiMeFields}
  user {
    phoneNumber
    firstName
    lastName
  }
`

export const userNameQuery = gql`
  query userName {
    me {
      user {
        phoneNumber
        userId
        firstName
        lastName
        avatarUrl
      }
    }
  }
`

export const balanceQueryParams = `
  tenantName
  withErrors
  bankHoliday {
    unit
    value
  }
  free {
    unit
    value
  }
  hours {
    unit
    value
  }
  nightHours {
    unit
    value
  }
  overtime {
    unit
    value
  }
  vacation {
    unit
    value
  }
`

export const estimationsQueryParams = `
  estimationDate
  estimations {
    vacation  {
      unit
      value
    }
    bankHoliday {
      unit
      value
    }
  }
`

export const taskCountsQuery = gql`
  query taskCounts($office: Boolean!, $showSecondView: Boolean) {
    taskCounts(office: $office, showSecondView: $showSecondView) {
      pending
      todo
      closed
      secondView
    }
  }
`
export const onlyShiftFields = `id
personId
costCenterName
costCenterUuid
description
date
percent
shortDescription
type
timeOfDay
updated
from
until
timeType
validated
note
hexBackgroundColor
hexTextColor
isManual
updateTaskId
shiftAbs`

export const shiftFields = `shifts {
  ${onlyShiftFields}
}`

export const teamplanQueryReturnValues = `
  date
  tenants {
    id
    name
    costCenters {
      name
      persons {
        firstName
        lastName
        isUser
        ${shiftFields}
      }
    }
  }
`

export const lightTeamplanQueryReturnValues = `
  date
  tenants {
    name
    costCenters {
      name
    }
  }
`

export const lowSecurityTenantQuery = gql`
  query lowSecurityTenantNames {
    lowSecurityTenantNames {
      tenantName
      personId
    }
  }
`

const notificationDocumentQueryFields = `
    ... on DocumentNotification {
      dateFrom
      dateTo
      documentId
      documentType
      taskUuid
    }
`

const notificationInvitationQueryFields = `
    ... on InvitationNotification {
      code
      tenantName
    }
`

const notificationLinkMessageQueryFields = `
    ... on LinkMessageNotification {
      bodyKey
      linkButtonKey
      linkKey
      titleKey
    }
`

const notificationOfficeAbsenceRequestQueryFields = `
    ... on OfficeAbsenceRequestNotification {
      comment
      dateFrom
      dateTo
      period
      taskUuid
      userName
    }
`

const notificationOfficeMonthlySheetApprovedQueryFields = `
    ... on OfficeMonthlySheetApprovedNotification {
      dateFrom
      dateTo
      documentId
      personId
      taskUuid
      userName
    }
`

const notificationReceivedUserDataQueryFields = `
    ... on ReceivedUserDataNotification {
      taskUuid
      tenantName
      userName
    }
`

const notificationReminderQueryFields = `
    ... on ReminderNotification {
      dateFrom
      dateTo
      documentId
      reminderDeadline
      reminderType
      taskUuid
    }
`
const notificationReminderShiftUpdateQueryFields = `
    ... on ReminderShiftUpdateNotification {
      taskUuid
    }
`

const notificationReminderShiftUpdateManualQueryFields = `
    ... on ReminderShiftUpdateManualNotification {
      taskUuid
      managerNameShort
    }
`

const notificationReminderShareUserDataQueryFields = `
... on ReminderShareUserDataNotification {
  taskUuid
}
`

const notificationReminderShareUserDataManualQueryFields = `
    ... on ReminderShareUserDataManualNotification {
      taskUuid
      managerNameShort
    }
`

const notificationReminderShareUserDataManualForManagerQueryFields = `
    ... on ReminderShareUserDataManualNotificationForManager {
      taskUuid
      frontlinerNameShort
    }
`

const notificationMonthlySheetCommentForManagerQueryFields = `
    ... on MonthlySheetCommentForManagerNotification {
      taskUuid
      frontlinerNameShort
      commentShort
      badge
    }
`

const notificationMonthlySheetCommentForFrontlinerQueryFields = `
    ... on MonthlySheetCommentForFrontlinerNotification {
      taskUuid
      managerNameShort
      commentShort
      badge
    }
`

const notificationAbsenceRequestCommentForManagerQueryFields = `
    ... on AbsenceRequestCommentForManagerNotification {
      taskUuid
      frontlinerNameShort
      commentShort
      badge
    }
`

const notificationAbsenceRequestCommentForFrontlinerQueryFields = `
    ... on AbsenceRequestCommentForFrontlinerNotification {
      taskUuid
      managerNameShort
      commentShort
      badge
    }
`

const notificationReminderMonthlySheetsManualQueryFields = `
    ... on ReminderMonthlySheetManualNotification {
      taskUuid
      managerNameShort
      dateFrom
      dateTo
      documentId
    }
`

const notificationReminderMonthlySheetsManualForManagerQueryFields = `
  ... on ReminderMonthlySheetManualForManagerNotification {
    taskUuid
    frontlinerNameShort
    documentId
  }
`

const notificationReminderAbsenceRequestManualForManagerQueryFields = `
  ... on ReminderAbsenceRequestManualForManagerNotification {
    taskUuid
    frontlinerNameShort
  }
`

const notificationResolvedAbsenceRequestQueryFields = `
    ... on ResolvedAbsenceRequestNotification {
      comment
      dateFrom
      dateTo
      period
      state
      taskUuid
      managerName
    }
`

const notificationShareUserDataQueryFields = `
    ... on ShareUserDataNotification {
      taskUuid
      tenantName
    }
`

const notificationShareUserDataCommentForFrontlinerQueryFields = `
    ... on ShareUserDataCommentForFrontlinerNotification {
      taskUuid
      managerNameShort
      commentShort
      badge
    }
`

const notificationShareUserDataCommentForManagerQueryFields = `
    ... on ShareUserDataCommentForManagerNotification {
      taskUuid
      frontlinerNameShort
      commentShort
      badge
    }
`

const notificationShareUserDataAcceptedQueryFields = `
    ... on ShareUserDataAcceptedNotification {
      taskUuid
      tenantName
    }
`

const notificationShiftQueryFields = `
    ... on ShiftNotification {
      count
      dateFrom
      dates
      dateTo
      taskUuid
    }
`

const notificationShiftUpdateSeenQueryFields = `
    ... on ShiftUpdateSeenNotification {
      count
      dateFrom
      dateTo
      taskUuid
      userName
      userNameShort
    }
`

const notificationAbsenceRequestRequestDeletionQueryFields = `
    ... on AbsenceRequestRequestDeletionNotification {
      dateFrom
      dateTo
      taskUuid
      frontlinerNameShort
      period
    }
`

const notificationResolvedAbsenceRequestRequestDeletionQueryFields = `
    ... on ResolvedAbsenceRequestRequestDeletionNotification {
      dateFrom
      dateTo
      taskUuid
      managerNameShort
      period
      state
    }
`

const notificationResignationInfoQueryFields = `
    ... on ResignationInfoNotification {
      tenantName
      resignationDate
    }
`

const notificationCmsContentFields = `
    ... on CmsContentNotification {
      contentUuid
      type
      title
      startDate
      endDate
      isWholeDay
    }
`

const notificationSecureConnectionContentFields = `
    ... on SecureConnectionNotification {
      taskUuid
      tenantName
    }
`

const notificationReminderSecureConnectionContentFields = `
    ... on ReminderSecureConnectionNotification {
      taskUuid
    }
`

const notificationSecureConnectionCommentForManagerQueryFields = `
    ... on SecureConnectionCommentForManagerNotification {
      taskUuid
      frontlinerNameShort
      commentShort
      badge
    }
`

const notificationSecureConnectionCommentForFrontlinerQueryFields = `
    ... on SecureConnectionCommentForFrontlinerNotification {
      taskUuid
      managerNameShort
      commentShort
      badge
    }
`

const notificationReminderSecureConnectionManualForFrontlinerQueryFields = `
    ... on ReminderSecureConnectionManualForFrontlinerNotification {
      taskUuid
      managerNameShort
    }
`

const notificationSecureConnectionCompletedQueryFields = `
    ... on SecureConnectionCompletedNotification {
      taskUuid
      frontlinerNameShort
      tenantName
    }
`

const createNotificationReturnValues = (notificationTypes: string[]) => `
  data {
    ${notificationTypes.join('')}
  }
  id
  personId
  type
  status
  updatedAt
  invitationCode
  dataType
`

export const notificationQueryReturnValues = createNotificationReturnValues([
  notificationDocumentQueryFields,
  notificationInvitationQueryFields,
  notificationLinkMessageQueryFields,
  notificationOfficeAbsenceRequestQueryFields,
  notificationOfficeMonthlySheetApprovedQueryFields,
  notificationReceivedUserDataQueryFields,
  notificationReminderQueryFields,
  notificationReminderShiftUpdateQueryFields,
  notificationReminderShiftUpdateManualQueryFields,
  notificationReminderMonthlySheetsManualQueryFields,
  notificationReminderMonthlySheetsManualForManagerQueryFields,
  notificationReminderAbsenceRequestManualForManagerQueryFields,
  notificationReminderShareUserDataQueryFields,
  notificationReminderShareUserDataManualQueryFields,
  notificationReminderShareUserDataManualForManagerQueryFields,
  notificationResolvedAbsenceRequestQueryFields,
  notificationShareUserDataQueryFields,
  notificationShareUserDataCommentForFrontlinerQueryFields,
  notificationShareUserDataCommentForManagerQueryFields,
  notificationShareUserDataAcceptedQueryFields,
  notificationShiftQueryFields,
  notificationShiftUpdateSeenQueryFields,
  notificationMonthlySheetCommentForManagerQueryFields,
  notificationMonthlySheetCommentForFrontlinerQueryFields,
  notificationAbsenceRequestCommentForManagerQueryFields,
  notificationAbsenceRequestCommentForFrontlinerQueryFields,
  notificationAbsenceRequestRequestDeletionQueryFields,
  notificationResolvedAbsenceRequestRequestDeletionQueryFields,
  notificationResignationInfoQueryFields,
  notificationCmsContentFields,
  notificationSecureConnectionContentFields,
  notificationReminderSecureConnectionContentFields,
  notificationSecureConnectionCommentForManagerQueryFields,
  notificationSecureConnectionCommentForFrontlinerQueryFields,
  notificationReminderSecureConnectionManualForFrontlinerQueryFields,
  notificationSecureConnectionCompletedQueryFields,
])

export const findAllPersonsWithOwnerAndPermissionsQueryName = 'listPersonsWithOwnerAndPermissions'
export const findAllPersonsWithOwnerAndPermissionsQuery = gql`
  query listPersonsWithOwnerAndPermissions {
    findAllPersons {
      id
      ownerId
      status
      owner {
        id
        name
      }
      roles {
        role
        tenantId
      }
    }
  }
`
export const getPersonQuery = gql`
  query getPerson($uuid: String!) {
    getPerson(favurUuid: $uuid) {
      id
      favurUuid
      firstName
      lastName
      teamPermissions {
        ${teamPermissionFields}
        teamId
        team {
          ${teamFields}
        }
      }
      tenantConnectionEstablished
      tenantConnectionStatus
      tenant {
        id
        name
      }
      owner{
        id
      }
      updatedAt
      user {
        firstName
        lastName
      }
    }
  }
`

export const getActiveConnections = gql`
  query getActiveConnections {
    connections {
      persons {
        id
        favurUuid
        tenant {
          name
        }
        teamPermissions {
          teamId
        }
      }
    }
  }
`

export const detailConnectionsToDelete = gql`
  query detailConnectionsToDelete {
    connections {
      persons {
        id
        favurUuid
        numberOfDocuments
        tenantConnectionEstablished
        status
        deleteAfter
        tenant {
          name
        }
      }
    }
  }
`

export const teamWithDepsFields = `${teamFields}
  tenantId
  tenant {
    id
    name
  }
  owner {
    id
    name
  }`

export const personFields = `
  person {
    id
    firstName
    lastName
    tenant {
      id
      name
    }
    user {
      id
      avatarUrl
      firstName
      lastName
    }
  }
  `

export const teamUsersFields = `
  teamPermissions {
    ${teamPermissionFields}
    ${personFields}
  }`

export const teamUserFieldsWithOption = `
  teamPermissions(removeSupervisors: $removeSupervisors, personIds: $personIds) {
    ${teamPermissionFields}
    ${personFields}
  }
`

export type GetTeamsQueryReturnT = {
  member?: TeamT[]
  public?: TeamT[]
}

export const getTeamsQueryWithMembers = gql`
  query getTeamsQuery($isManager: Boolean, $memberPermissions: [String], $removeSupervisors: Boolean, $personIds: [ID]) {
    getTeams {
      member(isManager: $isManager, memberPermissions: $memberPermissions) {
        ${teamWithDepsFields}
        ${teamUserFieldsWithOption}
      }
      public {
        ${teamWithDepsFields}
        ${teamUserFieldsWithOption}
      }
    }
  }
`
export const getTeamsQuery = gql`
query getTeamsQuery($isManager: Boolean, $memberPermissions: [String]) {
  getTeams {
    member(isManager: $isManager, memberPermissions: $memberPermissions) {
      ${teamWithDepsFields}
    }
    public {
      ${teamWithDepsFields}
    }
  }
}
`

export const getPersonTeamWithMembersQuery = gql`
  query getPersonTeamWithMembers($uuid: String!) {
    getPerson(favurUuid: $uuid) {
      id
      favurUuid
      teamPermissions {
        ${teamPermissionFields}
        teamId
        team {
          ${teamFields}
          teamPermissions {
            person {
              id
              firstName
              lastName
              favurUuid
              user {
                firstName
                lastName
                avatarUrl
              }
            }
          }
        }
      }
      tenant {
        name
      }
    }
  }
`

export type TeamPermissionsT = {
  absenceManager: boolean
  monthlySheetManager: boolean
  shareUserDataManager: boolean
  team: TeamT
}

export type PersonTeamPermissionsQueryReturnT = Pick<PersonT, 'id'> &
  {
    id: number
    favurUuid: string
    tenant: TenantT
    owner: OwnerT
    teamPermissions: TeamPermissionsT[]
  }[]

export const personTeamPermissionsQuery = gql`
  query personTeamPermissions {
    findAllPersons {
      id
      favurUuid
      tenant {
        id
        name
      }
      teamPermissions {
        ${teamPermissionFields}
        team {
          ${teamWithDepsFields}
        }
      }
      owner {
        name
      }
    }
  }
`

export const getPublicTeamsQuery = gql`
  query {
    getPublicTeams {
      ${teamWithDepsFields}
    }
  }
`

export const removeHighSecuritySessionMutation = gql`
  mutation {
    removeSecuritySession {
      success
    }
  }
`

export const personDayShiftsQuery = gql`
  query getPersonDayShiftsQuery($date: String!, $personId: ID!, $types: [String]) {
    getPersonDayShifts(date: $date, personId: $personId, types: $types) {
      date
      tenants {
        tenantName
        tenantId
        costCenterUuid
        costCenterName
        ${shiftFields}
      }
    }
  }
`

export const getPersonTeamsUsersQuery = gql`
  query personTeamPermissions {
    findAllPersons {
      id
      favurUuid
      teamPermissions {
        ${teamPermissionFields}
        team {
          ${teamWithDepsFields}
          ${teamUsersFields}
        }
      }
    }
  }
`

export const getPublicTeamsUsersQuery = gql`
  query {
    getPublicTeams {
      ${teamWithDepsFields}
      ${teamUsersFields}
    }
  }
`

export type ManagedTaskCountsReturnT = {
  closed: number
  pending: number
  todo: number
  personId: string
}[]

export const getManagedTaskCountsQuery = gql`
  query getManagedTaskCountsQuery($taskTypes: [String], $teamIds: [String]) {
    getManagedTaskCounts(taskTypes: $taskTypes, teamIds: $teamIds) {
      personId
      todo
      pending
      closed
    }
  }
`

export const userSettingsQuery = gql`
  query userSettingsQuery {
    me {
      user {
        settings {
          pushNotifications {
            absenceRequest
            documents
            frontlinerAbsenceRequest
            invitations
            monthlySheetReminder
            newShifts
            officeMonthlySheetApproved
            receivedUserData
            shareUserData
            shiftsUpdate
            shiftUpdateSeen
            cmsPage
            cmsEvent
            cmsNewsletter
            secureConnectionFrontliner
            secureConnectionManager
          }
          inAppNotifications {
            absenceRequest
            officeMonthlySheetApproved
            receivedUserData
            shiftUpdateSeen
          }
          seenTutorials
          dashboardEmployeeCards
        }
      }
    }
  }
`
export const updateUserSettingsMutation = gql`
  mutation($settings: UserSettings!) {
    updateUserSettings(settings: $settings) {
      success
    }
  }
`

export const getAbsenceRequestConflictsQuery = gql`
  query getAbsenceRequestConflicts($startDate: String!, $endDate: String!, $isManager: Boolean, $teamIds: [ID]) {
    getAbsenceRequestConflicts(startDate: $startDate, endDate: $endDate, isManager: $isManager, teamIds: $teamIds) {
      vacationsCount
      vacationsPersons {
        id
        firstName
        lastName
        user {
          firstName
          lastName
          avatarUrl
        }
      }
      otherLeavesCount
      otherLeavesPersons {
        id
      }
      pendingRequestsCount
      pendingRequestsPersons {
        id
      }
    }
  }
`

export const changeUserLanguageMutation = gql`
  mutation changeUserLanguage($newLanguage: String!) {
    changeUserLanguage(newLanguage: $newLanguage) {
      success
    }
  }
`

export const getLastUpdateDateQuery = gql`
  query getLastUpdatedDate {
    getLastUpdatedDate {
      lastUpdatedDate
    }
  }
`

export const employersConnectionsQuery = gql`
  query employersConnections {
    connections {
      invitations {
        code
        status
        tenantName
        updatedAt
      }
      persons {
        id
        favurUuid
        lastSharedData
        status
        tenantConnectionEstablished
        tenantConnectionStatus
        workWithdrawal
        workEntry
        tenant {
          name
        }
        updatedAt
        teamPermissions {
          teamId
        }
        user {
          userDataCollectionLastUpdate
        }
      }
    }
  }
`

export const getManagedTenantsQuery = gql`
  query getManagedTenants {
    getManagedTenants {
      id
      uuid
      name
      hideUnverifiedEffectiveTimes
      absencesBlockingPeriod
      reminders {
        type
        first
        second
        third
      }
    }
  }
`

export const listPaginatedcontents = gql`
  query cmsPaginatedContentList(
    $contentType: String
    $page: Int
    $pageSize: Int
    $eventTimeline: EventTimelineType
    $date: String
    $startDate: String
    $endDate: String
    $isAbsenceRestricted: Boolean
    $showAdmin: Boolean
    $ownerId: Int
  ) {
    cmsPaginatedContentList(
      contentType: $contentType
      page: $page
      pageSize: $pageSize
      eventTimeline: $eventTimeline
      date: $date
      startDate: $startDate
      endDate: $endDate
      isAbsenceRestricted: $isAbsenceRestricted
      showAdmin: $showAdmin
      ownerId: $ownerId
    ) {
      pageNumber
      pageSize
      totalPages
      totalEntries
      entries {
        audiences {
          uuid
          audienceType
          ownerId
          tenantId
          teamId
        }
        uuid
        startDate
        sentAt
        endDate
        isWholeDay
        metadatas {
          title
          shortDescription
        }
        isAbsenceRestricted
      }
    }
  }
`
export const cmsBlockFields = `
blocks {
  order
  type
  data {
    ... on DocumentBlockData {
      filename
      filesize
    }
    ... on ImageBlockData {
      altText
      filename
    }
    ... on TextBlockData {
      html
    }
    ... on IconBlockData {
      variant
    }
    ... on LinkBlockData {
      variant
      text
      route
    }
    ... on SubcontentBlockData {
      title
      previewText
      previewImageFilename
      subcontentUuid
    }
  }
  language
  blockCss
}
`

export const userAnnouncementsQuery = gql`
  query {
    userAnnouncements {
      announcementId
      id
      announcement {
        id
        route
        ${cmsBlockFields}
      }
    }
  }
`

export const markAnncouncementSeen = gql`
  mutation markUserAnnouncementSeen($userAnnouncementId: ID!) {
    markUserAnnouncementSeen(userAnnouncementId: $userAnnouncementId) {
      success
    }
  }
`

export const invitationQuery = gql`
  query invitation($code: String!) {
    invitation(code: $code) {
      code
      status
      updatedAt
      phoneNumber
      userId
    }
  }
`

export const pinStatusQuery = gql`
  query pinStatus {
    pinStatus {
      pinStatus
      pinLength
    }
  }
`

export const sessionValidityQuery = gql`
  query sessionValidity {
    sessionValidity {
      highSecSessionValidUntil
      highSecSessionValidNow
      securitySetupValidUntil
      securitySetupValidNow
      pinResetValidUntil
      pinResetValidNow
      timestamp
    }
  }
`
