import React, { useState, useEffect, useCallback } from 'react'
import { Box, Button, IconButton, Typography } from '@mui/material'
import { CancelTokenSource } from 'axios'
import Grid from 'components/Grid'
import Nbsp from 'components/Nbsp'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useJamesMutation from 'hooks/useJamesMutation'
import { CloseS, PlusS } from 'icons'
import { UseFormReturn, Path, FieldValues, PathValue, UnpackNestedValue } from 'react-hook-form'
import { USER_CANCEL } from 'services/AxiosInterceptor/useRejectGraphql'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import theme from 'utils/theme'
import { PersonalDocumentFileTypeT } from '../../PersonalData/types'
import { deleteFavurDocument } from './mutations'
import { fileSelectClasses } from './styles'
import { FileFieldT } from './types'
import useUploadFile from './useUploadFile'

export type FileSelectT<T extends FieldValues> = {
  filetype: PersonalDocumentFileTypeT
  form: UseFormReturn<T>
  name: string
  translationKey: string
}

const FileSelect = <T extends FieldValues>({ form, name, filetype, translationKey }: FileSelectT<T>) => {
  const { t } = useFavurTranslation()
  const [uploadProgress, setUploadProgress] = useState(0)
  const [axiosSource, setAxiosSource] = useState<CancelTokenSource>()
  const [deleteDocumentMutation] = useJamesMutation(deleteFavurDocument)
  const uploadFile = useUploadFile()
  const { register, setValue, watch } = form
  // eslint-disable-next-line fp/no-mutating-methods
  const file = watch(name as Path<T>) as FileFieldT
  const selectedFile = file?.filename
  const cancelUploadOrDelete = () => {
    if (selectedFile && uploadProgress < 100) {
      if (axiosSource) {
        axiosSource.cancel(USER_CANCEL)
      }
      form.setValue(`${name}.filename` as Path<T>, null as UnpackNestedValue<PathValue<T, Path<T>>>)
    }
    if (selectedFile && uploadProgress === 100) {
      deleteDocumentMutation({ filename: `${filetype}/${selectedFile}` }).then((data) => {
        if (data?.deleteFavurDocument?.success) {
          form.setValue(`${name}.filename` as Path<T>, null as UnpackNestedValue<PathValue<T, Path<T>>>)
        }
        return null
      })
    }
    return null
  }
  const fileSelectCallback = useCallback(
    async (fileToUpload: File) => {
      const uploadFilename = fileToUpload.name
      // @ts-ignore
      setValue(`${name}.filename`, uploadFilename)
      const { source, filename } = await uploadFile(filetype, fileToUpload, uploadFilename, setUploadProgress)
      // @ts-ignore
      setValue(`${name}.filename`, filename)
      form.trigger(`${name}.filename` as Path<T>)
      setAxiosSource(source)
    },
    [filetype, form, name, setValue, uploadFile],
  )

  useEffect(() => {
    if (file?.isExistingFile) {
      setUploadProgress(100)
    }
  }, [file, setUploadProgress])

  // @ts-ignore
  const blueColor = theme.palette.primary[900]
  return (
    <Grid sx={fileSelectClasses.listItemGrid} col={4}>
      {selectedFile ? (
        <Box sx={fileSelectClasses.selected(uploadProgress)}>
          <Box sx={fileSelectClasses.labelContainer}>
            <Typography
              sx={fileSelectClasses.labelTypographySelected}
              variant="button"
              data-testid={`uploaded-file-${name}`}
            >
              {selectedFile}
              <Typography
                sx={[fileSelectClasses.labelTypographySelected, fileSelectClasses.labelDate]}
                variant="caption"
              >
                <Nbsp />-<Nbsp />
                {getFormattedFromDate(newDateWithoutTime(file?.createdAt), uiDateFormat)}
              </Typography>
            </Typography>
            <IconButton
              onClick={cancelUploadOrDelete}
              sx={fileSelectClasses.iconButton}
              data-testid={`delete-${name}`}
              size="large"
            >
              <CloseS fill={blueColor} />
            </IconButton>
          </Box>
        </Box>
      ) : (
        <Button variant="contained" component="label" sx={fileSelectClasses.button} data-testid={`upload-${name}`}>
          <Box sx={fileSelectClasses.empty}>
            <Box sx={fileSelectClasses.labelContainer}>
              <Typography sx={fileSelectClasses.labelTypography} variant="button">
                <PlusS />
                <Nbsp />
                {t(translationKey)}
              </Typography>
            </Box>
          </Box>
          <input
            data-testid={`input-filename-${name}`}
            hidden
            multiple={false}
            type="file"
            accept=".pdf,.jpg,.jpeg,.png"
            {...register(`${name}.file` as Path<T>)}
            onChange={async (e) => {
              const { files } = e.target
              const fileToUpload = files?.item(0)
              if (fileToUpload) {
                fileSelectCallback(fileToUpload)
              }
            }}
          />
          <input hidden defaultValue="" {...register(`${name}.filename` as Path<T>)} />
          <input hidden defaultValue="" {...register(`${name}.isExistingFile` as Path<T>)} />
          <input hidden defaultValue="" {...register(`${name}.createdAt` as Path<T>)} />
        </Button>
      )}
    </Grid>
  )
}

export default FileSelect
