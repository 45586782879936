import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { ReminderSecureConnectionNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getSecureConnectionReminderData = ({ notification, t }: GetNotificationData) => {
  const { taskUuid } = notification.data as ReminderSecureConnectionNotification
  const { title, body } = notificationGlosssaryKeys.reminderSecureConnection

  return {
    title: t(title),
    body: t(body),
    route: routes.secureConnectionWithTask(taskUuid ?? ''),
    role: ROLE_VIEWS.frontliner,
  }
}
