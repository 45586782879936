import React, { useCallback } from 'react'
import { Box } from '@mui/material'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { RoleViewT } from 'types'
import SectionHeader from '../SectionHeader'
import DashboardTasksFrontliner from './Frontliner'
import DashboardTasksManager from './Manager'
import { classes } from './styles'

const DashboardTasks: React.FC = () => {
  const { t } = useFavurTranslation()
  const { hasOfficeView: isManager, setActiveView } = useRolesViews()
  const history = useHistory()

  const onClick = useCallback(
    (role: RoleViewT) => {
      setActiveView(role)
      history.push(`${routes.tasks}/todo`)
    },
    [history, setActiveView],
  )

  return (
    <>
      <SectionHeader title={t('page.tasks.header.openTasks')} testId="show-all-tasks" />
      <Box sx={classes.tilesContainer}>
        {isManager ? <DashboardTasksManager onClick={onClick} /> : <DashboardTasksFrontliner onClick={onClick} />}
      </Box>
    </>
  )
}

export default DashboardTasks
