import { gql } from '@apollo/client'

export const familyDataQuery = gql`
  query getFamilyData {
    familyData {
      civilStatus
      civilStatusSince
      partner {
        firstName
        lastName
        gender
        birthDate
        socialInsuranceNumber
      }
      children {
        firstName
        lastName
        gender
        birthDate
        socialInsuranceNumber
      }
    }
  }
`
