import React, { useMemo, useState } from 'react'
import { SubheaderTabContextProviderProps } from '../../components/organisms/Headers/NotificationsHeader/types'
import TabHeaderContext from './context'

const SubheaderTabProvider: React.FC<SubheaderTabContextProviderProps> = ({ children, pages }) => {
  const [selectedTabName, setSelectedTabName] = useState<string>(pages[0]?.name ?? '')

  const providerValue = useMemo(
    () => ({
      setSelectedTabName,
      selectedTabName,
      pages,
    }),
    [pages, selectedTabName],
  )

  return <TabHeaderContext.Provider value={providerValue}>{children}</TabHeaderContext.Provider>
}

export default SubheaderTabProvider
