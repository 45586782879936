import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { OfficeMonthlySheetApprovedNotification } from 'shared/graphql/graphql'
import { uiDateFormat } from 'utils/constants'
import { getFormattedFromISOString } from 'utils/date'
import { getShortNameFromName } from 'utils/person'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getOfficeMonthlySheetApprovedData = ({ notification, t }: GetNotificationData) => {
  const {
    userNameShort,
    userName,
    taskUuid,
    dateFrom,
    dateTo,
  } = notification.data as OfficeMonthlySheetApprovedNotification
  const { title, body } = notificationGlosssaryKeys.officeMonthlySheetApproved

  return {
    title: t(title, {
      frontlinerNameShort: userNameShort ?? getShortNameFromName(userName ?? ''),
    }),
    body: t(body, {
      dateFrom: getFormattedFromISOString(dateFrom ?? '', uiDateFormat, undefined),
      dateTo: getFormattedFromISOString(dateTo ?? '', uiDateFormat, undefined),
    }),
    route: `${routes.monthlySheets}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
