import { INITIAL_TASKS_STATUS, TASKS_STATUS_TRANSLATIONS } from './constants'
import { GetContentStatus } from './utils/types'

export const getContentStatus = ({ status, taskType }: GetContentStatus) => {
  if (!status) return ''

  const translations = {
    ...TASKS_STATUS_TRANSLATIONS,
    [INITIAL_TASKS_STATUS[taskType]]: 'task.detailState.taskInitiated',
  }

  return translations[status]
}
