import { TASK_TYPES } from '../../constants'
import { getContentStatus } from '../../getContentStatus'
import { getDateFormatFromTask } from '../Dates/getDateFormatFromTask'
import { getDateRangeFromTask } from '../Dates/getDateRangeFromTask'
import { EMPTY_CONTENT_DATA } from '../constants'
import type { GetTaskListData } from '../types'
import { getTaskState } from './getTaskState'
import { isAbsenceRequestTaskData } from './taskDataTypeGuard'

export const getAbsenceRequestData = ({ task, locale, t }: GetTaskListData) => {
  if (!task?.taskData || !isAbsenceRequestTaskData(task.taskData)) return EMPTY_CONTENT_DATA

  const { taskData, statusFe, updatedAt, status } = task

  return {
    bodyText: getDateRangeFromTask({ dates: taskData, locale, t }),
    startText: t(getTaskState({ statusFe })),
    secondaryText: t(getContentStatus({ status, taskType: TASK_TYPES.absenceRequest })),
    endText: getDateFormatFromTask({ date: updatedAt, locale, t }),
  }
}
