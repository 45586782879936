import React from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import { SimpleLoading } from 'components/LoadingIcon'
import { ROLE_VIEWS } from 'constants/roles'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useTaskCounts from 'hooks/useTaskCounts'
import { TaskCenterL } from 'icons'
import { RoleViewT } from 'types'
import { classes } from './styles'

interface IDashboardTasksFrontliner {
  onClick: (role: RoleViewT) => void
}

const DashboardTasksFrontliner: React.FC<IDashboardTasksFrontliner> = ({ onClick }) => {
  const { t } = useFavurTranslation()
  const { taskCounts, loading } = useTaskCounts({ showSecondView: false })

  return (
    <Box
      sx={[classes.taskBox]}
      onClick={() => {
        onClick(ROLE_VIEWS.frontliner)
      }}
    >
      <Card>
        {loading || !taskCounts ? (
          <SimpleLoading small smallContainer />
        ) : (
          <Box sx={classes.horizontalContainer}>
            <Box sx={classes.flexContainer}>
              <TaskCenterL />
              <Typography variant="h3">{taskCounts.todo}</Typography>
              <Typography variant="body2">{t('page.dashboard.tasks.frontliner')}</Typography>
            </Box>
          </Box>
        )}
      </Card>
    </Box>
  )
}

export default DashboardTasksFrontliner
