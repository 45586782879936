import { useCallback, useMemo, useRef } from 'react'
// eslint-disable-next-line lodash-fp/use-fp
import { throttle } from 'lodash'
import { gql } from '@apollo/client'
import { useJamesApolloLazyQuery } from './useJamesApolloLazyQuery'

const query = gql`
  query refreshHighSecuritySession {
    refreshHighSecuritySession {
      highSecSessionValidUntil
    }
  }
`

const THROTTLING_DELAY = 30_000

const useRefreshHighSecSession = () => {
  const [refresh] = useJamesApolloLazyQuery<{ refreshHighSecuritySession: { highSecSessionValidUntil: string } }>(query)

  const refreshHighSecSession = useCallback(async () => {
    const result = await refresh({ fetchPolicy: 'no-cache' })
    return result?.data?.refreshHighSecuritySession.highSecSessionValidUntil
  }, [refresh])

  const throttledRefreshHighSecSessionFnRef = useRef(
    throttle(() => {
      refreshHighSecSession()
    }, THROTTLING_DELAY),
  ).current

  const throttledRefreshHighSecSession = useCallback(() => {
    return throttledRefreshHighSecSessionFnRef
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const refreshHighSecTriggers = useMemo(
    () => ({
      onMouseOver: throttledRefreshHighSecSession(),
      onTouchStart: throttledRefreshHighSecSession(),
      onKeyDown: throttledRefreshHighSecSession(),
    }),
    [throttledRefreshHighSecSession],
  )

  return { refreshHighSecSession, throttledRefreshHighSecSession, refreshHighSecTriggers }
}

export default useRefreshHighSecSession
