import React from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import ContentWrapper from 'redesign/components/atoms/ContentWrapper'
import FramedIcon from 'redesign/components/atoms/FramedIcon'
import { PlaceholderIcon } from 'redesign/components/atoms/Icon'
import ContentCard from 'redesign/components/organisms/ContentCard'
import ListWrapper from 'redesign/components/organisms/ListWrapper'
import { TaskContainer } from './styles'
import { TaskListProps } from './types'
import { normalizeTaskContents, normalizeTaskHeader } from './utils'
import { getTaskUrl } from './utils/getTaskUrl'

const TaskList: React.FC<TaskListProps> = ({ tasks, isOffice, isLoading, redirect }) => {
  const { t, locale } = useFavurTranslation()

  return (
    <ListWrapper hasContent={Boolean(tasks.length)} message={t('page.tasks.content.noTasks')} isLoading={isLoading}>
      <ContentWrapper>
        <TaskContainer>
          {tasks.map((task) => {
            const { bodyText, startText, secondaryText, endText } = normalizeTaskContents({
              t,
              task,
              locale,
            })
            const title = normalizeTaskHeader({ task, isOffice, t })
            const taskUrl = getTaskUrl({ task })

            return (
              <ContentCard
                key={task.favurUuid}
                header={{
                  icon: <FramedIcon icon={PlaceholderIcon} />,
                  title,
                  isLink: true,
                }}
                bodyText={bodyText}
                footerProps={{
                  startText,
                  endText,
                  secondaryText,
                }}
                onClick={() => redirect({ taskUrl })}
              />
            )
          })}
        </TaskContainer>
      </ContentWrapper>
    </ListWrapper>
  )
}
export default TaskList
