import { formatDateRange } from 'redesign/shared/date/utils'
import { newDateWithoutTime } from 'utils/date'
import { DEFAULT_DATE_FORMAT } from '../../constants'
import { GetDateRangeFromTask } from '../types'

export const getDateRangeFromTask = ({ dates, dateFormat = DEFAULT_DATE_FORMAT, locale, t }: GetDateRangeFromTask) => {
  const { dateTo, dateFrom } = dates

  return formatDateRange({
    dates: {
      startDate: newDateWithoutTime(dateFrom),
      endDate: dateTo ? newDateWithoutTime(dateTo) : undefined,
    },
    dateFormat,
    locale,
    t,
  })
}
