import React, { useState } from 'react'
import { Button, Typography } from '@mui/material'
import { useHistory } from 'react-router'
import ContentFooter from 'redesign/components/molecules/ContentFooter'
import PullToRefreshContentWrapper from 'redesign/components/organisms/PullToRefreshContentWrapper'

const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

// POC. Real implementation to be added later
const PullToRefreshPage: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [content, setContent] = useState<string[]>([])
  const history = useHistory()

  const onRefresh = async () => {
    setIsLoading(true)

    await delay(2000)

    setContent((prevValue) => [...prevValue, ...Array(20)])

    setIsLoading(false)
  }

  return (
    <PullToRefreshContentWrapper onRefresh={onRefresh} isLoading={isLoading} disabled={isLoading}>
      <Button
        sx={{
          paddingTop: '50px',
        }}
        onClick={() => history.push('/me')}
      >
        Return to menu
      </Button>
      <Typography>Custom Pull to refresh POC </Typography>
      {content.map((_, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <ContentFooter key={index} startText="Content number" secondaryText={`${index + 1}`} />
      ))}
    </PullToRefreshContentWrapper>
  )
}

export default PullToRefreshPage
