import { ComponentsVariants } from '@mui/material'

declare module '@mui/material/Checkbox' {
  interface CheckboxPropsSizeOverrides {
    large: true
  }
}

const variants: ComponentsVariants['MuiCheckbox'] = [
  {
    props: {
      size: 'large',
    },
    style: {
      height: '2.75rem',
      width: '2.75rem',
    },
  },
]

const MuiCheckbox = {
  variants,
}

export default MuiCheckbox
