import React, { useCallback, useState, useMemo, useRef, useEffect } from 'react'
import { Box, IconButton } from '@mui/material'
import { addDays, differenceInCalendarMonths, eachDayOfInterval } from 'date-fns'
import useRefreshHighSecSession from 'hooks/useRefreshHighSecSession'
import { CloseThinM } from 'icons'
import { throttle } from 'lodash/fp'
import AvatarSideBar from 'pages/AbsencePlanner/components/AvatarSideBar'
import AbsencePlannerGridBody from 'pages/AbsencePlanner/components/Grid/Body'
import SmallScreenGradient from 'pages/AbsencePlanner/components/Grid/CalendarRow/SmallScreenGradient'
import MonthScrollerContainer from 'pages/AbsencePlanner/components/Grid/MonthScrollerContainer'
import GridBodyContainer from 'pages/AbsencePlanner/components/Grid/MonthScrollerContainer/GridBodyContainer'
import TableBackgroundContainer from 'pages/AbsencePlanner/components/Grid/TableBackgroundContainer/TableBackgroundContainer'
import TeamNameRows from 'pages/AbsencePlanner/components/Grid/TeamNameRows'
import { CELL_WIDTH } from 'pages/AbsencePlanner/components/Grid/constants'
import { gridClasses } from 'pages/AbsencePlanner/components/Grid/styles'
import MonthIndicator from 'pages/AbsencePlanner/components/MonthSwitcherBar/MonthIndicator'
import useAbsencePlannerStateContext from 'pages/AbsencePlanner/contexts/AbsencePlannerStateContext'
import { AvatarSidebarStateProvider } from 'pages/AbsencePlanner/contexts/AvatarSidebarStateContext'
import { FilterContext } from 'pages/AbsencePlanner/contexts/FilterContext'
import { useSelectedStatesActionsContext } from 'pages/AbsencePlanner/contexts/selectedStatesContext'
import { useAbsencePlannerData } from 'pages/AbsencePlanner/hooks/useAbsencePlannerData'
import { PersonT } from 'types'
import { getDateFromISOString } from 'utils/date'
import palette from 'utils/theme/palette'
import PreviewCalendarRow from '../PreviewCalendarRow/PreviewCalendarRow'
import { absencePlannerDrawerClasses } from '../styles'
import { usePreviewGridFilter } from './hooks/usePreviewGridFilter'

const DAY_OFFSET = -1
const EXTRA_PREVIEW_DAYS = 7

interface ISimplifiedGridProps {
  onCancel: () => void
  previewPerson?: PersonT
  startDate: string
  endDate: string
  isOpen: boolean
}

const PreviewGrid: React.FC<ISimplifiedGridProps> = ({ onCancel, previewPerson, startDate, endDate, isOpen }) => {
  const { throttledRefreshHighSecSession } = useRefreshHighSecSession()
  const baseDate = useMemo(() => addDays(getDateFromISOString(startDate), -1 * EXTRA_PREVIEW_DAYS), [startDate])
  const previewPersonIds = useMemo(() => (previewPerson?.id ? [Number(previewPerson?.id)] : undefined), [
    previewPerson?.id,
  ])
  const filters = usePreviewGridFilter(previewPerson)
  const { shownTypes, ownerMemberTeams, teamsLoading, shownTeams, isMultiTenant } = filters

  const { updateDateRange, shiftsDateRange, loading, personData, userPerson } = useAbsencePlannerData({
    shownTypes,
    shownTeams,
    selectedPersonId: previewPerson?.id,
  })

  const scrollRef = useRef(null)
  const [shownMonthIndex, setShownMonthIndex] = useState(0)
  const { isSmallScreen } = useAbsencePlannerStateContext()
  const { setSelectedDays } = useSelectedStatesActionsContext()

  const onScrollX = useCallback(
    (container: HTMLDivElement) => {
      const amountScrolledX = container.scrollLeft

      const daysScrolled = Math.floor(amountScrolledX / CELL_WIDTH)
      const date = addDays(baseDate, daysScrolled)
      const scrolledMonthIndex = differenceInCalendarMonths(date, baseDate)

      if (scrolledMonthIndex !== shownMonthIndex) {
        setShownMonthIndex(scrolledMonthIndex)
      }
    },
    [baseDate, shownMonthIndex],
  )

  // We only call onScrollX every 250 ms
  const throttledOnScrollX = throttle(250, onScrollX)

  const onMonthScrollerScroll = useCallback(
    (event: React.UIEvent<HTMLDivElement, UIEvent>) => {
      const container = event.currentTarget as HTMLDivElement
      throttledOnScrollX(container)
    },
    [throttledOnScrollX],
  )

  // Queries the badges data based on start and end dates and sets the selected days
  useEffect(() => {
    const taskStartDate = getDateFromISOString(startDate)
    const taskEndDate = getDateFromISOString(endDate)
    const firstShownDay = addDays(taskStartDate, -1 * EXTRA_PREVIEW_DAYS + DAY_OFFSET)
    const lastShownDay = addDays(taskEndDate, EXTRA_PREVIEW_DAYS)
    const shiftDateRange = {
      startDate: firstShownDay,
      endDate: lastShownDay,
    }
    updateDateRange(shiftDateRange)
    setSelectedDays(eachDayOfInterval({ start: taskStartDate, end: taskEndDate }))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, endDate])

  // Sets scroll container position on drawer open
  useEffect(() => {
    if (shiftsDateRange.startDate && shiftsDateRange.endDate && isOpen) {
      if (!scrollRef.current) return
      const scrollContainer = scrollRef.current as HTMLDivElement
      scrollContainer.scrollLeft = CELL_WIDTH * (EXTRA_PREVIEW_DAYS - 1)
    }
  }, [startDate, shiftsDateRange.startDate, shiftsDateRange.endDate, isOpen])

  return (
    <>
      <Box sx={absencePlannerDrawerClasses.content}>
        <Box sx={absencePlannerDrawerClasses.whiteBlock} />
        <Box sx={absencePlannerDrawerClasses.topContent}>
          <IconButton sx={absencePlannerDrawerClasses.closeButton} onClick={onCancel} size="large">
            <CloseThinM fill={palette.primary.main} />
          </IconButton>
          <TableBackgroundContainer>
            <MonthIndicator baseDate={baseDate} shownMonthIndex={shownMonthIndex} />
          </TableBackgroundContainer>
        </Box>
      </Box>
      <Box
        sx={gridClasses.gridContainer}
        onMouseOver={throttledRefreshHighSecSession()}
        onTouchStart={throttledRefreshHighSecSession()}
      >
        <FilterContext.Provider value={filters}>
          <AvatarSidebarStateProvider>
            <SmallScreenGradient isPreview />
            <MonthScrollerContainer ref={scrollRef} onScroll={onMonthScrollerScroll}>
              <AvatarSideBar
                teams={ownerMemberTeams}
                taskCounts={{}}
                loading={teamsLoading}
                persons={previewPerson ? [previewPerson] : undefined}
                firstPersonIds={previewPersonIds}
                isPreview
              />
              <TeamNameRows
                teams={ownerMemberTeams}
                teamsLoading={teamsLoading}
                personIds={previewPersonIds}
                isMultiTenant={isMultiTenant}
              />
              <GridBodyContainer>
                <PreviewCalendarRow shiftsDateRange={shiftsDateRange} />
                <AbsencePlannerGridBody
                  persons={previewPerson ? [previewPerson] : undefined}
                  teams={ownerMemberTeams}
                  personData={personData}
                  userPerson={userPerson}
                  shiftsDateRange={shiftsDateRange}
                  loading={loading}
                  isSmallScreen={isSmallScreen}
                  isPreview
                  firstPersonIds={previewPersonIds}
                  scrollContainerRef={scrollRef}
                />
              </GridBodyContainer>
            </MonthScrollerContainer>
          </AvatarSidebarStateProvider>
        </FilterContext.Provider>
      </Box>
    </>
  )
}

export default PreviewGrid
