import React, { useCallback, useMemo, useState } from 'react'
import { Box, Button, Typography } from '@mui/material'
import ApplicationInformation from 'components/ApplicationInformation'
import useFavurNavigation from 'hooks/useFavurNavigation'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRolesViews from 'hooks/useRolesViews'
import useSessionContext from 'hooks/useSessionContext'
import { useHistory, useLocation } from 'react-router-dom'
import { useSetDatadogUser } from 'services/Datadog/useSetDatadogUser'
import routes from 'services/RoutesProvider/routes'
import { isNative } from 'utils/platform'
import { Browser } from '@capacitor/browser'
import { datadogLogs } from '@datadog/browser-logs'
import { NavPointCallbacksT } from '../types'
import { tertiaryClasses } from './styles'

interface TertiaryNavigationProps {
  toggleDrawer: () => void
}

const TertiaryNavigation: React.FC<TertiaryNavigationProps> = ({ toggleDrawer }) => {
  const { t } = useFavurTranslation()
  const [showInfo, setShowInfo] = useState(false)
  const { pathname } = useLocation()
  const history = useHistory()
  const { clearActiveView } = useRolesViews()
  const { logout: contextLogout } = useSessionContext()
  const { tertiaryNavigation: navPoints } = useFavurNavigation()
  useSetDatadogUser()

  const logout = useCallback(() => {
    datadogLogs.logger.info('[Doing logout] From TertiaryNavigation')
    clearActiveView()
    contextLogout()
  }, [clearActiveView, contextLogout])

  const closeIfCurrent = useCallback(
    (to: string) => {
      to === pathname && toggleDrawer()
    },
    [pathname, toggleDrawer],
  )

  const onNavigate = useCallback(
    (to: string) => {
      closeIfCurrent(to)
      history.push(to)
    },
    [closeIfCurrent, history],
  )

  const callbacks: NavPointCallbacksT = useMemo(
    () => ({
      contact: () => onNavigate(routes.contact),
      appInfo: () => setShowInfo(true),
      privacy: async () => {
        await Browser.open({ url: t('page.termsAndConditionsUpdate.url.dataProtection') })
      },
      logout,
    }),
    [onNavigate, setShowInfo, t, logout],
  )

  return (
    <Box sx={tertiaryClasses.container(isNative())}>
      <Box sx={tertiaryClasses.elementsGroup}>
        {navPoints.map((navPoint) => (
          <Button
            key={navPoint.label}
            data-testid={navPoint.testId}
            sx={tertiaryClasses.element}
            onClick={callbacks[navPoint.id]}
            disableRipple
          >
            <Typography variant="body2">{t(navPoint.label)}</Typography>
          </Button>
        ))}
      </Box>

      {showInfo && <ApplicationInformation label="App Info" onClose={() => setShowInfo(false)} />}
    </Box>
  )
}
export default TertiaryNavigation
