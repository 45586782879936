import { gql } from '@apollo/client'

export const updateAbsenceRequestMutationApollo = gql`
  mutation updateAbsenceStatus(
    $dates: [InputAbsenceDateElement]
    $internalComment: String
    $reviewerComment: String
    $status: String!
    $taskUuid: String!
  ) {
    updateAbsenceStatus(
      dates: $dates
      internalReviewerComment: $internalComment
      reviewerComment: $reviewerComment
      status: $status
      taskUuid: $taskUuid
    ) {
      success
    }
  }
`
