import { gql } from '@apollo/client'

export const lowSecurityTenantNames = gql`
  query getLowSecurityTenantNames {
    lowSecurityTenantNames {
      tenantName
      personId
    }
  }
`

export const establishSecureConnectionMutation = `establishSecureConnection(personId: #personId, token: "#token"){
      status,
      validUntil,
      tenantName,
      errorCode
    }`

export const taskByUuid = gql`
  query getTasks($favurUuid: String!) {
    task(favurUuid: $favurUuid) {
      person {
        id
        tenant {
          name
        }
      }
    }
  }
`
