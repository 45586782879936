import { Card, styled } from '@mui/material'

export const MuiCard = styled(Card, {
  shouldForwardProp: (prop) => prop !== 'isNew' && prop !== 'isClickable',
})<{ isNew: boolean; isClickable?: boolean }>`
  border-radius: 0;
  background-color: ${({ isNew, theme }) =>
    isNew ? theme.palette.common.newCard : theme.palette.common.backgroundCard};
  cursor: ${({ isClickable }) => (isClickable ? 'pointer' : 'auto')};
  :hover {
    background-color: ${({ theme }) => theme.palette.common.hoverCard};
  }
`

export const Content = styled('div')`
  padding: ${({ theme }) => theme.spacing(1)};
  text-decoration: none;
`
