import React from 'react'
import { Box, Typography } from '@mui/material'
import Card from 'components/Basics/Card'
import TeamList from 'components/TeamList'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useJamesApolloQuery } from 'hooks/useJamesApolloQuery'
import { OwnerPersons } from 'hooks/useOwnersOfUser'
import useRefreshHighSecSession from 'hooks/useRefreshHighSecSession'
import { useHistory } from 'react-router-dom'
import routes from 'services/RoutesProvider/routes'
import { TeamT } from 'types'
import { orderTeamsByLanguage } from 'types/utils'
import { teamsQuery } from '../../queries'
import { classes } from '../../styles'
import PersonsItem from './PersonsItem'
import { usePersonsOwner } from './usePersonsOwner'

interface TeamListProps {
  ownerPersons: OwnerPersons | undefined
}

const TeamsList: React.FC<TeamListProps> = ({ ownerPersons }) => {
  const { t, language } = useFavurTranslation()
  const { refreshHighSecTriggers } = useRefreshHighSecSession()
  const history = useHistory()
  const { data: dataTeams, loading: loadingTeams } = useJamesApolloQuery<{ findAllTeams: TeamT[] }>(teamsQuery, {
    variables: { personIds: ownerPersons?.personIds ?? [] },
    fetchPolicy: 'cache-and-network',
  })

  const { activePersons, inactivePersons, loading: loadingPersons } = usePersonsOwner(
    parseInt(`${ownerPersons?.owner.id}`),
  )

  if (loadingTeams || loadingPersons) {
    return null
  }

  const teams = dataTeams?.findAllTeams ?? []

  const teamOnClick = (team: TeamT) => {
    history.push(`${routes.teams}/${team.id}`)
  }

  return (
    <Box {...refreshHighSecTriggers}>
      <PersonsItem
        activePersons={activePersons}
        inactivePersons={inactivePersons}
        ownerId={ownerPersons?.owner.id as number}
      />
      {teams.length === 0 ? (
        <Card>
          <Typography variant="body1" sx={classes.noTeamText} data-testid="no-team">
            {t(`page.teams.noTeams`)}
          </Typography>
        </Card>
      ) : (
        <TeamList teams={orderTeamsByLanguage(teams, language)} showWarnings onClick={teamOnClick} />
      )}
    </Box>
  )
}

export default TeamsList
