import React from 'react'
import { useContext } from 'react'
import Tabs from 'redesign/components/organisms/Tabs'
import SubheaderTabContext from 'redesign/services/SubheaderTabs/context'

const SubheaderTabs: React.FC = () => {
  const { selectedTabName, setSelectedTabName, pages } = useContext(SubheaderTabContext)

  return (
    <Tabs
      variant="fullWidth"
      pages={pages}
      color="primary"
      showTabPage={false}
      selectedTabName={selectedTabName}
      setSelectedTabName={setSelectedTabName}
    />
  )
}

export default SubheaderTabs
