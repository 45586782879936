import { styled } from '@mui/material'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.spacing(1)};
`

export const Header = styled('div')`
  padding-bottom: ${({ theme }) => theme.spacing(2)};
`
