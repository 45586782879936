import { taskStates } from 'pages/Tasks/constants'
import routes from 'services/RoutesProvider/routes'
import { ReminderMonthlySheetManualNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getMonthlySheetManualReminder = ({ notification, t }: GetNotificationData) => {
  const { managerNameShort, taskUuid } = notification.data as ReminderMonthlySheetManualNotification
  const { title, body } = notificationGlosssaryKeys.reminderMonthlySheetManual

  return {
    title: t(title, { managerNameShort }),
    body: t(body),
    route: `${routes.shiftUpdateDetail}/${taskUuid}/${taskStates.todo}`,
  }
}
