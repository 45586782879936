import React from 'react'
import { DialogActions, DialogContent, DialogTitle, Dialog as MuiDialog, Typography } from '@mui/material'
import { IDialogProps } from './type'

// Todo: update when design is ready
const Dialog: React.FC<IDialogProps> = ({ children, title, actions, testId, onClose, ...props }) => {
  return (
    <MuiDialog
      disableEscapeKeyDown
      onClose={(event, reason) => {
        if (onClose && reason !== 'backdropClick') {
          onClose(event, reason)
        }
      }}
      data-testid={testId ?? 'dialog'}
      {...props}
    >
      {title && (
        <DialogTitle>
          <Typography variant="subtitle1" role="heading" data-testid="dialog-title-text" component="span">
            {title}
          </Typography>
        </DialogTitle>
      )}
      <DialogContent>{children}</DialogContent>
      {actions && <DialogActions>{actions}</DialogActions>}
    </MuiDialog>
  )
}

export default Dialog
