import React from 'react'
import { useTheme } from '@mui/material'
import DotAlertWrapper from 'redesign/components/atoms/DotAlert'
import Link from '../../atoms/Link'
import { Content, MuiCard } from './styles'
import type { CardProps } from './types'

const Card: React.FC<CardProps> = ({ children, download, isNew = false, url, onClick, dataTestId }) => {
  const theme = useTheme()

  return (
    <Link url={url} download={download}>
      <MuiCard
        data-testid={dataTestId}
        aria-label="card"
        isNew={isNew}
        theme={theme}
        onClick={onClick}
        isClickable={Boolean(onClick)}
      >
        <Content>
          <DotAlertWrapper isNew={isNew}>{children}</DotAlertWrapper>
        </Content>
      </MuiCard>
    </Link>
  )
}

export default Card
