import { taskCountsQuery } from 'shared/queries'
import { TaskCountsT } from 'types'
import { useJamesApolloQuery } from './useJamesApolloQuery'

interface IUseTaskCountsProps {
  office?: boolean
  showSecondView?: boolean
}

const useTaskCounts = ({ office = false, showSecondView }: IUseTaskCountsProps = {}) => {
  const { refetch, data, loading } = useJamesApolloQuery<{ taskCounts: TaskCountsT }>(taskCountsQuery, {
    variables: { office, showSecondView },
    fetchPolicy: 'cache-and-network',
  })

  return { refetch, taskCounts: data?.taskCounts, loading }
}

export default useTaskCounts
