import type { IndicatorState } from './types'

export const MAX = 128
export const SMOOTHNESS = 0.4
export const TRIGGER_THRESHOLD = 100
export const SHOW_INDICATOR_THRESHOLD = 50
export const PULL_TO_REFRESH_ARROW_STATES = {
  show: 'show',
  flip: 'flip',
  hide: 'hide',
  reset: 'reset',
} as const
export const INITIAL_INDICATOR_STATE: IndicatorState = {
  showIndicator: false,
  flipIndicator: false,
}
