import { ComponentsOverrides, Theme } from '@mui/material'

const styleOverrides: ComponentsOverrides<Theme>['MuiTabs'] = {
  root: {
    minHeight: '2.625rem',
    width: 'fit-content',
  },
}
const MuiTabs = {
  styleOverrides,
}

export default MuiTabs
