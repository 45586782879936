import { TASK_TYPES } from '../constants'
import {
  getAbsenceRequestData,
  getMonthlySheetData,
  getSecuredConnectionData,
  getSharedUserData,
  getShiftUpdateData,
} from './TaskListData'
import { EMPTY_CONTENT_DATA } from './constants'
import type { GetTaskListData } from './types'

export const normalizeTaskContents = ({ task, locale, t }: GetTaskListData) => {
  switch (task.taskType) {
    case TASK_TYPES.absenceRequest:
      return getAbsenceRequestData({ t, task, locale })
    case TASK_TYPES.monthlySheet:
      return getMonthlySheetData({ t, task, locale })
    case TASK_TYPES.shiftUpdate:
      return getShiftUpdateData({ t, task, locale })
    case TASK_TYPES.shareUserData:
      return getSharedUserData({ t, task, locale })
    case TASK_TYPES.secureConnection:
      return getSecuredConnectionData({ t, task, locale })

    default:
      return EMPTY_CONTENT_DATA
  }
}
