import { gql } from '@apollo/client'

export const contactDataQuery = gql`
  query getContactData {
    contactData {
      email
      addresses {
        type
        firstName
        lastName
        address
        additionalAddress
        zipCode
        city
        country
        phoneNumber
      }
    }
  }
`
