import { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { DOCUMENT_STATUS } from 'constants/documents'
import { PERSON_STATUS } from 'constants/person'
import useFavurTranslation from 'hooks/useFavurTranslation'
import { useFileDisplay } from 'hooks/useFileDownload'
import { DATE_FORMAT_VARIANTS } from 'redesign/shared/date/constants'
import { formatDate, formatDateRange } from 'redesign/shared/date/utils'
import FlashMessagesContext from 'services/FlashMessages/context'
import { getDocumentDownloadUrl, isClosed, isMonthlySheet } from 'utils/documents'
import { DocumentProps } from './types'
import { getDocumentTranslationLabel } from './utils'

export const useDocument = ({ document, openMonthlySheetDialog }: DocumentProps) => {
  const { status, data, documentType, tenant, person, favurUuid, taskMonthlySheet, createdByMirus } = document
  const { t, locale } = useFavurTranslation()
  const { setFlashMessage, removeAll } = useContext(FlashMessagesContext)
  const [isNew, setIsNew] = useState(status === DOCUMENT_STATUS.received)
  const [isViewing, setViewing] = useState(false)
  const documentLabel = `${t(getDocumentTranslationLabel(documentType))} ${formatDateRange({
    dates: data,
    dateFormat: DATE_FORMAT_VARIANTS.monthOnly,
    locale,
    t,
  })}`
  const isInactivePerson = person?.status !== PERSON_STATUS.ACTIVE
  const contentData = {
    startText: tenant ? tenant.name : person?.tenant?.name,
    endText: formatDate({ date: createdByMirus, dateFormat: DATE_FORMAT_VARIANTS.shortWithoutWeekday, locale, t }),
  }
  const hasToOpenDialog = useMemo(() => {
    const isDocMonthlySheet = isMonthlySheet(document)
    const documentHasTask = Boolean(taskMonthlySheet)

    return isDocMonthlySheet && !isClosed(document) && documentHasTask
  }, [document, taskMonthlySheet])

  const { displayFile, error: displayError, displaying } = useFileDisplay(() => {
    setIsNew(false)
    setViewing(false)
  })

  const openFile = useCallback(() => {
    setViewing(true)
    displayFile({ url: getDocumentDownloadUrl(favurUuid), filename: `${documentLabel}.pdf` })
  }, [displayFile, favurUuid, documentLabel])

  const displayFileCallback = useCallback(() => {
    if (hasToOpenDialog) {
      openMonthlySheetDialog({
        open: true,
        // It can be that there is no task, we'll lead to a blank page now
        taskUuid: taskMonthlySheet?.task?.favurUuid,
        isInactivePerson: isInactivePerson,
        openFile: openFile,
      })
    } else {
      openFile()
    }
  }, [taskMonthlySheet?.task?.favurUuid, hasToOpenDialog, openFile, isInactivePerson, openMonthlySheetDialog])

  useEffect(() => {
    removeAll()
    if (displayError) {
      setFlashMessage(t('page.documents.download.error'))
    }
  }, [displayError, removeAll, setFlashMessage, t])

  return {
    contentData,
    documentLabel,
    displaying,
    displayFileCallback,
    isNew,
    isViewing,
  }
}
