import { useCallback } from 'react'
import axios from 'axios'
import { gql, useApolloClient } from '@apollo/client'
import { PersonalDocumentFileTypeT } from '../../PersonalData/types'

const START_UPLOAD_PROGRESS = 10
const GET_LINK_UPLOAD_PROGRESS = 15

const query = gql`
  query getDocumentUploadLink($documentType: String!, $filename: String!) {
    documentUploadLink(documentType: $documentType, filename: $filename) {
      link
    }
  }
`

const getFilenameFromSignedUrl = (signedUrl: string): string => {
  const fileWithQueryParams = signedUrl.split('/')
  return fileWithQueryParams[fileWithQueryParams.length - 1].split('?')[0]
}

const useUploadFile = () => {
  const client = useApolloClient()
  const getLink = useCallback(
    (documentType: PersonalDocumentFileTypeT, filename: string) =>
      client
        .query<{ documentUploadLink: { link: string } }>({
          query,
          fetchPolicy: 'no-cache',
          variables: { documentType, filename },
        })
        .then((response) => {
          if (response && !axios.isCancel(response)) {
            return response.data.documentUploadLink.link
          }
          return undefined
        }),
    [client],
  )

  // https://cloud.google.com/storage/docs/xml-api/post-object-forms
  // https://stackoverflow.com/questions/61950270/image-upload-issue-using-gcs-upload-signed-url-react-js
  // https://github.com/googleapis/google-cloud-node/issues/1695
  // https://cloud.google.com/storage/docs/access-control/signed-urls#signing-resumable
  const uploadFile = useCallback(
    async (
      documentType: PersonalDocumentFileTypeT,
      file: File,
      filename: string,
      trackProgress?: (percent: number) => void,
    ) => {
      if (trackProgress) {
        trackProgress(START_UPLOAD_PROGRESS)
      }
      const link = (await getLink(documentType, filename)) as string
      if (trackProgress) {
        trackProgress(GET_LINK_UPLOAD_PROGRESS)
      }

      const source = axios.CancelToken.source()
      const cancelToken = source.token

      axios
        .put(`${link}`, file, {
          headers: {
            'Content-Type': file.type,
          },
          cancelToken,
          onUploadProgress(progressEvent) {
            if (trackProgress) {
              const percentCompleted =
                Math.round((progressEvent.loaded * 100) / progressEvent.total) + GET_LINK_UPLOAD_PROGRESS
              trackProgress(Math.min(100, percentCompleted))
            }
          },
        })
        .then(() => trackProgress && trackProgress(100))
      return { source, filename: getFilenameFromSignedUrl(link) }
    },
    [getLink],
  )

  return uploadFile
}

export default useUploadFile
