import React from 'react'
import { CircularProgress } from '@mui/material'
import { LoadingContainer } from './styles'

const LoadingSpinner = () => (
  <LoadingContainer aria-label="Loading animation">
    <CircularProgress />
  </LoadingContainer>
)

export default LoadingSpinner
