import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import {
  MonthlySheetCommentForFrontlinerNotification,
  MonthlySheetCommentForManagerNotification,
} from 'shared/graphql/graphql'
import { NotificationWithManagerProps } from '../types'
import { getBadgeGlossaryKey } from '../utils'
import { notificationGlosssaryKeys } from './constants'

export const getMonthlySheetComment = ({ notification, t, isManager }: NotificationWithManagerProps) => {
  const data = notification.data as
    | MonthlySheetCommentForFrontlinerNotification
    | MonthlySheetCommentForManagerNotification
  const { title, body } = isManager
    ? notificationGlosssaryKeys.monthlySheetCommentForManager
    : notificationGlosssaryKeys.monthlySheetCommentForFrontliner

  const { taskUuid, commentShort, badge } = data

  return {
    title: t(title, { ...data }),
    body: `${t(body)} ${commentShort ?? t(getBadgeGlossaryKey(badge ?? ''))}`,
    route: routes.monthlySheetsWithTask(taskUuid ?? ''),
    role: isManager ? ROLE_VIEWS.office : ROLE_VIEWS.frontliner,
  }
}
