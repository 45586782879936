import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { absencePeriods, absencePeriodsTranslationMap } from 'shared/constants'
import { ResolvedAbsenceRequestNotification } from 'shared/graphql/graphql'
import { absenceRequestDateFormat } from 'utils/constants'
import { getFormattedFromDate, newDateWithoutTime } from 'utils/date'
import { getShortNameFromName } from 'utils/person'
import { ResolvedAbsenceRequestStates } from '../constants'
import { GetNotificationData } from '../types'

export const getResolvedAbsenceRequestData = ({ notification, t, locale }: GetNotificationData) => {
  const {
    managerNameShort,
    managerName,
    dateTo: notificationDateTo,
    dateFrom: notificationDateFrom,
    taskUuid,
    period,
    state,
  } = notification.data as ResolvedAbsenceRequestNotification
  const title = () => {
    const titleKey =
      state === ResolvedAbsenceRequestStates.approved
        ? 'notification.new.absence.accepted.title'
        : 'notification.new.absence.declined.title'

    return t(titleKey, {
      managerNameShort: managerNameShort ?? getShortNameFromName(managerName ?? ''),
    })
  }

  const body = () => {
    const multiday = notificationDateFrom !== notificationDateTo

    const dateFrom = getFormattedFromDate(
      newDateWithoutTime(notificationDateFrom ?? ''),
      absenceRequestDateFormat,
      locale,
    )
    const dateTo = getFormattedFromDate(newDateWithoutTime(notificationDateTo ?? ''), absenceRequestDateFormat, locale)
    const absencePeriod = () => {
      // New-date-format
      if (multiday || !period || period.trim() === '') {
        return ''
      }

      const translatedPeriod = absencePeriodsTranslationMap[period]

      return t(absencePeriods[translatedPeriod])
    }

    return multiday
      ? t('notification.new.absence.dates_multiday.body', {
          dateFrom,
          dateTo,
        })
      : t('notification.new.absence_singleday.body', {
          dateFrom,
          absencePeriod,
        })
  }

  return {
    title: title(),
    body: body(),
    route: `${routes.absenceRequestDetail}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
