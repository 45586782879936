import { gql } from '@apollo/client'

export const bankDataQuery = gql`
  query getBankInfo {
    bankInfo {
      iban
      bic
    }
  }
`
