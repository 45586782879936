import React, { useState } from 'react'
import { TabContext } from '@mui/lab'
import TabPanel from '@mui/lab/TabPanel'
import TabBar from 'redesign/components/molecules/TabBar/TabBar'
import { TabBarContainer, TabContainer } from './styles'
import { TabsProps } from './types'

const Tabs = ({
  color,
  pages,
  orientation,
  iconPosition,
  variant = 'standard',
  showTabPage = true,
  setSelectedTabName,
}: TabsProps) => {
  const [value, setValue] = useState(pages && (pages[0]?.name ?? null))

  const onClick = (selectedTab: string) => {
    if (setSelectedTabName) {
      setSelectedTabName(selectedTab)
    }

    setValue(selectedTab)
  }

  return (
    <TabContext value={value}>
      <TabContainer isVertical={orientation === 'vertical'}>
        <TabBarContainer>
          <TabBar
            orientation={orientation}
            color={color}
            iconPosition={iconPosition}
            pages={pages}
            setValue={onClick}
            variant={variant}
          />
        </TabBarContainer>
        {showTabPage &&
          pages?.map(({ name, component }) => (
            <TabPanel value={name} key={name}>
              {component}
            </TabPanel>
          ))}
      </TabContainer>
    </TabContext>
  )
}

export default Tabs
