import { useCallback, useEffect, useMemo, useState } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useRefreshHighSecSession from 'hooks/useRefreshHighSecSession'
import useTenantPageData from 'pages/UserSettings/hooks/useTenantPageData'
import { taskTypes, TaskTypeT } from 'shared/constants'
import { TenantReminderT } from 'types'
import { IAddEditDrawerProps } from './components/AddEditDrawer'
import { getGlossaryKeyFromType, getReminderAmount, getReminderFromKey, getReminderKey } from './utils'

const useTenantReminderList = (tenantUuid: string, workflowType: string) => {
  const { t } = useFavurTranslation()
  const { throttledRefreshHighSecSession } = useRefreshHighSecSession()
  const [drawerOpen, setDrawerOpen] = useState(false)
  const [showDrawerDeleteButton, setShowDrawerDeleteButton] = useState(false)
  const [reminderRow, setReminderRow] = useState<TenantReminderT>({
    type: workflowType as TaskTypeT,
    first: 0,
    second: 0,
    third: 0,
  })
  const [editedReminderRow, setEditedReminderRow] = useState(reminderRow)
  const [reminderKey, setReminderKey] = useState<keyof TenantReminderT>('first')
  const [reminderAmount, setReminderAmount] = useState(0)
  const [dataLoading, setDataLoading] = useState(true)
  const { loading, loadingMutations, tenant, onChangeTenantReminders } = useTenantPageData(tenantUuid)

  const titleKey = useMemo(() => {
    switch (workflowType) {
      case taskTypes.monthlySheet:
        return 'page.userSettings.tenantReminderList.monthlySheetHeader'
      case taskTypes.shareUserData:
        return 'page.userSettings.tenantReminderList.shareUserDataHeader'
      case taskTypes.shiftUpdate:
        return 'page.userSettings.tenantReminderList.shiftUpdateHeader'
      default:
        return ''
    }
  }, [workflowType])

  const onClickEdit = useCallback(
    (reminderNumber: number) => {
      setShowDrawerDeleteButton(true)
      setReminderKey(getReminderKey(reminderNumber))
      setDrawerOpen(true)
      throttledRefreshHighSecSession()
    },
    [throttledRefreshHighSecSession],
  )

  const onClickAdd = useCallback(() => {
    onClickEdit(reminderAmount + 1)
    setShowDrawerDeleteButton(false)
  }, [onClickEdit, reminderAmount])

  const onChangeDrawer = useCallback(
    (days: number, hours: number) => {
      const time = days * 24 + hours
      setEditedReminderRow({ ...editedReminderRow, [reminderKey]: time })
    },
    [editedReminderRow, reminderKey],
  )

  const onSuccessDrawer = useCallback(
    (forcedValue?: number) => {
      if (!tenant?.reminders) return
      const filteredReminders = tenant.reminders.filter((reminder) => reminder.type !== editedReminderRow.type)
      const editedRow =
        forcedValue !== undefined ? { ...editedReminderRow, [reminderKey]: forcedValue } : editedReminderRow
      onChangeTenantReminders([...filteredReminders, editedRow])
      setDrawerOpen(false)
    },
    [editedReminderRow, onChangeTenantReminders, reminderKey, tenant],
  )

  const onResetToDefault = useCallback(() => {
    if (!tenant?.reminders) return
    const filteredReminders = tenant.reminders.filter((reminder) => reminder.type !== editedReminderRow.type)
    onChangeTenantReminders(filteredReminders)
  }, [editedReminderRow, onChangeTenantReminders, tenant])

  const drawerProps: IAddEditDrawerProps = useMemo(
    () => ({
      open: drawerOpen,
      value: editedReminderRow[reminderKey] as number,
      onChange: onChangeDrawer,
      onClose: () => {
        setEditedReminderRow(reminderRow)
        setDrawerOpen(false)
      },
      onSuccess: onSuccessDrawer,
      successButtonDisabled: loadingMutations,
      title: `${t('page.userSettings.tenantReminderList.reminderNumber', {
        reminderNumber: `${getReminderFromKey(reminderKey)}`,
      })} ${t(getGlossaryKeyFromType(workflowType as TaskTypeT))}`,
      showDeleteButton: showDrawerDeleteButton,
    }),
    [
      drawerOpen,
      editedReminderRow,
      loadingMutations,
      onChangeDrawer,
      onSuccessDrawer,
      reminderKey,
      reminderRow,
      showDrawerDeleteButton,
      t,
      workflowType,
    ],
  )

  useEffect(() => {
    if (loading) {
      setDataLoading(true)
    }
  }, [loading])

  useEffect(() => {
    if (!tenant?.reminders || loading) return
    const row = tenant?.reminders?.find((reminder) => reminder.type === workflowType) ?? {
      type: workflowType as TaskTypeT,
      first: 0,
      second: 0,
      third: 0,
    }
    setReminderRow(row)
    setEditedReminderRow(row)
    setReminderAmount(getReminderAmount(row))
    setDataLoading(false)
  }, [loading, tenant?.reminders, workflowType])

  return {
    loading: dataLoading,
    titleKey,
    reminderRow,
    reminderAmount,
    drawerProps,
    onClickAdd,
    onClickEdit,
    onResetToDefault,
  }
}

export default useTenantReminderList
