import { taskStates } from 'pages/Tasks/constants'
import routes from 'services/RoutesProvider/routes'
import { ReminderShiftUpdateNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getReminderShiftUpdateData = ({ notification, t }: GetNotificationData) => {
  const { taskUuid } = notification.data as ReminderShiftUpdateNotification

  return {
    title: t(notificationGlosssaryKeys.reminderShiftUpdate.title),
    body: t(notificationGlosssaryKeys.reminderShiftUpdate.body),
    route: `${routes.shiftUpdateDetail}/${taskUuid}/${taskStates.todo}`,
  }
}
