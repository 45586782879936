import React from 'react'
import PageWithFilter from 'components/Filter/PageWithFilter'
import useFilterContext from 'components/Filter/context'
import useTaskCounts from 'hooks/useTaskCounts'
import TaskList from './components/TaskList'
import useQuickFilter from './hooks/useQuickFilter'

interface ITasksPageContentProps {
  header: React.JSX.Element
  isOffice: boolean
  titleTrans: string
}

const TasksPageContent: React.FC<ITasksPageContentProps> = ({ header, isOffice, titleTrans }) => {
  const { state, setFilters } = useFilterContext()
  const { refetch: refetchTaskCounts, taskCounts } = useTaskCounts({ office: isOffice, showSecondView: false })
  const quickFilter = useQuickFilter({ taskCounts, filters: state.filters, setFilters })

  return (
    <PageWithFilter header={header} filterPageTitle={titleTrans} quickFilter={quickFilter}>
      <TaskList office={isOffice} refetchTaskCounts={refetchTaskCounts} />
    </PageWithFilter>
  )
}

export default TasksPageContent
