import { TASK_TYPES } from '../../constants'
import { getContentStatus } from '../../getContentStatus'
import { getDateFormatFromTask } from '../Dates/getDateFormatFromTask'
import { EMPTY_CONTENT_DATA } from '../constants'
import type { GetTaskListData } from '../types'
import { getTaskState } from './getTaskState'
import { isMonthlySheetTask } from './taskDataTypeGuard'

export const getMonthlySheetData = ({ task, locale, t }: GetTaskListData) => {
  if (!task || !isMonthlySheetTask(task)) return EMPTY_CONTENT_DATA

  const { status, tenantName, statusFe, updatedAt } = task

  return {
    bodyText: tenantName ?? '',
    startText: t(getTaskState({ statusFe })),
    secondaryText: t(getContentStatus({ status, taskType: TASK_TYPES.monthlySheet })),
    endText: getDateFormatFromTask({ date: updatedAt, locale, t }),
  }
}
