import React, { useContext } from 'react'
import { TabContext } from '@mui/lab'
import NotificationsHeader from 'redesign/components/organisms/Headers/NotificationsHeader'
import Page from 'redesign/components/templates/Page'
import SubheaderTabContext from 'redesign/services/SubheaderTabs/context'
import { Panel } from './styles'

const NotificationsContent = () => {
  const { selectedTabName, pages } = useContext(SubheaderTabContext)

  return (
    <TabContext value={selectedTabName}>
      <Page header={<NotificationsHeader />} paddingX={0}>
        {pages?.map(({ component, name }) => (
          <Panel key={name} value={name}>
            {component}
          </Panel>
        ))}
      </Page>
    </TabContext>
  )
}

export default NotificationsContent
