import { Theme } from '@mui/material/styles'

const shadows: Theme['shadows'] = [
  'none',
  '0rem 0.0625rem 0.5rem 0rem rgba(20, 24, 51, 0.04)',
  '0rem 0.25rem 1rem 0rem rgba(20, 26, 51, 0.04)',
  '0rem 0.25rem 1.5rem 0rem rgba(20, 26, 51, 0.04)',
  '0rem 0.25rem 1.5rem 0rem rgba(20, 26, 51, 0.04)',
  '0rem 0.25rem 1.5rem 0rem rgba(20, 26, 51, 0.04)',
  '0rem 0.25rem 1.5rem 0rem rgba(20, 26, 51, 0.04)',
  '0rem 0.25rem 1.5rem 0rem rgba(20, 26, 51, 0.04)',
  '0rem 0.25rem 1.5rem 0rem rgba(20, 26, 51, 0.04)',
  '0rem 0.25rem 1.5rem 0rem rgba(20, 26, 51, 0.04)',
  '0rem 0.25rem 1.5rem 0rem rgba(20, 26, 51, 0.04)',
  '0rem 0.25rem 1.5rem 0rem rgba(20, 26, 51, 0.04)',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
  'none',
]

export default shadows
