import { TASK_TYPES } from '../constants'
import { HEADER_FRONTLINER_TRANSLATIONS, HEADER_MANAGER_TRANSLATIONS } from './constants'
import { getFrontlinerNameShort } from './getFrontlinerNameShort'
import { NormalizeTaskHeader } from './types'

export const normalizeTaskHeader = ({ task, t, isOffice }: NormalizeTaskHeader) => {
  if (!task) return ''

  const { taskType, person } = task
  const typeOfTask = taskType as typeof TASK_TYPES[keyof typeof TASK_TYPES]

  if (!Object.values(TASK_TYPES).includes(typeOfTask)) return ''

  const translationKeys = isOffice ? HEADER_MANAGER_TRANSLATIONS : HEADER_FRONTLINER_TRANSLATIONS

  const frontlinerNameShort = isOffice
    ? getFrontlinerNameShort({ firstName: person?.firstName, lastName: person?.lastName })
    : ''

  return t(translationKeys[typeOfTask], { frontlinerNameShort })
}
