import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { ReceivedUserDataNotification } from 'shared/graphql/graphql'
import { getShortNameFromName } from 'utils/person'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getRecievedUserDataData = ({ notification, t }: GetNotificationData) => {
  const { taskUuid, userName, userNameShort } = notification.data as ReceivedUserDataNotification

  return {
    title: t(notificationGlosssaryKeys.receivedUserData.title, {
      frontlinerNameShort: userNameShort ?? getShortNameFromName(userName ?? ''),
    }),
    body: '',
    route: `${routes.userDataDetails}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
