import React from 'react'
import AppStatesService from './AppStates'
import BannerMessagesService from './BannerMessages'
import DialogsService from './Dialogs'
import EmployeePermissionsService from './EmployeePermissionsContext'
import FeatureFlagService from './FeatureFlags'
import FlashMessagesService from './FlashMessages'
import OfficeViewService from './OfficeView'
import PinService from './Pin'
import PSNService from './PushNotifications'
import SessionService from './Session'
import ShiftsService from './Shifts'

const Services = ({ children }: { children?: React.ReactNode }) => {
  return (
    <FlashMessagesService>
      <SessionService>
        <AppStatesService>
          <ShiftsService>
            <PinService>
              <FeatureFlagService>
                <BannerMessagesService>
                  <DialogsService>
                    <EmployeePermissionsService>
                      <OfficeViewService>
                        <PSNService>{children}</PSNService>
                      </OfficeViewService>
                    </EmployeePermissionsService>
                  </DialogsService>
                </BannerMessagesService>
              </FeatureFlagService>
            </PinService>
          </ShiftsService>
        </AppStatesService>
      </SessionService>
    </FlashMessagesService>
  )
}

export default Services
