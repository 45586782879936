import React, { useContext } from 'react'
import useFavurTranslation from 'hooks/useFavurTranslation'
import useNotificationsPaginated from 'hooks/useNotificationsPaginated'
import { acknowledgeOneMutation } from 'pages/Notifications/queries'
import ListWrapper from 'redesign/components/organisms/ListWrapper'
import Notification from 'redesign/components/organisms/Notifications'
import { PAGE_KEYS } from 'redesign/pages/Notifications/constants'
import SubheaderTabContext from 'redesign/services/SubheaderTabs/context'
import { Notification as NotificationT } from 'shared/graphql/graphql'
import { MutationResult } from 'types'
import { useMutation } from '@apollo/client'
import { ContentContainer } from '../Documents/styles'

const NotificationsList: React.FC = () => {
  const { selectedTabName } = useContext(SubheaderTabContext)
  const { notifications, loading } = useNotificationsPaginated(selectedTabName === PAGE_KEYS.supervisor)
  const { t } = useFavurTranslation()
  const [acknowledgeOne] = useMutation<MutationResult>(acknowledgeOneMutation)

  return (
    <ContentContainer>
      <ListWrapper
        hasContent={Boolean(notifications?.length)}
        message={t('page.documents.content.noDocuments')}
        isLoading={loading}
      >
        {notifications?.map((notification) => (
          <Notification
            key={notification.id}
            dismiss={(dismissedNotification: NotificationT) => {
              acknowledgeOne({ variables: { notificationId: dismissedNotification.id } })
            }}
            notification={notification}
          />
        ))}
      </ListWrapper>
    </ContentContainer>
  )
}

export default NotificationsList
