import { styled } from '@mui/material'

export const Frame = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({ theme }) => theme.spacing(0.5)};
  padding: ${({ theme }) => theme.spacing(0.5)};
  background-color: ${({ theme }) => theme.palette.common.greyLight};
  width: fit-content;
  height: fit-content;
`
