import { styled } from '@mui/material'

export const NotificationContainer = styled('div')`
  display: flex;
  flex-direction: row;
`
export const ChildrenContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isNew',
})<{ isNew?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: ${({ isNew, theme }) =>
    isNew ? `${theme.spacing(1)} 0 ${theme.spacing(1)} ${theme.spacing(1)}` : theme.spacing(1)};
`
