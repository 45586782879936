import { styled } from '@mui/material'

export const Container = styled('div')`
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  min-height: 100vh;
`

export const PullIndicator = styled('div')`
  position: absolute;
  top: ${({ theme }) => theme.spacing(1.25)};
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: ${({ theme }) => theme.spacing(3)};
  transition: transform 0.2s ease-out;

  &.flip {
    transform: translateX(-50%) rotate(180deg);
  }
`
