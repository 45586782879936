import { TaskTypeT } from 'shared/constants'
import { DocumentT, PersonT, PlannedShiftT, TaskMonthlySheetT } from 'types'

export type TaskStateT = 'todo' | 'pending' | 'closed'

export type TabT = {
  id: TaskStateT
  label: string
}

export type AbsenceDateT = {
  date: string
  period: string
  type: string
}

export type AbsenceRequestTaskT = {
  __typename: string
  dates: AbsenceDateT[]
  approvedDates: string[]
  dateFrom: string
  dateTo: string
  rejectedDates: string[]
  reviewerComment: string
}

export type MonthlySheetTaskT = {
  __typename: string
  documentAcceptedAt: string | null
  documentCreatedAt: string
  documentId: number
  documentUuid: string
  endDate: string
  startDate: string
}

export type ShareUserDataTaskT = {
  reviewerComment?: string
  userComment?: string
  sentDataOn?: string
}

export type ShiftUpdateTaskT = {
  acknowledgedAt: string
  publishedAt: string
}

export type SecureConnectionTaskT = Record<string, never>

export type TimelineItemT = {
  id: string
  documentId: string | null
  document?: DocumentT
  badge: string
  comment: string
  authorId: string
  authorFirstName: string | null
  authorLastName: string | null
  authorAvatarUrl: string | null
  authorIsSystem: boolean
  insertedAt: string
  tenantName: string
  isDataForm: boolean
}

export type TaskDataT =
  | AbsenceRequestTaskT
  | MonthlySheetTaskT
  | ShareUserDataTaskT
  | ShiftUpdateTaskT
  | SecureConnectionTaskT

export type TaskT<T extends TaskDataT> = {
  id?: number
  comment: string
  insertedAt: string
  updatedAt: string
  userName: string
  triggeredByUserName: string
  taskType: TaskTypeT
  tenantName: string
  favurUuid: string
  status: string
  statusFe: TaskStateT
  taskData: T
  person?: PersonT
  personTriggeredBy?: PersonT
  shiftsUpdated?: PlannedShiftT[]
  shiftsUpdatedHistory?: PlannedShiftT[]
  taskMonthlySheet?: TaskMonthlySheetT
  timeline?: TimelineItemT[]
}

export type TaskCardDataT<T extends TaskDataT> = {
  task: TaskT<T>
  office: boolean
  sendManualReminder?: () => void
  onDelete?: (task: TaskT<T>) => void
  onSentReminderSuccess?: () => void
  onClickDetails?: () => void
}

export type UserDataStepT = 'intro' | 'personal' | 'family' | 'contact' | 'bank' | 'sharing'

export type UserDataUrlParamsT = {
  userFormStep: string
  favurUuid: string
}

export type TasksPaginated = {
  totalCount: number
  list: TaskT<TaskDataT>[]
}

export enum ShiftUpdatedValues {
  notUpdated = 0,
  updated = 1,
  acknowledged = 2,
  inserted = 3,
  deleted = 4,
}

export enum EventsOfTask {
  monthlySheetAccept = 'monthly_sheet_accept',
  monthlySheetAcceptThisMonth = 'monthly_sheet_accept_this_month',
  monthlySheetAcceptNextMonth = 'monthly_sheet_accept_next_month',
  monthlySheetSendCommentToManager = 'monthly_sheet_send_comment_to_manager',
  monthlySheetSendCommentToFrontline = 'monthly_sheet_send_comment_to_frontline',
  sendReminderToFrontline = 'send_reminder_to_frontline',
  sendReminderToManager = 'send_reminder_to_manager',
  absenceRequestSendCommentToManager = 'absence_request_send_comment_to_manager',
  absenceRequestSendCommentToFrontline = 'absence_request_send_comment_to_frontline',
  absenceRequestRequestDeletion = 'absence_request_request_deletion',
  absenceRequestAcceptDeletion = 'absence_request_accept_deletion',
  absenceRequestRejectDeletion = 'absence_request_reject_deletion',
  secureConnectionSendCommentToManager = 'secure_connection_send_comment_to_manager',
  secureConnectionSendCommentToFrontline = 'secure_connection_send_comment_to_frontline',
  shareUserDataSendCommentToManager = 'share_user_data_send_comment_to_manager',
  shareUserDataSendCommentToFrontline = 'share_user_data_send_comment_to_frontline',
}
