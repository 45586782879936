import { styled } from '@mui/material'

export const MainContainer = styled('div')`
  display: flex;
  flex-direction: column;
`

export const HeaderContainer = styled('div')`
  padding: ${({ theme }) => theme.spacing(1, 2)};
`

export const HeaderContent = styled('div')`
  display: grid;
  grid-template-columns: 1fr 3fr 1fr;
  align-items: center;
  height: ${({ theme }) => theme.spacing(5)};
`

export const StartContainer = styled('div')`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

export const MiddleContainer = styled('div')`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: ${({ theme }) => theme.palette.text.primary};
`

export const EndContainer = styled('div')`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 100%;
`
