import { Box, styled } from '@mui/material'

export const TabContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isVertical',
})<{ isVertical?: boolean }>`
  display: flex;
  flex-direction: ${({ isVertical }) => (isVertical ? 'row' : 'column')};
`
export const TabBarContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  border-bottom: ${({ theme }) => `${theme.spacing(0.125)} solid ${theme.palette.common.shiftBorder}`};
`
