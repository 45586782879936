import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { ShareUserDataNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getShareUserData = ({ notification, t }: GetNotificationData) => {
  const { tenantName, taskUuid } = notification.data as ShareUserDataNotification
  const { title, body } = notificationGlosssaryKeys.shareUserData

  return {
    title: t(title),
    body: t(body, { tenantName }),
    route: `${routes.userDataDetails}/${taskUuid}`,
    role: ROLE_VIEWS.office,
  }
}
