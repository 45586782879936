import { ComponentsVariants } from '@mui/material'

declare module '@mui/material/Badge' {
  interface BadgePropsVariantOverrides {
    small: true
  }
}

const variants: ComponentsVariants['MuiBadge'] = [
  {
    props: {
      variant: 'small',
    },
    style: {
      '.MuiBadge-small': {
        height: '1rem',
        padding: '0 0.25rem',
      },
    },
  },
]

const MuiBadge = {
  variants,
}

export default MuiBadge
