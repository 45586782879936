import routes from 'services/RoutesProvider/routes'
import { LinkMessageNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'

export const getLinkMessageNotificationData = ({ notification, t }: GetNotificationData) => {
  const { data, invitationCode } = notification
  const { titleKey, bodyKey } = data as LinkMessageNotification

  return {
    title: t(titleKey ?? ''),
    body: t(bodyKey ?? ''),
    route: `${routes.invite}/${invitationCode}`,
  }
}
