import { taskStates } from 'pages/Tasks/constants'
import routes from 'services/RoutesProvider/routes'
import { ShiftUpdateSeenNotification } from 'shared/graphql/graphql'
import { stringToFavurDate } from 'utils/date'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getShiftUpdateSeenData = ({ notification, t }: GetNotificationData) => {
  const { userNameShort, dateFrom, dateTo, count, taskUuid } = notification.data as ShiftUpdateSeenNotification

  const textBody = t(
    count === 1
      ? notificationGlosssaryKeys.shiftUpdateSeen.body
      : notificationGlosssaryKeys.shiftUpdateSeenMultiday.body,
    {
      dateFrom: stringToFavurDate(dateFrom ?? ''),
      dateTo: stringToFavurDate(dateTo ?? ''),
      count: count ?? 0,
    },
  )

  const url = taskUuid
    ? `${routes.shiftUpdateDetail}/${taskUuid}/${taskStates.closed}`
    : `${routes.tasks}/${taskStates.closed}`

  return {
    title: t(notificationGlosssaryKeys.shiftUpdateSeen.title, { frontlinerNameShort: userNameShort }),
    body: textBody,
    route: url,
  }
}
