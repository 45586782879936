import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { ShareUserDataAcceptedNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getShareUserDataAcceptedData = ({ notification, t }: GetNotificationData) => {
  const { tenantName, taskUuid } = notification.data as ShareUserDataAcceptedNotification

  return {
    title: t(notificationGlosssaryKeys.shareUserDataAccepted.title),
    body: t(notificationGlosssaryKeys.shareUserDataAccepted.body, { tenantName }),
    route: `${routes.userDataDetails}/${taskUuid}`,
    role: ROLE_VIEWS.frontliner,
  }
}
