import { styled } from '@mui/material'

export const MainContainer = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Container = styled('div')`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${({ theme }) => theme.spacing(1)};
`
