import { ROLE_VIEWS } from 'constants/roles'
import routes from 'services/RoutesProvider/routes'
import { ReminderShareUserDataNotification } from 'shared/graphql/graphql'
import { GetNotificationData } from '../types'
import { notificationGlosssaryKeys } from './constants'

export const getReminderShareUserData = ({ notification, t }: GetNotificationData) => {
  const { taskUuid } = notification.data as ReminderShareUserDataNotification
  const { title, body } = notificationGlosssaryKeys.reminderShareUserData

  return {
    title: t(title),
    body: t(body),
    route: `${routes.userDataDetails}/${taskUuid}`,
    role: ROLE_VIEWS.frontliner,
  }
}
