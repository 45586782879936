export const ICON_SIZE = {
  small: 16,
  medium: 24,
  large: 32,
  extraLarge: 48,
  xxl: 64,
  tutorial: 80,
}

export const ICON_VIEWBOX = {
  small: `0 0 ${ICON_SIZE.small} ${ICON_SIZE.small}`,
  medium: `0 0 ${ICON_SIZE.medium} ${ICON_SIZE.medium}`,
  large: `0 0 ${ICON_SIZE.large} ${ICON_SIZE.large}`,
  extraLarge: `0 0 ${ICON_SIZE.extraLarge} ${ICON_SIZE.extraLarge}`,
} as const
