import React from 'react'
import SvgWrapper from '../SvgWrapper'
import { ICON_SIZE, ICON_VIEWBOX } from './constants'
import type { IconProps } from './types'

const PlaceholderIcon: React.FC<IconProps> = ({
  viewBox = ICON_VIEWBOX.medium,
  color = 'currentColor',
  size = ICON_SIZE.medium,
}) => (
  <SvgWrapper data-testid="placeholder-icon" viewBox={viewBox} size={size}>
    <g clipPath="url(#a)">
      <path
        d="M24 12a4.8 4.8 0 0 0-2.21-4.06 4.82 4.82 0 0 0-5.73-5.73 4.83 4.83 0 0 0-8.12 0 4.82 4.82 0 0 0-5.73 5.73 4.83 4.83 0 0 0 0 8.12 4.82 4.82 0 0 0 5.73 5.73 4.83 4.83 0 0 0 8.12 0 4.82 4.82 0 0 0 5.73-5.73A4.8 4.8 0 0 0 24 12zm-11 1.37V14a1 1 0 0 1-2 0v-1.5a1 1 0 0 1 1-1 2 2 0 1 0-2-2 1 1 0 1 1-2 0 4 4 0 1 1 5 3.87zm-1 5.88a1.5 1.5 0 1 1 0-3 1.5 1.5 0 0 1 0 3z"
        fill={color}
        fillOpacity=".87"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" d="M0 0h24v24H0z" />
      </clipPath>
    </defs>
  </SvgWrapper>
)

export default PlaceholderIcon
