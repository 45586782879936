import routes from 'services/RoutesProvider/routes'
import { TASK_TYPES } from '../constants'
import type { GetTaskListData } from './types'

export const getTaskUrl = ({ task: { taskType, favurUuid, statusFe } }: Pick<GetTaskListData, 'task'>) => {
  switch (taskType) {
    case TASK_TYPES.absenceRequest:
      return `${routes.absenceRequestDetail}/${favurUuid}`
    case TASK_TYPES.monthlySheet:
      return routes.monthlySheetsWithTask(favurUuid ?? '')
    case TASK_TYPES.shiftUpdate:
      return `${routes.shiftUpdateDetail}/${favurUuid}/${statusFe}`
    case TASK_TYPES.shareUserData:
      return `${routes.userDataDetails}/${favurUuid}`
    case TASK_TYPES.secureConnection:
      return routes.secureConnectionWithTask(favurUuid ?? '')
    default:
      return ''
  }
}
