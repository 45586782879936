const medium = 500
const baseTypography = {
  h1: {
    fontWeight: medium,
    fontSize: '3rem',
    lineHeight: 1.2,
    letterSpacing: '-0.01em',
  },
  h2: {
    fontWeight: medium,
    fontSize: '2.5rem',
    lineHeight: 1.2,
    letterSpacing: '-0.01em',
  },
  h3: {
    fontWeight: medium,
    fontSize: '2rem',
    lineHeight: 1.2,
    letterSpacing: '-0.01em',
  },
  h4: {
    fontWeight: medium,
    fontSize: '1.75rem',
    lineHeight: 1.2,
    letterSpacing: '-0.007em',
  },
  h5: {
    fontWeight: medium,
    fontSize: '1.5rem',
    lineHeight: 1.2,
    letterSpacing: '0',
  },
  h6: {
    fontWeight: medium,
    fontSize: '1.25rem',
    lineHeight: 1.2,
    letterSpacing: '0',
  },
  subtitle1: {
    fontWeight: medium,
    fontSize: '1rem',
    lineHeight: 1.2,
    letterSpacing: '0.01em',
  },
  subtitle2: {
    fontWeight: medium,
    fontSize: '0.875rem',
    lineHeight: 1.2,
    letterSpacing: '0.01em',
  },
  body1: {
    fontWeight: 'normal',
    fontSize: '1rem',
    lineHeight: 1.6,
    letterSpacing: '0.01em',
  },
  body2: {
    fontWeight: 'normal',
    fontSize: '0.875rem',
    lineHeight: 1.6,
    letterSpacing: '0.01em',
  },
  caption: {
    fontWeight: 'normal',
    fontSize: '0.75rem',
    lineHeight: 1.6,
    letterSpacing: '0.04em',
  },
  overline: {
    fontSize: '0.75rem',
    lineHeight: 1.6,
    letterSpacing: '0.08em',
  },
}

export const typographyVariants = {
  ...baseTypography,
  body1Scratched: {
    ...baseTypography.body1,
    textDecoration: 'line-through',
  },
  body2Scratched: {
    ...baseTypography.body2,
    textDecoration: 'line-through',
  },
  buttonMedium: {
    fontWeight: medium,
    fontSize: '1rem',
    lineHeight: 1.72,
    letterSpacing: '0.04em',
    textTransform: 'none',
  },
  buttonSmall: {
    fontWeight: medium,
    fontSize: '0.875rem',
    lineHeight: 1.72,
    letterSpacing: '0.04em',
    textTransform: 'none',
  },
  chipBig: {
    fontWeight: medium,
    fontSize: '0.875rem',
    lineHeight: 1.2,
    letterSpacing: '0.01em',
  },
  chipSmall: {
    fontWeight: medium,
    fontSize: '0.625rem',
    lineHeight: 1.2,
    letterSpacing: '0.01em',
  },
}

export default {
  fontFamily: 'FiraSansRedesign, sans-serif',
  fontSize: 16, // MUI default is 14px,
  ...typographyVariants,
}
