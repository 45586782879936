import { ComponentsOverrides, ComponentsVariants, Theme } from '@mui/material'
import { buttonColorOptions } from '../MuiButton/constants'

const getVariants: (theme: Theme) => ComponentsVariants['MuiButton'] = (theme) => [
  ...buttonColorOptions.map((option) => ({
    props: { disabled: true, color: option },
    style: {
      '&.Mui-disabled': {
        color: theme.palette[option].main,
      },
    },
  })),
  {
    props: { disabled: true, color: 'inherit' },
    style: {
      '&.Mui-disabled': {
        color: 'inherit',
      },
    },
  },
]

const styleOverrides: ComponentsOverrides<Theme>['MuiIconButton'] = {
  root: ({ theme }) => ({
    variants: getVariants(theme),
  }),
  sizeLarge: {
    padding: '0.75rem',
  },
  sizeMedium: {
    padding: '0.5rem',
  },
  sizeSmall: {
    padding: '0.3125rem',
  },
}

const MuiIconButton = {
  styleOverrides,
}

export default MuiIconButton
