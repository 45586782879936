const palette = {
  text: {
    primary: '#141A33DE',
    secondary: '#141A3399',
    disabled: '#141A337A',
  },
  primary: {
    dark: '#93005A',
    main: '#FF0080',
    light: '#FFE0EA',
    hover: '#FF00800A',
    selected: '#FF008014',
    focus: '#FF00801F',
    focusVisible: '#FF00804D',
    outlinedBorder: '#FF008080',
  },
  secondary: {
    dark: '#122DB0',
    main: '#3D55CC',
    light: '#F3F2FF',
  },
  error: {
    dark: '#C62828',
    main: '#D32F2F',
    light: '#EF5350',
  },
  warning: {
    dark: '#E65100',
    main: '#EF6C00',
    light: '#FF9800',
  },
  info: {
    dark: '#01579B',
    main: '#0288D1',
    light: '#03A9F4',
  },
  success: {
    dark: '#1B5E20',
    main: '#2E7D32',
    light: '#4CAF50',
  },
  common: {
    backgroundApp: '#F5F6FA',
    backgroundCard: '#FFFFFF',
    darkerGrey: '#141A33DE',
    greyMid: '#878CA8',
    greyLight: '#F5F6FA',
    shiftBorder: '#141a3314',
    white: '#FFFFFF',
    newCard: '#FF00800A',
    hoverCard: '#141A330A',
  },
}

export default palette
